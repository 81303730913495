import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { makeStyles } from '@material-ui/core/styles';
import arrowRight from '../../../assets/images/swiper-arrow-right.svg';
import arrowLeft from '../../../assets/images/swiper-arrow-left.svg';
import yellowStar from '../../../assets/images/yellow-star.svg';

//    [theme.breakpoints.down('xs')]: {},

const useStyles = makeStyles((theme) => ({
  reviewContainer: {
    height: 685,
    background:
      'linear-gradient(90deg, rgba(40, 170, 226, 1) 0%, rgba(35, 108, 206, 1) 100%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },
  headerText: {
    fontSize: 38,
    color: '#ffffff',
    textTransform: 'uppercase',
    fontWeight: 900,
    [theme.breakpoints.down('xs')]: {
      marginTop: 60,
      marginBottom: 60,
      fontSize: 24,
    },
  },
  reviewComponent: {
    width: '100%',
    height: 500,
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },
  review: {
    height: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      paddingBottom: 75,
    },
  },
  reviewPaper: {
    width: 854,
    height: 376,
    borderRadius: 40,
    border: 1,
    borderStyle: 'solid',
    borderColor: '#02040180',
    backgroundColor: '#ffffff',
    boxShadow: '8px 7px 4px 0px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.down('xs')]: {
      width: 339,
      //height: 687,
      height: 'auto',
      paddingBottom: 20,
    },
  },
  reviewPaperStarsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: "5px",
    marginTop: 52,
    marginLeft: 41,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      marginLeft: 0,
    },
  },
  reviewPaperStar: {
    width: 31,
    height: 29,
    // backgroundImage: 'url("/static/images/yellow-star.svg")',
    backgroundImage: `url(${yellowStar})`
  },
  reviewPaperText: {
    fontFamily: 'Lato',
    fontSize: 17,
    fontWeight: 400,
    lineHeight: '36px',
    letterSpacing: 0,
    paddingLeft: 41,
    paddingRight: 41,
    textAlign: 'left',
    paddingTop: 15,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
      fontSize: 17,
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  reviewPaperAuthor: {
    fontFamily: 'Lato',
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '32px',
    letterSpacing: 0,
    paddingLeft: 41,
    textAlign: 'left',
    paddingTop: 3,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      paddingLeft: 0,
    },
  },
  selectedIndicator: {
    width: 15,
    height: 15,
    borderRadius: 7,
    backgroundColor: '#FFFFFF',
    borderRadius: "50%",

    display: "inline-block" /* Ensure it's displayed */

  },
  indicator: {
    width: 15,
    height: 15,
    borderRadius: 7,
    backgroundColor: 'red',
    // backgroundColor: '#C7C7C7',
    borderRadius: "50%",
    cursor: 'pointer',
    display:"inline-block" /* Ensure it's displayed */

  },
  prevArrow: {
    position: 'absolute',
    bottom: 0,
    marginBottom: 7,
    zIndex: 1,
    left: '50%',
    backgroundImage: `url(${arrowLeft})`,
    height: 25,
    width: 13,
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
  },
  nextArrow: {
    position: 'absolute',
    bottom: 0,
    marginBottom: 7,
    zIndex: 1,
    right: '50%',
    backgroundImage: `url(${arrowRight})`,
    height: 25,
    width: 13,
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
  },
}));

export function Review({ reviews }) {
  const classes = useStyles();

  const marginArrows = -(
    50 +
    (reviews.length * 15 + (reviews.length ? (reviews.length - 1) * 20 : 0)) / 2
  );

  return (
    <div className={classes.reviewContainer}  >
      
      <div className={classes.headerText}>Amazing success stories</div>
      <Carousel
        swipeable
        infiniteLoop
        // autoPlay
        emulateTouch
        showStatus={false}
        className={classes.reviewComponent}
        renderIndicator={(clickHandler, isSelected) => (
          // <div
          //   className={
          //     isSelected ? classes.selectedIndicator : classes.indicator
          //   }
          //   onClick={clickHandler}
          //   style={{
          //     // position: 'absolute',
          //     // bottom: '3px', // Adjust as needed
          //     // left: '50%',
          //     // transform: 'translateX(-50%)',
          //     position: 'absolute',
          //     bottom: '3px', // Adjust as needed
          //     left: '50%',
          //     transform: 'translateX(-50%)',
          //     display: 'inline-block',
          //   }}
          // />
          <div
          onClick={clickHandler}
          style={{
            width: 15,
            height: 15,
            borderRadius: '50%',
            backgroundColor: isSelected ? '#FFFFFF' : '#C7C7C7',
            display: 'inline-block',
            margin: '0 10px', // Adjust margin to increase space between dots
            bottom: '3px'
          }}
        />
        )}
        renderArrowPrev={(clickHandler, hasPrev, label) => (
          <div
            style={{
              marginLeft: marginArrows,
            }}
            className={classes.prevArrow}
            onClick={clickHandler}
          />
        )}
        renderArrowNext={(clickHandler, hasNext, label) => (
          <div
            style={{
              marginRight: marginArrows,
            }}
            className={classes.nextArrow}
            onClick={clickHandler}
          />
        )}
      >
        {reviews.map(({ text, author }) => {
          return (
            <div className={classes.review}>
              <div className={classes.reviewPaper}>
                <div className={classes.reviewPaperStarsContainer}>
                  <div className={classes.reviewPaperStar} />
                  <div className={classes.reviewPaperStar} />
                  <div className={classes.reviewPaperStar} />
                  <div className={classes.reviewPaperStar} />
                  <div className={classes.reviewPaperStar} />
                </div>
                
                <div className={classes.reviewPaperText}>{text}</div>
                <div className={classes.reviewPaperAuthor}>-{author} </div>
              </div>
            </div>
          );
        })}
        
      </Carousel>
    </div>
  );
}
