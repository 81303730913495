import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Dialog, Slide } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import cs from 'classnames';
import MobileStoreButton from 'react-mobile-store-button';
// import {
//   FACEBOOK_URL,
//   HOME_URL,
//   INSTAGRAM_URL,
//   IOS_APP_URL,
//   NAVBAR_LINKS,
//   TWITTER_URL,
// } from '../../constants';
import logo from '../../../assets/images/logo.svg';
import instagram from '../../../assets/images/001-instagram.svg';
import facebook from '../../../assets/images/002-facebook.svg';
import twitter from '../../../assets/images/003-twitter.svg';
// import { signUp } from '../../utils/signUp';
import { SubMenuPopup } from './ProgramsMobileMenu/SubMenuPopup';

import body from '../../../assets/images/icon-body.png';
import stretch from '../../../assets/images/icon-stretch.png';
import running from '../../../assets/images/icon-running.png';
import stretching from '../../../assets/images/icon-stretching.png';
import hoolaHop from '../../../assets/images/icon-hoola-hop.png';


const NAVBAR_LINKS = [
  // { label: 'WORKOUTS', link: '/mobility-workouts' },
  
  { label: 'PRICING', link: '/pricing' },
  
  { label: 'LOG IN', isBig: true, link: `signin/` },
];


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.hunterGreen.main,
  },
  linkContainer: {
    textDecoration: 'none',
  },
  link: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.71,
    letterSpacing: 1.08,
    color: 'white',
  },
  closeIcon: {
    width: 40,
    height: 40,
    color: 'white',
  },
  divider: {
    'border-bottom': `1px solid rgba(255, 255, 255, 0.16)`,
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  startButton: {
    height: 40,
    width: 160,
    borderRadius: 2,
  },
  startButtonText: {
    fontWeight: 'bold',
    fontSize: 13,
    letterSpacing: 1,
    color :"black"
  },
  title: {
    fontSize: 14,
    fontWeight: 300,
    lineHeight: 2.86,
    letterSpacing: 0.21,
    color: 'white',
  },
  defaultLink: {
    display: 'inline-block',
    overflow: 'hidden',
    textDecoration: 'none',
  },
  socialMedia: {
    paddingRight: theme.spacing(1.25),
    paddingLeft: theme.spacing(1.25),
    '&:first-child': {
      paddingLeft: 0,
    },
  },
}));
const Transition = React.forwardRef((props, ref) => (
  <Slide direction='left' ref={ref} {...props} />
));

const NavbarMobile = ({ isOpen, setIsOpen }) => {
  const classes = useStyles();
  const [subMenuItems, setSubmenuItems] = useState([]);

 const HOME_URL = '/';
 const INSTAGRAM_URL = 'https://www.instagram.com/movementvault/';
 const FACEBOOK_URL = 'https://www.facebook.com/movementvault/';
 const TWITTER_URL = 'https://www.twitter.com/movementvault/';
 const IOS_APP_URL =
  'https://apps.apple.com/us/app/movement-vault/id1474254318';
  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={() => setIsOpen(false)}
      TransitionComponent={Transition}
    >
      <SubMenuPopup
        menuItems={subMenuItems}
        onClose={() => {
          setSubmenuItems([]);
        }}
      />

      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        className={classes.root}
        py={2}
        px={4}
      >
        <Box display='flex' flexDirection='column'>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <a
              className={classes.logoContainer}
              href={HOME_URL}
              target='_self'
              rel='noopener noreferrer'
            >
              <img src={logo} alt='movementvault' />
            </a>
            <Close
              className={classes.closeIcon}
              onClick={() => setIsOpen(false)}
            />
          </Box>
          <Box display='flex' flexDirection='column' pt={4}>
         

          
          <Box display='flex' flexDirection='column' pt={2}>
    <a
      href="/auth/pricing"
      className={classes.linkContainer}
      // target={target}
    >
      <Typography
        className={classes.link}
        // onClick={() => {
        //   setSubmenuItems(childItems || []);
        // }}
      >
        PRICING
      </Typography>
     
    </a>
    {/* {index !== NAVBAR_LINKS.length - 1 && ( */}
      <Box className={classes.divider} pt={2} />
    {/* )} */}
  </Box>

          <Box display='flex' flexDirection='column' pt={2}>
    <a
      href="/auth/signin"
      className={classes.linkContainer}
      // target={target}
    >
      <Typography
        className={classes.link}
        // onClick={() => {
        //   setSubmenuItems(childItems || []);
        // }}
      >
        SIGN IN
      </Typography>
     
    </a>
    {/* {index !== NAVBAR_LINKS.length - 1 && ( */}
      {/* <Box className={classes.divider} pt={2} /> */}
    {/* )} */}
  </Box>




          </Box>
          <Box display='flex' alignItems='center' pt='5%'>
            <a href="/auth/signup">
            <Button
              variant='contained'
              color='secondary'
              className={classes.startButton}
              // onClick={signUp}
            >
              <Typography variant='body1' className={classes.startButtonText}>
                START FOR FREE
              </Typography>
            </Button>
            </a>
          </Box>
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='space-evenly'
        >
          <Box pb={0.5}>
            <Typography className={classes.title} variant='h4'>
              DOWNLOAD THE MOVEMENT VAULT APP!
            </Typography>
          </Box>
          <MobileStoreButton
            store='ios'
            url={IOS_APP_URL}
            linkProps={{ title: 'Movement vault' }}
            width={140}
            height={40}
          />
          <Box display='flex' justifyContent='flex-start' pb={2} pt={4}>
            <a
              className={cs(classes.defaultLink, classes.socialMedia)}
              href={INSTAGRAM_URL}
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={instagram} alt='instagram' height={24} width={24} />
            </a>
            <a
              className={cs(classes.defaultLink, classes.socialMedia)}
              href={FACEBOOK_URL}
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={facebook} alt='facebook' height={24} width={24} />
            </a>
            <a
              className={cs(classes.defaultLink, classes.socialMedia)}
              href={TWITTER_URL}
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={twitter} alt='twitter' height={24} width={24} />
            </a>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

NavbarMobile.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default NavbarMobile;


// {NAVBAR_LINKS.map(({ link, label, target, childItems }, index) => (
//   <Box key={link} display='flex' flexDirection='column' pt={2}>
//     <a
//       href={link}
//       className={classes.linkContainer}
//       target={target}
//     >
//       <Typography
//         className={classes.link}
//         onClick={() => {
//           setSubmenuItems(childItems || []);
//         }}
//       >
//         {label}
//       </Typography>
//       {/* {childItems?.map(({label, link}) => {
//         return (
//           <>
//             <Box className={classes.divider} pt={2} />

//             <a href={link}>

//             <Typography
//               style={{ paddingLeft: '33px'  , paddingTop : "16px"}}
//               className={classes.link}
//             >
//               {label}
//             </Typography>
//             </a>
            
//           </>
//         );
//       })} */}
//     </a>
//     {index !== NAVBAR_LINKS.length - 1 && (
//       <Box className={classes.divider} pt={2} />
//     )}
//   </Box>
// ))}