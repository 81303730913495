import React from 'react';
import { withStyles } from '@material-ui/styles';

import logo from 'assets/svg/mv-logo-navbar.svg';
import { WEBSITE_URL } from 'core/constants';

const styles = (theme) => ({
  topBar: {
    alignItems: 'center',
    background: theme.palette.primary.dark,
    bottom: 0,
    color: theme.palette.primary.contrastText,
    display: 'flex',
    height: 60,
    fontSize: 14,
    paddingLeft: 24,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 60,
      paddingLeft: 16,
    },
  },

  logo: {
    height: '1.8rem',
    marginBottom: '-0.5rem',
  },
});

const Header = ({ classes }) => (
  <div className={classes.topBar}>
    <a
      href={WEBSITE_URL}
      target='_blank'
      style={{ zIndex: 3 }}
      rel='noopener noreferrer'
    >
      <img className={classes.logo} src={logo} alt='logo' />
    </a>
    
  </div>
);

export default withStyles(styles)(Header);
