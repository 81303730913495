import React from 'react';
import { withStyles } from '@material-ui/styles';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import heroImg from 'assets/img/choose-plan.png';
import avatarFrameImg from 'assets/img/avatar-frame.png';
import chevronLeftImg from 'assets/svg/chevron-left.svg';
import placeholderAvatar from 'assets/svg/avatar.svg';
import { Box } from '@material-ui/core';

const styles = ({ palette: { primary }, breakpoints }) => ({
  content: {
    padding: '3rem',
    width: '100%',
    [breakpoints.down('xs')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  },
  container: {
    position: 'relative',
    minHeight: '100%',
    [breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  header: {
    alignItems: 'center',
    background: `url(${heroImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: 316,
    display: 'flex',
    paddingLeft: 48,
    width: '100%',
    [breakpoints.down('sm')]: {
      height: 'auto',
      paddingLeft: 0,
      flexDirection: 'column',
      alignItems: 'center',
      padding: '32px 0',
    },
  },
  headerImage: {
    position: 'relative',
    height: 160,
    width: 160,
    '& > img': {
      position: 'absolute',
    },
    '& > img:first-child': {
      height: 160,
      width: 160,
    },
    '& > img:last-child': {
      top: 12,
      left: 12,
      height: 136,
      objectFit: 'cover',
      width: 136,
    },
  },
  headerContent: {
    color: primary.contrastText,
    paddingLeft: 32,
    fontSize: 20,
    letterSpacing: 'normal',
    '& > div:first-child': {
      fontSize: 32,
      fontWeight: 600,
      lineHeight: 2,
    },
    [breakpoints.down('xs')]: {
      paddingLeft: 0,
    },
  },
  nav: {
    alignItems: 'center',
    borderBottom: `1px solid ${primary.gray}`,
    display: 'flex',
    fontSize: 24,
    fontWeight: 900,
    letterSpacing: 0.5,
    lineHeight: 1.5,
    paddingBottom: 24,
    textTransform: 'uppercase',
    '& > img': {
      height: 28,
      width: 28,
      marginRight: 8,
    },
    [breakpoints.down('xs')]: {
      width: 'auto',
      fontSize: 20,
    },
  },
});

const Layout = ({
  children,
  classes,
  pageTitle,
  title,
  background,
  user: { avatarUrl, bio, firstName, lastName },
}) => {
  return (
    <div style={{ backgroundColor: background }} className={classes.container}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Box className={classes.header}>
        <div className={classes.headerImage}>
          
          <img src={avatarFrameImg} alt='avatar frame' />
          <img src={avatarUrl || placeholderAvatar} alt='avatar' />
        </div>
        <div className={classes.headerContent}>
          <div>{`${firstName} ${lastName}`}</div>
          <div>{bio}</div>
        </div>
      </Box>
      <div className={classes.content}>
        <NavLink
          exact
          to='/dashboard/profile'
          style={{ color: 'inherit', textDecoration: 'none' }}
        >
          <div className={classes.nav}>
            {title && (
              <>
                <img src={chevronLeftImg} alt='arrow left' />
                {title}
              </>
            )}
          </div>
        </NavLink>
        <div className={classes.children}>{children}</div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(withStyles(styles)(Layout));
