import React, { useState } from 'react';
import { Box,  NoSsr, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Navbar from './Navbar';
import NewFooter from './NewFooter';
import { PageSeoTags } from './SeoTags';
import ReactPlayer from 'react-player';
import ProgramsHeader from './ProgramsHeader';
import ProgramsSponsors from './ProgramsSponsors';
import HowMovmentVaultWorks from './HowMovmentVaultWorks';
import ProgramsWillHelpYou from './ProgramsWillHelpYou';
// import  {Review}  from './Review';
import ProgramsUsersPercentage from './ProgramsUsersPercentage';
import ProgramsDoctor from './ProgramsDoctor';
import ProgramsHaveYouTried from './ProgramsHaveYouTried';
import ProgramsHelped from './ProgramsHelped';
import MobilityScore from './MobilityScore';
import ReadyToUnlock from './ReadyToUnlock';
import Rehab from './Rehab';
import Consistent from './Consistent';
import {Review} from './Review';
import freeClassAllDevices from '../../../assets/images/free-class-all-devices.png';
import freeclassgraysonsterchingphonevertical from '../../../assets/images/free-class-grayson-sterching-phone-vertical.png';
import howmovmentvaultworks from '../../../assets/images/howmovmentvaultworks.png';
import freeclassmaurostrentching from '../../../assets/images/free-class-mauro-strentching.png';
// import freeclassmeandcatherine from '../../../assests/images/free-class-me-and-catherine.png';
// import doublePhone from '../../../assets/images/images/doublePhone.png';
// import howmovmentvaultwokrsphone from '../../../assets/images/howmovmentvaultwokrsphone.png';

import freeclassmeandcatherine from "../../../assets/images/free-class-me-and-catherine.png"
import doublePhone from "../../../assets/images/doublePhone.png"
import howmovmentvaultwokrsphone from "../../../assets/images/howmovmentvaultwokrsphone.png"






const useStyles = makeStyles((theme) => ({
  header: {
    height: 768,
    marginTop: -88,
    backgroundImage: `url('../static/images/image-home-banner.jpg')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('xs')]: {
      height: 480,
      // height: 520,
      paddingTop: 88,
    },
    [theme.breakpoints.down('sm')]: {
      height: 500,
      // height: 520,
      paddingTop: 88,
    },
  },
  title: {
    color: 'white',
    fontSize: 44,
    fontWeight: 900,
    letterSpacing: 0.65,
    lineHeight: 1.27,
    padding: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: 26,
      paddingTop: '10px',
      padding: 0,
    },
    textAlign: 'center',
  },
  titleFirst: {
    color: 'white',
    fontSize: 44,
    fontWeight: 900,
    letterSpacing: 0.65,
    lineHeight: 1.27,
    padding: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: 26,
      paddingTop: '2px',
      padding: 0,
    },
    textAlign: 'center',
  },
  subtitle: {
    color: 'white',
    fontSize: 24,
    letterSpacing: 0.65,
    // paddingTop: 21,
    paddingBottom: 112,
    marginTop: '90px',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 10,
      paddingTop: 20,
      fontSize: 18,
      marginTop: '0px',
    },
    textAlign: 'center',
  },
  subtitleDesktop: {
    color: 'white',
    fontSize: 24,
    letterSpacing: 0.65,
    // paddingTop: 21,
    paddingBottom: 112,
    marginTop: '40px',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 10,
      paddingTop: 18,
      fontSize: 18,
      marginTop: '0px',
    },
    textAlign: 'center',
  },
  startButton: {
    height: 64,
    width: 312,
    borderRadius: 2,
    [theme.breakpoints.down('xs')]: {
      height: 56,
      width: 260,
      marginBottom: '-35px',
    },
    [theme.breakpoints.down('sm')]: {
      height: 56,
      width: 260,
      // marginBottom : "-32px",
      marginBottom: '-8px',
    },
  },
  startButtonText: {
    fontWeight: 'bold',
    fontSize: 18,
    color: 'white',
    lineHeight: 3.56,
    letterSpacing: 1.38,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  divider: {
    'border-bottom': `1px solid ${theme.palette.primary.main}`,
    marginTop: '36px',
  },
  pitchIcon: {
    paddingTop: 25,
  },
  pitchTitle: {
    paddingTop: 36,
    paddingBottom: 30,
    fontSize: 38,
    fontWeight: 900,
    lineHeight: 1.16,
    letterSpacing: 0.56,
    // color: theme.palette.hunterGreen.main,
    color: theme.palette.hunterGreen.main,
    wordSpacing: 500,
    'word-wrap': 'break-word',
    [theme.breakpoints.down('xs')]: {
      fontSize: 24,
      textAlign: 'center',
      wordSpacing: 0,
      'word-wrap': 'normal',
    },
  },
  pitchTitleImprove: {
    paddingTop: 36,
    paddingBottom: 30,
    fontSize: 38,
    fontWeight: 900,
    lineHeight: 1.16,
    letterSpacing: 0.56,
    width: '600px',
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.hunterGreen.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: 24,
      textAlign: 'center',
      width: '100%',
    },
  },
  pitchSubtitle: {
    fontSize: 18,
    lineHeight: 1.56,
    color: '#585963',
    maxWidth: 320,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      maxWidth: 'none',
      paddingLeft: '5%',
      paddingRight: '5%',
    },
  },
  fixYourPainPitchSubtitle: {
    fontSize: 18,
    lineHeight: 1.56,
    color: '#585963',
    maxWidth: 400,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      maxWidth: 'none',
      paddingLeft: '5%',
      paddingRight: '5%',
      maxWidth: 320,
    },
  },
  subHeader: {
    backgroundColor: theme.palette.hunterGreen.main,
  },
  subheaderBoxTitle: {
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 30,
    paddingRight: 30,
    fontSize: 30,
    fontWeight: 900,
    lineHeight: 1.2,
    letterSpacing: 0.56,
    color: 'white',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 2,
    maxWidth: 500,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontSize: 24,
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  subheaderText: {
    fontSize: 18,
    lineHeight: 1.56,
    color: 'white',
    maxWidth: 560,
    marginRight: '30px',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontSize: 16,
      lineHeight: 1.63,
      marginRight: '0px',
    },
  },
  howItWorksTitle: {
    maxWidth: 500,
    fontSize: 38,
    fontWeight: 900,
    lineHeight: 1.16,
    letterSpacing: 0.56,
    color: theme.palette.hunterGreen.main,
  },
  color: {
    color: 'white',
  },
  customMobility: {
    maxWidth: 500,
    fontSize: 38,
    fontWeight: 900,
    lineHeight: 1.16,
    letterSpacing: 0.56,
    color: 'white',
  },
  customRoutinesTitle: {
    maxWidth: 'none',
    paddingBottom: 25,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontSize: 24,
    },
  },
  featuredIn: {
    maxWidth: 'none',
    paddingBottom: 25,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
      fontSize: 24,
      paddingBottom: 4,
    },
  },
  hearFromOurUsersTitle: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  customRoutinesText: {
    maxWidth: 'none',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontSize: 16,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  reactPlayerRoot: {
    paddingLeft: '15%',
    paddingRight: '15%',
    paddingBottom: 80,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  },
  downloadMovementVaultContainer: {
    paddingLeft: '10vw',
    paddingRight: '10vw',
    paddingBottom: 60,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '3vw',
      paddingRight: '3vw',
    },
  },
  rehab: {
    marginLeft: 0,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  bodyAssessmentBlock: {
    backgroundColor: '#f7f8fa',
  },
  forEveryBody: {
    marginLeft: '25px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0px',
    },
  },
  mobilityBanner: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url('../static/images/circle-banner.png')`,
    backgroundPosition: '0px 100%',
    backgroundSize: '380px 300px',
    backgroundRepeat: 'no-repeat',
  },
  mobilityVideosText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  mobileView: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
    },
  },
  desktopView: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  painRehabDesktop: {
    marginTop: '10px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '0px',
    },
  },
  painRehabMobile: {
    marginTop: '0px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '10px',
    },
  },
  anyTimeMobile: {
    marginTop: '0px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '10px',
    },
  },
  mainText: {
    marginTop: '100px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '0px',
    },
  },
  ourStretch: {
    marginLeft: '140px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0px',
    },
  },
  titleForVideo: {
    fontSize: '38px',
    fontWeight: 900,
    diplay: 'flex',
    justifyContet: 'center',
    textAlign: 'center',
    paddingTop: '20px',
    // width : "907px",
    [theme.breakpoints.down('xs')]: {
      fontSize: '32px',
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  },
  reactPlayerRoot: {
    diplay: 'flex',
    justifyContet: 'center',
    textAlign: 'center',
    alignItems: 'center',
    paddingLeft: '15%',
    paddingRight: '15%',
    paddingBottom: 80,
    paddingTop: 75,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  },
}));

// background-size: 380px 300px;
// background-color: #28aae2;
// background-image: url(../static/images/circle-banner.png);
// background-position: 0px 100%;

const Affiliate = ({ onFullscreenStatusChange }) => {
  const classes = useStyles();
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const handleClickLink = () => {
    window.location.href = `https://app.movementvault.com/auth/signup`;
  };

  // let title = 'Movement Vault - The #1 app for stretching and mobility';
  // let description =
  //   'Try Movement Vault for free. 1000+ short and effective stretching and mobility video routines. Improve mobility, alleviate pain, prevent injury.';

  return (
    <div style={{backgroundColor : "white" }}>
      <head>
        <title>#1 Stretching, Rehab, and Mobility app</title>

        <PageSeoTags
          title='#1 Stretching, Rehab, and Mobility app'
          description='Fix your pain with injury rehab programs. Improve flexibility and mobility with 1500+ video classes. Most effective online stretching and mobility program'
        />
      </head>
      
      {/* <Navbar transparent changeWhenScroll position='sticky' isIndexPage /> */}
      <Navbar
        transparent={false}
        tab='PROGRAMS'
        changeWhenScroll
        position='sticky'
        hasStartButton={true}
        isIndexPage
      />

      <ProgramsHeader
        text='A BETTER WAY TO STRETCH.'
        textOne='IMPROVE FLEXIBILITY & MOBILITY. FIX And PREVENT PAIN + INJURY.'
        description='Daily stretching & mobility video classes and Programs.'
        descriptionOne='Created by top Doctors of Physical Therapy.'
        buttonText='TRY FOR FREE'
        image={freeClassAllDevices}
      />

      <ProgramsSponsors />

      <Rehab />
      <HowMovmentVaultWorks
        title='HOW MOVEMENT VAULT WORKS'
        descriptionOne='Movement Vault is a revolutionary way to stretch, fix and prevent pain and injury.'
        descriptionTwo='Perform daily stretching and mobility video classes from anywhere, on any device. Choose from over 1500+ classes and counting. Classes for all levels, and everybody.'
        descriptionThree='Plus fix your pain and injuries with complete programs created by world class Doctors of Physical Therapy.'
        // img={catherineHypermobility}
        img={howmovmentvaultworks}
        // img={`url(../../../assets/images/howmovmentvaultworks.png)`}
        onClick={handleClickLink}
        buttonText={"TRY FOR FREE"}



        isButton={true}
      />

      <ProgramsWillHelpYou
        title='The Movement Vault app will help you:'
        // img={phone}
        img={freeclassgraysonsterchingphonevertical}
        descriptionOne='Improve your joint flexibility, mobility, and stability'
        descriptionTwo='Improve your core activation and stability'
        descriptionThree='Improve your balance'
        descriptionFour='Improve your body awareness'
        descriptionFive='Fix and prevent pain and injury'
        descriptionSix='Improve your performance in your daily activities and in the gym'
        descriptionSeven='Get you back to doing everything you love to do, pain-free'
        onClick={handleClickLink}
        buttonText={"TRY FOR FREE"}
      />
      <Review
        reviews={[
          {
            text: 'To say that this app changed my life is an understatement. I am pretty active and enjoy working out, but sit all day for work. Over the years I’ve noticed my muscles have been getting tighter, which has led to low back and knee pain. I tried yoga and a few other programs, which were boring and only helped a little. Movement Vault’s daily stretching classes have helped me improve my flexibility tremendously. I felt a difference after my first class and was hooked. 15 minutes a few times a week and I feel amazing!',
            author: 'Janelle P.',
          },
          {
            text: 'I had been living with with back pain for about 6 years and you name it, I tried it. I’d get some relief, but was never able to fully resolve my back pain. The Movement Vault app was a game changer for me. I now have literally zero pain. I am finally able to get back to the gym and rough house with my grandkids. Since completing the back program, I’ve continued with their daily stretching classes. I highly recommend their low back program and daily stretching video classes.',
            author: 'Mike D.',
          },
          {
            text: 'I never knew I was stretching the wrong way. I have always stretched, but learned that I was doing static stretches, which aren’t very effective. No wonder I have made little progress. Movement Vault’s active stretching was way different than anything else I’ve tried. My shoulder and hip mobility has improved significantly since using their app. I can now get deeper with squats without knee pain and my form with shoulder overhead movements are much better and pain-free. This app has helped me in so many ways.',
            author: 'Marshall H.',
          },
          {
            text: 'This app saved my neck. My neck pain started in college and got worse over the past decade. I ignored the pain until it got so bad that I had to do something. I saw two PTs, and three chiropractors. They helped some, but never fully cured my pain. I found myself taking advil everyday just to get through the day. I completed Movement Vault’s neck pain program, and wow, I forgot what it’s like to not have neck pain. I can now work without pain and have stopped taking pain medication. This program helped so much.',
            author: 'Stephanie R.',
          },
          {
            text: 'My knee pain is gone thanks to Movement Vault! I sit at work and so I have very tight hips, especially my hip flexors. I was told that this puts pressure on my knee cap. After about 3 months of doing the stretches I found on youtube, my knee pain kept getting worse. Sitting for more than 30 minutes was very uncomfortable. I listened to a podcast that mentioned this app and started the program. The active stretches in the program are so effective. After about week 6, my knee was completely pain free.  ',
            author: 'Travis H.',
          },
        ]}
      />
      <ProgramsUsersPercentage
        ringComponents={[
          {
            percent: 93,
            description:
              'of Movement Vault users significantly improved their flexibility + mobility within 60 days',
          },
          {
            percent: 86,
            description:
              'of Movement Vault users that had pain, were able to become pain-free after using Movement Vault for 60-days',
          },
          {
            percent: 98,
            description:
              'of users recommend Movement Vault to their friends and family',
          },
        ]}
        totalUsersSurvey='*based on a survey of 10,536 Movement Vault users'
      />
      <Box>
        <Typography
          className={classes.titleForVideo}
          //   variant='h4'
        >
          <div>Start Improving Your Flexibility and Living Pain-Free</div>
          <div>Just Like These Members</div>
        </Typography>
      </Box>
      <div className={classes.reactPlayerRoot}>
        <NoSsr>
          <ReactPlayer
            config={{
              file: {
                attributes: { controlsList: 'nodownload' },
              },
            }}
            onContextMenu={(e) => e.preventDefault()}
            width='100%'
            height='100%'
            controls
            // url={'https://d1glqvkiwyy8rv.cloudfront.net/HIPS_AND_ANKLES.mp4'}
            url={'https://d1glqvkiwyy8rv.cloudfront.net/mov.mp4'}
            playing={false}
            // controls={true}
            playsinline={true}
          />
        </NoSsr>
      </div>
      <ProgramsDoctor
        titleOne='CREATED BY TOP DOCTORS OF PHYSICAL THERAPY'
        descriptionOne='Our active stretching methodology is backed by research and uses the most effective'
        descriptionTwo='active stretches, and muscle activation exercises, not static stretching. Focusing on static stretching'
        descriptionThree='is one of the main reasons why other programs fall short and don’t actually improve your flexibility.'
        descriptionOneMobile='Our active stretching methodology is backed by research and uses the most effective active stretches, and muscle activation exercises, not static stretching.'
        descriptionTwoMobile='Focusing on static stretching is one of the main reasons why other programs fall short and don’t actually improve your flexibility.'
      />

      <ProgramsHaveYouTried
        title='STRETCHING THAT WORKS'
        descriptionOne='Frustrated with not achieving flexibility progress with your current stretching routine?'
        descriptionTwo='The #1 reason most people fail to achieve flexibility results is due to stretching the wrong way. Traditional static stretching is the wrong way to stretch.'
        descriptionThree='Movement Vault’s revolutionary active stretching methodology is backed by research and is proven to help you achieve lasting flexibility and mobility results.'
        // img={catherineHypermobility}
        // img={`url(../../../assets/images/free-class-mauro-strentching.png)`}ddddd
        img={freeclassmaurostrentching}
        isButton={true}
        onClick={handleClickLink}
        buttonText={"TRY FOR FREE"}


      />

      <ProgramsHelped
        title='FIX YOUR PAIN FOR GOOD'
        descriptionOne='Tired of suffering with joint pain and injuries even though you’ve tried everything including physical therapy, chiropractors, and even using other injury programs? You aren’t alone.'
        descriptionTwo='We have helped thousands of people just like you fix the root cause of their pain and injuries.'
        descriptionThree='We can help you too. Imagine doing everything you love to do in life without pain. You can.'
        // img={meAndCatherine}
        img={freeclassmeandcatherine}
        onClick={handleClickLink}
        buttonText={"TRY FOR FREE"}


      />

      <ProgramsWillHelpYou
        title='The Movement Vault app includes:'
        // img={phone}
        image={freeClassAllDevices}
        
        descriptionOne='New 10-20 minute stretching video class everyday'
        descriptionTwo='1500+ video classes to choose from'
        descriptionThree='Pain & Injury programs such as our 16-week low back pain program'
        descriptionFour='Classes for all levels, all ages, everyone'
        descriptionFive='Our Virtual Mobility Assessment'
        descriptionSix='Little to no equipment needed'
        descriptionSeven='Use on any device, at anytime'
        isButton={true}
        onClick={handleClickLink}
        buttonText={"TRY FOR FREE"}

      />

      <MobilityScore
        title='WHAT’S YOUR MOBILITY SCORE?'
        descriptionOne='Wondering which of your muscles and joints are tight? Tight joints lead to pain and injury.'
        descriptionTwo=' Take Movement Vault’s Virtual Mobility Assessment and generate your customized Total Mobility Score and generate a mobility score for each major joint in your body.'
        descriptionThree='This will help you focus on the areas you need to improve most and help you track your progress.'
        // img={meAndCatherine}
        img={doublePhone}
        onClick={handleClickLink}
       buttonText={"START YOUR ASSESSMENT"} 
       


      />
      <Consistent
        title='HELPING YOU STAY CONSISTENT'
        descriptionOne='Never miss your stretching session again.'
        descriptionTwo='Being consistent with stretching can be challenging, but not with Movement Vault.'
        descriptionThree='Movement Vault’s in-app stats and reminders will help you stay motivated and consistent.'
        // img={catherineHypermobility}
        img={howmovmentvaultwokrsphone}
        isButton={true}
        onClick={handleClickLink}
        buttonText={"TRY FOR FREE"}

      />

      <ReadyToUnlock
        titleOne='Ready to Unlock Your flexibility and mobility?'
        titleTwo=' Ready to start living pain-free?'
        description='We’ve helped 100,000+ people and counting since 2017. We can help you too.'
      />
      <NewFooter />
    </div>
  );
};

export default Affiliate;
