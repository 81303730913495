import React from 'react';
import { withStyles } from '@material-ui/styles';


const styles = ({ palette: { primary }, breakpoints }) => ({
 
  container: {
    position: 'relative',
    minHeight: '100%',
    [breakpoints.down('xs')]: {
      width: 'auto',
    },
  }
});

const Layout = ({
  children,
  classes,
  background,
}) => {
  return (
    <div className={classes.container}>
        <div className={classes.children}>{children}</div>
    </div>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default withStyles(styles)(Layout);
