import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1,
    textDecoration: 'none',
  },
  linkContainer: {
    paddingLeft: '1.5vw',
    paddingRight: '1.5vw',
    height: '100%',
    'border-bottom': ({ isSelected }) =>
      isSelected
        ? `4px solid ${theme.palette.secondary.main}`
        : '4px solid transparent',
    '&:hover': {
      'border-bottom': `4px solid ${theme.palette.secondary.main}`,
    },
    cursor: 'pointer',
  },
  link: {
    color: 'white',
    fontWeight: ({ isSelected }) => (isSelected ? 800 : 500),
    fontSize: ({ isBig }) => (isBig ? 11 : 11),
    letterSpacing: ({ isBig }) => (isBig ? 1 : 0.85),
  },
}));

const NavbarLink = ({
  text,
  isSelected,
  isBig = false,
  link,
  target = '_self',
  onClick,
  style = {},
}) => {
  const classes = useStyles({ isSelected, isBig });
  return (
    <a href={link} className={classes.root} target={target} style={style}>
      {/* <Box  display='flex' alignItems='center' className={classes.linkContainer}>
        <Typography  className={classes.link} variant='h6'>
          {text === "PROGRAMS" ? <div onClick={onClick}>{text}</div> : text}
        </Typography>
      </Box> */}

      {text === 'PROGRAMS' ? (
        <Box
          onClick={onClick}
          display='flex'
          alignItems='center'
          className={classes.linkContainer}
        >
          <Typography className={classes.link} variant='h6'>
            {text}
          </Typography>
        </Box>
      ) : (
        <Box
          display='flex'
          alignItems='center'
          className={classes.linkContainer}
        >
          <Typography className={classes.link} variant='h6'>
            {text}
          </Typography>
        </Box>
      )}
    </a>
  );
};

NavbarLink.propTypes = {
  text: PropTypes.string.isRequired,
  target: PropTypes.string,
  link: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,
  isBig: PropTypes.bool,
};

export default NavbarLink;
