import React from 'react';
import PropTypes from 'prop-types';

const TAG_KEYS = {
  TITLE: 'title',
  DESCRIPTION: 'description',
  IMAGE: 'image',
  FB: {
    TITLE: 'fbtitle',
    DESCRIPTION: 'fbdescription',
    IMAGE: 'fbimage',
  },
  TWITTER: {
    TITLE: 'twittertitle',
    DESCRIPTION: 'twitterdescription',
    IMAGE: 'twitterimage',
  },
};

const SeoTags = () => {
  return (
    <>
      <link rel='icon' href='/favicon.svg' />
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <meta name='theme-color' content='#000000' />

      <meta name='title' content='Movement Vault' />
      <meta
        name='description'
        content='Fix your pain with injury rehab programs. Improve flexibility and mobility with 1500+ video classes. Most effective online stretching and mobility program.'
      />

      <meta property='og:type' content='website' />
      <meta
        property='og:title'
        content='Movement Vault'
        key={TAG_KEYS.FB.TITLE}
      />
      <meta property='og:site_name' content='Movement Vault' />
      <meta property='og:url' content='https://movement-vault.netlify.app' />
      <meta
        property='og:image'
        content='https://movement-vault-web.netlify.app/static/images/promo.jpg'
        key={TAG_KEYS.FB.IMAGE}
      />
      {/* <meta
        property='og:image'
        content='https://movement-vault.netlify.app/promo-1.png'
      />
      <meta
        property='og:image'
        content='https://movement-vault.netlify.app/promo-2.png'
      />
      <meta
        property='og:image'
        content='https://movement-vault.netlify.app/promo-3.png'
      />
      <meta
        property='og:image'
        content='https://movement-vault.netlify.app/promo-4.png'
      /> */}
      <meta
        name='description'
        content='Fix your pain with injury rehab programs. Improve flexibility and mobility with 1500+ video classes. Most effective online stretching and mobility program.'
        key={TAG_KEYS.DESCRIPTION}
      />
      <meta
        property='og:description'
        content='Fix your pain with injury rehab programs. Improve flexibility and mobility with 1500+ video classes. Most effective online stretching and mobility program.'
        key={TAG_KEYS.FB.DESCRIPTION}
      />

      <meta property='twitter:card' content='summary_large_image' />
      <meta
        property='twitter:url'
        content='https://movement-vault.netlify.app'
      />
      <meta
        property='twitter:title'
        content='Movement Vault'
        key={TAG_KEYS.TWITTER.TITLE}
      />
      <meta
        property='twitter:description'
        content='Fix your pain with injury rehab programs. Improve flexibility and mobility with 1500+ video classes. Most effective online stretching and mobility program.'
        key={TAG_KEYS.TWITTER.DESCRIPTION}
      />
      <meta
        property='twitter:image'
        content='https://movement-vault-web.netlify.app/static/images/promo.jpg'
        key={TAG_KEYS.TWITTER.IMAGE}
      />
      {/* <meta
        property='twitter:image'
        content='https://movement-vault.netlify.app/promo-1.png'
      />
      <meta
        property='twitter:image'
        content='https://movement-vault.netlify.app/promo-2.png'
      />
      <meta
        property='twitter:image'
        content='https://movement-vault.netlify.app/promo-3.png'
      />
      <meta
        property='twitter:image'
        content='https://movement-vault.netlify.app/promo-4.png'
      /> */}
    </>
  );
};

export default SeoTags;

export const PageSeoTags = ({ title, description, image }) => {
  return (
    <>
      {title && <meta name='title' content={title} key={TAG_KEYS.TITLE} />}
      {title && (
        <meta property='og:title' content={title} key={TAG_KEYS.FB.TITLE} />
      )}
      {title && (
        <meta
          property='twitter:title'
          content={title}
          key={TAG_KEYS.TWITTER.TITLE}
        />
      )}

      {description && (
        <meta
          name='description'
          content={description}
          key={TAG_KEYS.DESCRIPTION}
        />
      )}
      {description && (
        <meta
          name='og:description'
          content={description}
          key={TAG_KEYS.FB.DESCRIPTION}
        />
      )}
      {description && (
        <meta
          name='twitter:description'
          content={description}
          key={TAG_KEYS.TWITTER.DESCRIPTION}
        />
      )}

      {image && (
        <meta property='og:image' content={image} key={TAG_KEYS.FB.IMAGE} />
      )}
      {image && (
        <meta
          property='twitter:image'
          content={image}
          key={TAG_KEYS.TWITTER.IMAGE}
        />
      )}
    </>
  );
};

PageSeoTags.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
};
