import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Typography } from '@material-ui/core';
import ProgramsRingComponent from './ProgramsRingComponent';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: 135,
    paddingLeft: 280,
    paddingRight: 280,
    paddingBottom: 127,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 60,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 20,
    },
  },
  percentageContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: "66px",
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  singleDescriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  percentage: {
    // backgroundImage: percentage,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  totalUsersSurveyContainer: {
    color: '#020401',
    fontFamily: 'Lato',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '32px',
    paddingTop: '59px',
    [theme.breakpoints.down('xs')]: {
      display : "flex",
      textAlign : "center",
      justifyContent : "center",
      fontSize : 16,
    },
  },
}));

const ProgramsUsersPercentage = ({ ringComponents, totalUsersSurvey }) => {
  const classes = useStyles();

  return (
    <Box className={classes.mainContainer}>
      <div className={classes.percentageContainer}>
        {ringComponents.map((ringData) => {
          return (
            <ProgramsRingComponent
              percent={ringData.percent}
              description={ringData.description}
            />
          );
        })}
      </div>
      <div className={classes.totalUsersSurveyContainer}>
        {totalUsersSurvey}
      </div>
    </Box>
  );
};

export default ProgramsUsersPercentage;

// position: absolute;
// margin-left: 115px;
// margin-top: 98px;
