import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { withStyles } from '@material-ui/styles';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import { Button } from 'components';
import { Creators } from '../reducer';
import { BASE_URL, TOKEN } from 'core/constants';
import * as localStorage from 'core/utils/localStorage';
import cards from 'assets/svg/cards.svg';
import { Creators as ModalCreators } from 'modules/modal';
import debounce from 'lodash/debounce';
import { IOS_APP_URL } from 'core/constants';
import axios from 'axios';
import { useLocation } from 'react-router-dom';


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
  console.error("Couldn't find Stripe publishable key");
}

// TODO: Cleanup and better error/success handling needed.
const styles = (theme) => {
  return {
    alertText: {
      marginBottom: '12px',
    },
    button: {
      width: 'calc(50% - 12px)',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        margin: '12px 0px',
      },
    },
    buttons: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '36px',
      [theme.breakpoints.down('md')]: {
        display: 'block',
      },
    },
    container: {
      width: '100%',
    },
    content: {
      borderRadius: 2,
      '& > input': {
        border: `1px solid ${theme.palette.primary.gray}`,
        boxSizing: 'border-box',
        padding: theme.spacing(2),
        width: '100%',
        fontSize: 16,
      },
      '& > input:focus': {
        outline: `2px solid ${theme.palette.primary.main}`,
        border: 'none',
      },
    },
    error: {
      paddingBottom: 10,
    },
    form: {
      background: theme.palette.background.paper,
      borderRadius: theme.radius,
      padding: `64px 92px`,
      width: 600,
      overflowY: 'auto',
      [theme.breakpoints.down('md')]: {
        height: '100%',
        padding: 16,
        width: '100%',
      },
      '& h1': {
        marginTop: 0,
      },
    },
    linkBlock: {
      color: theme.palette.primary.main,
      marginBottom: '1.5rem',
      display: 'block',
      textDecoration: 'none',
    },
    input: {
      width: 'calc(50% - 12px)',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    inputField: {
      width: '100%',
    },
    inputRow: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    label: {
      fontSize: 14,
      marginBottom: theme.spacing(0.5),
      textTransform: 'capitalize',
    },
    labelError: {
      color: theme.palette.messages.error,
    },
    link: {
      '& > a': {
        textDecoration: 'none',
      },
    },
    stripeInput: {
      border: `1px solid ${theme.palette.primary.gray}`,
      padding: theme.spacing(2),
      height: 50,
      '&:focus': {
        outline: `2px solid ${theme.palette.primary.main}`,
        border: 'none',
      },
    },
    title: {
      fontSize: 24,
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    checkCouponCodeButton: {
      // marginTop: '21px',
      // color: ' #fff',
      // background: '#28aae2',
      // height: '53px',
      // borderRadius: '0px',
      // width: '30%',
      position: 'absolute',
      top: 0,
      borderRadius: '5px',
      right: '0px',
      zZndex: 2,
      border: 'none',
      top: '2px',
      height: '40px',
      cursor: 'pointer',
      color: ' #fff',
      backgroundColor: '#28aae2',
      transform: 'translateX(2px)',
      marginTop: '34px',
      width: '70px',
      marginRight: '7px',
      borderRadius: '2px',
    },
    pricingMainContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    pricingRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: '12px',
    },
  };
};

const InputField = ({
  classes,
  label,
  name,
  onChange,
  placeholder,
  required = false,
  type,
  value,
  ...props
}) => (
  <div className={classes.inputField}>
    <div className={classes.label}>{label}</div>
    <div className={classes.content}>
      <input
        onChange={onChange ? (e) => onChange(e.target.value) : () => {}}
        id={name}
        type={type}
        placeholder={placeholder}
        required={required}
        value={value}
        {...props}
      />
    </div>
  </div>
);


const CheckoutForm = ({
  classes,
  selectedPlan,
  onCancelClick,
  onSuccess,
  user,
  sendManageSubscriptionEmail,
  couponId

}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [subscribing, setSubscribing] = useState(false);
  const [fullName, setFullName] = useState('');
  const [errorToDisplay, setErrorToDisplay] = useState('');
  const [couponResponse, setCouponResponse] = useState(null);
  const [couponCode, setCopounCode] = useState(couponId || "");
  // const [couponCode1, setCouponCode1] = useState(couponId || '');




  

  // const [test,setTest] = useState(document.getElementById('coupon-code').length || 0)
  // console.log(test , "TEEEEST")

  //   const input = document.getElementById('coupon-code');
  //   console.log(document.getElementById('coupon-code').value , "BUX")

  // const value1 = input?.length || null;

  // console.log(value1)

  const sendManageSubscriptionEmailDebounced = useRef(
    debounce(
      () => {
        sendManageSubscriptionEmail();
      },
      60000,
      { leading: true }
    )
  );

  useEffect(() => {
    const handleScrollFocussedIntoView = debounce(
      () => {
        if (document.activeElement.tagName) {
          setTimeout(() => {
            requestAnimationFrame(() => {
              document.activeElement.scrollIntoView({
                block: 'center',
                inline: 'center',
                behavior: 'auto',
              });
            });
          }, 0);
        }
      },
      300,
      { leading: false, trailing: true }
    );

    handleScrollFocussedIntoView();

    window.addEventListener('resize', handleScrollFocussedIntoView);
    window.addEventListener('scroll', handleScrollFocussedIntoView);

    return () => {
      window.removeEventListener('resize', handleScrollFocussedIntoView);
      window.removeEventListener('scroll', handleScrollFocussedIntoView);
    };
  }, []);

  function createSubscription({ paymentMethodId, couponCode }) {
    const token = localStorage.getItem(TOKEN);
    return (
      fetch(`${BASE_URL}/payment/stripe/pay`, {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          paymentMethodId: paymentMethodId,
          code: selectedPlan.code,
          couponCode,
        }),
      })
        .then((response) => {
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.errors) {
            // The card had an error when trying to attach it to a customer
            throw result.message;
          }
          return result;
        })
        // Normalize the result to contain the object returned
        // by Stripe. Add the additional details we need.
        .then((result) => {
          onCancelClick();
          onSuccess();
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            subscription: result,
            paymentMethodId: paymentMethodId,
            priceId: selectedPlan.code,
          };
        })
        // No more actions required. Provision your service for the user.
        .catch((errorMessage) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          setSubscribing(false);
          setErrorToDisplay(errorMessage);
        })
    );
  }





  async function checkCouponCode(couponCode) {
    const token = localStorage.getItem(TOKEN);
    console.log("NIKAia" , couponCode,token,TOKEN)

    try {
      const response = await fetch(
        `${BASE_URL}/payment/stripe/check/${couponCode}`,
        {
          method: 'get',
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response,"RESP")
      const body = await response.json();
      console.log(body,"BODY")
      if (response.ok) {
        const codeIncludesPlan = body.membershipPlans.find((plan) => {
          return plan.code == selectedPlan.code;
        });

        if (body.membershipPlans.length > 0 && !codeIncludesPlan) {
          return setCouponResponse({
            data: null,
            errorMessage: 'Coupon code does not apply to the selected plan',
          });
        }
        setCouponResponse({
          data: body,
          errorMessage: null,
        });
      } else {
        setCouponResponse({
          data: null,
          errorMessage: body.message,
        });
      }
    } catch (e) {
      setCouponResponse({
        data: null,
        errorMessage: e,
      });
    }
  }


  useEffect(() => {
    if (couponId) {
      console.log("datoia" , couponCode)

      checkCouponCode(couponId);
    }
  }, [couponId]);

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    setSubscribing(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    if (!user.paymentMethod || !user.paymentMethod.id) {
      const cardElement = elements.getElement(CardNumberElement);
      // Use your card Element with other Stripe.js APIs
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: fullName,
        },
      });

      if (error) {
        setSubscribing(false);
        setErrorToDisplay(error && error.message);
        return;
      }

      const paymentMethodId = paymentMethod.id;

      // Create the subscription
      createSubscription({
        paymentMethodId: paymentMethodId,
        couponCode: document.getElementById('coupon-code').value,
      });
      return;
    }
    createSubscription({
      paymentMethodId: user.paymentMethod.id,
      couponCode: document.getElementById('coupon-code').value,
    });
    return;
  };
  const getIntervalText = ({
    billingType,
    interval,
    intervalCount,
    intervalType,
  }) => {
    if (billingType === 'once') return 'in one transaction';

    if (interval === 'year') return 'yearly';
    if (interval === 'month') return 'monthly';
    if (interval === 'week') return 'weekly';
    if (interval === '3months') return 'every 3 months';
    if (interval === '6months') return 'every 6 months';

    if (interval === 'custom') {
      if (parseInt(intervalCount) === 1) {
        if (intervalType === 'day') return 'daily';
        if (intervalType === 'month') return 'monthly';
        if (intervalType === 'year') return 'yearly';
      }
      return `every ${intervalCount} ${intervalType}s`;
    }
  };

  const ManagedByIosMessage = () => {
    sendManageSubscriptionEmailDebounced.current();



    return (
      <div className={classes.form}>
        <div className={classes.alertText}>
          <h1>Manage subscription</h1>
          <p>
            Your subscription is managed in your iOS device. Please use the link
            below to continue.
          </p>
          <a
            className={classes.linkBlock}
            href={IOS_APP_URL}
            target='_blank'
            rel='noopener noreferrer'
          >
            Movement Vault
          </a>
        </div>
        <div className={classes.button}>
          <Button title='Okay' onClick={onCancelClick} />
        </div>
      </div>
    );
  };

  const UpgradeForm = () => {
    return (
      <div className={classes.form}>
        <form id='payment-form' onSubmit={handleSubmit}>
          <div className={classes.alertText}>
            Are you sure you want to upgrade your plan?
          </div>
          <div style={{ marginBottom: 16 }}>
            <InputField
              classes={classes}
              name='coupon-code'
              label='Coupon Code'
              type='text'
              placeholder='Coupon Code'
            />
          </div>

          <div className={classes.buttons}>
            <div className={cx(classes.button, classes.link)}>
              <Button title='Cancel' outlined onClick={onCancelClick} />
            </div>
            <div className={classes.button}>
              <Button
                processing={subscribing}
                processingLabel='Subscribing...'
                title='Upgrade plan'
                type='submit'
              />
            </div>
          </div>
          {errorToDisplay && (
            <div className={cx(classes.error, classes.labelError)}>
              {errorToDisplay}
            </div>
          )}
        </form>
      </div>
    );
  };

  // const _upgradeForm = !!user.appStoreId ? (
  //   <ManagedByIosMessage />
  // ) : (
  //   <UpgradeForm />
  // );

  if (!!user.appStoreId) {
    return (
      <div>
        <ManagedByIosMessage />
      </div>
    );
  }


  console.log(selectedPlan,"SELECTED")


  return (
    <div>
      {user.paymentMethod && user.paymentMethod.id ? (
        <UpgradeForm />
      ) : (
        <div id='payment-form' className={classes.form}>
          <div>
            <div className={classes.title}>
              {selectedPlan?.trialDuration &&
                `${selectedPlan?.trialDuration} day free trial`}
            </div>
            <div className={classes.label} style={{ marginBottom: 16 }}>
              No commitment. Cancel anytime.
            </div>
            <img src={cards} alt='card brands' style={{ marginBottom: 16 }} />
            <div>
              <form id='payment-form' onSubmit={handleSubmit}>
                <div style={{ marginBottom: 16 }}>
                  <InputField
                    classes={classes}
                    id='name'
                    label='Card Name'
                    onChange={(value) => setFullName(value)}
                    type='text'
                    placeholder='Full Name'
                    value={fullName}
                    tabIndex={1}
                    autoFocus
                    required
                  />
                </div>
                <div id='card-element'>
                  <div style={{ marginBottom: 16 }}>
                    <div className={classes.label}>Card Number</div>
                    <CardNumberElement
                      className={classes.stripeInput}
                      tabIndex={2}
                    />
                  </div>
                  <div
                    className={classes.inputRow}
                    style={{ marginBottom: 16 }}
                  >
                    <div className={classes.input}>
                      <div className={classes.label}>Expiry Date</div>
                      <CardExpiryElement
                        className={classes.stripeInput}
                        tabIndex={3}
                      />
                    </div>
                    <div className={classes.input}>
                      <div className={classes.label}>CVC</div>
                      <CardCvcElement
                        className={classes.stripeInput}
                        tabIndex={4}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginBottom: 16,
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'relative',
                  }}
                >
                  


                  {couponId ? 
                
                <InputField
                classes={classes}
                className={classes.pricingRow}
                name='coupon-code'
                label='Coupon Code'
                type='text'
                placeholder='Coupon Code'
                id='coupon-code'
                // onChange={(e)=>{
                //   setCopounCode(e.target.couponCode)
                // }}
                onChange={(value) => {
                  setCouponResponse(null);
                  setCopounCode(value);
                }}
                value={couponCode}
              /> : 
<>
<InputField
                    classes={classes}
                    className={classes.pricingRow}
                    name='coupon-code'
                    label='Coupon Code'
                    type='text'
                    placeholder='Coupon Code'
                    id='coupon-code'
                    // onChange={(e)=>{
                    //   setCopounCode(e.target.couponCode)
                    // }}
                    onChange={(value) => {
                      setCouponResponse(null);
                      setCopounCode(value);
                    }}
                    value={couponCode}
                  />
                  {couponCode.length > 0 ? (
                    <Button
                      className={classes.checkCouponCodeButton}
                      disabled={!!couponResponse}
                      title='APPLY'
                      onClick={() => {
                        checkCouponCode(couponCode);
                      }}
                    />
                  ) : null}

</>
                }

                  
                </div>
                {couponResponse?.errorMessage && (
                  <div className={cx(classes.error, classes.labelError)}>
                    {couponResponse?.errorMessage}
                  </div>
                )}

                {/* <div style={{ fontSize: 14, marginBottom: 32 }}>
                  {selectedPlan.trialDuration
                    ? `You won’t be charged until after your free ${selectedPlan.trialDuration} days. `
                    : ''}
                  <b>${selectedPlan.price / 100}</b> billed{' '}
                  {getIntervalText(selectedPlan)}{' '}
                  {selectedPlan.trialDuration && 'after free trial.'}
                </div> */}
                {/* <ul
                  style={{ fontSize: 14, paddingLeft: 14, paddingBottom: 32 }}
                >
                  <li>
                    <b>${selectedPlan.price / 100}</b> billed{' '}
                    {getIntervalText(selectedPlan)}.
                  </li>

                  {selectedPlan.trialDuration && (
                    <li>
                      You won’t be charged until after your free{' '}
                      <b>{selectedPlan.trialDuration}</b> days.
                    </li>
                  )}
                  {couponResponse?.data && (
                    <li>
                      <b>{couponResponse?.data.amount}%</b> discount. Pay{' '}
                      <b>
                        $
                        {Math.ceil(
                          selectedPlan.price *
                            ((100 - couponResponse?.data.amount) / 100)
                        ) / 100}
                      </b>{' '}
                      for your first payment.
                    </li>
                  )}
                </ul> */}

                {/* {couponResponse?.data && (
                    <li>
                      <b>{couponResponse?.data.amount}%</b> discount. Pay{' '}
                      <b>
                        $
                        {Math.ceil(
                          selectedPlan.price *
                            ((100 - couponResponse?.data.amount) / 100)
                        ) / 100}
                      </b>{' '}
                      for your first payment.
                    </li>
                  )} */}

                <div className={classes.pricingMainContainer}>
                  <div className={classes.pricingRow}>
                    <div style={{ fontSize: '14px' }}>Subtotal</div>
                    <div style={{ fontSize: '14' }}>
                      ${selectedPlan?.price / 100}
                    </div>
                  </div>
                  {couponResponse?.data && (
                    <div className={classes.pricingRow}>
                      <div style={{ fontSize: '14px' }}>Discount</div>
                      <div style={{ fontSize: '14px' }}>
                        -$
                        {/* {selectedPlan.price / 100 -
                          Math.ceil(
                            selectedPlan.price *
                              ((100 - couponResponse?.data.amount) / 100)
                          ) /
                            100} */}
                        {couponResponse?.data.type === 'percent' ? (
                          <>
                            {(
                              selectedPlan?.price / 100 -
                              Math.ceil(
                                selectedPlan?.price *
                                  ((100 - couponResponse?.data.amount) / 100)
                              ) /
                                100
                            ).toFixed(2)}
                          </>
                        ) : (
                          <>{couponResponse?.data.amount}</>
                        )}
                      </div>
                    </div>
                  )}

                  {selectedPlan.trial === true ? 
                  <div className={classes.pricingRow}>
                    <div style={{ fontSize: '16px' }}>
                      Total after free trial
                    </div>
                    <div style={{ fontSize: '16px' }}>
                      {couponResponse?.data ? (
                        <>
                          $
                          {/* {Math.ceil(
                            selectedPlan.price *
                              ((100 - couponResponse?.data.amount) / 100)
                          ) / 100} */}
                          {couponResponse?.data.type === 'percent' ? (
                            <>
                              {(
                                Math.ceil(
                                  selectedPlan?.price *
                                    ((100 - couponResponse?.data.amount) / 100)
                                ) / 100
                              ).toFixed(2)}
                            </>
                          ) : (
                            <>
                              {selectedPlan?.price / 100 -
                                couponResponse?.data.amount}
                            </>
                          )}
                        </>
                      ) : (
                        <>${selectedPlan?.price / 100}</>
                      )}
                    </div>
                  </div> : null}
                  <div className={classes.pricingRow}>
                    <div style={{ fontSize: '16px' }}>
                      <b>Total due today</b>
                    </div>
                    <div style={{ fontSize: '16px' }}>
                      {selectedPlan.trial === true ? <b>$0.00</b> :<b>{couponResponse?.data.type === 'percent' ? (
                            <>$
                              {(
                                Math.ceil(
                                  selectedPlan?.price *
                                    ((100 - couponResponse?.data.amount) / 100)
                                ) / 100
                              ).toFixed(2)}
                            </>
                          ) : (
                            <>$
                              {selectedPlan?.price / 100 -
                                couponResponse?.data.amount}
                            </>
                          )}</b>   }
                     
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    fontSize: 14,
                    marginBottom: 32,
                    marginTop: 23,
                    lineHeight: '23px',
                  }}
                >
                  <div>
                    {selectedPlan?.trialDuration
                      ? `You won’t be charged until after your free ${selectedPlan?.trialDuration} days. `
                      : ''}
                    <b>
                      <br />$
                      {couponResponse?.data ? (
                        <>
                          {/* {couponResponse?.data ? (
                          <>
                            {Math.ceil(
                              selectedPlan.price *
                                ((100 - couponResponse?.data.amount) / 100)
                            ) / 100}
                          </>
                        ) : (
                          <>${selectedPlan.price / 100}</>
                        )} */}
                          {couponResponse?.data.type === 'percent' ? (
                            <>
                              {Math.ceil(
                                selectedPlan?.price *
                                  ((100 - couponResponse?.data.amount) / 100)
                              ) / 100}
                            </>
                          ) : (
                            <>
                              {selectedPlan?.price / 100 -
                                couponResponse?.data.amount}
                            </>
                          )}
                        </>
                      ) : (
                        <>{selectedPlan?.price / 100}</>
                      )}
                    </b>{' '}
                    billed {getIntervalText(selectedPlan)}{' '}
                    {selectedPlan?.trialDuration && 'after free trial.'}
                  </div>
                </div>

                {errorToDisplay && (
                  <div className={cx(classes.error, classes.labelError)}>
                    {errorToDisplay}
                  </div>
                )}

                <div className={classes.buttons}>
                  <div className={cx(classes.button, classes.link)}>
                    <Button title='Cancel' outlined onClick={onCancelClick} />
                  </div>
                  <div className={classes.button}>
                    <Button
                      disabled={couponCode && !couponResponse?.data}
                      processing={subscribing}
                      processingLabel='Subscribing...'
                      title='confirm payment'
                      type='submit'
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* <ManagedByIosMessage />
              {user.appStoreId} */}
        </div>
      )}
    </div>
  );
};

const PaymentForm = ({ getPaymentPlans, plans, planId, couponId, ...rest  }) => {
  useEffect(() => {
    getPaymentPlans();
  }, [getPaymentPlans]);
  if (plans.length < 1 || !planId) return null;

  const parentPlan = plans.find((p) =>
    p.plans.some((plan) => plan.code === planId)
  );
  const selectedPlan = parentPlan?.plans?.find((plan) => plan.code === planId);

  console.log(couponId,"BABAJAANAAA")
  


  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm selectedPlan={selectedPlan} couponId={couponId} {...rest} />
    </Elements>
  );
};

const mapStateToProps = ({ auth: { planId, couponId, user }, payment: { plans } }) => ({
  plans,
  planId,
  couponId,
  user,
});

const mapDispatchToProps = {
  getPaymentPlans: Creators.getPaymentPlans,
  sendManageSubscriptionEmail: Creators.sendManageSubscriptionEmail,
  showModal: ModalCreators.showModal,
  // checkCouponCode : Creators.checkCouponCode
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PaymentForm));
