import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  percentage: {
    // backgroundImage: percentage,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    minHeight: 220,
    width: 230,
  },
  singleDescriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 12,
  },
  text: {
    // marginTop: '100px',
    color: '#020401',
    fontFamily: 'Lato',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '32px',
    // width: 238,
    marginTop: 25,
    [theme.breakpoints.down('xs')]: {
      display : "flex",
      textAlign : "center",
      justifyContent : "center",
      width : 229
    },
  },
  percent: {
    color: 'var(--gray-900, #101828)',
    textAlign: 'center',
    fontFamily: 'Lato',
    fontSize: '36px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '44px',
    letterSpacing: '-0.72px',
  },
  textContainer: {
    marginTop: 80,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 12,
    paddingRight: 12,
  },
}));

const ProgramsRingComponent = ({ percent, description }) => {
  const classes = useStyles();

  return (
    <div className={classes.singleDescriptionContainer}>
      <div
        className={classes.percentage}
        style={{
          backgroundImage: `url(${require(`../../../assets/images/neck-and-pain-ring-${percent}.svg`)})`,

          // backgroundImage: `url("../../../assets/images/neck-and-pain-ring-${percent}.svg")`,

          // backgroundImage: `url("/static/images/neck-and-pain-ring-${percent}.svg")`,
        }}
      >
        <div className={classes.textContainer}>
          <div className={classes.percent}>{percent}%</div>
          <div className={classes.text}>{description}</div>
        </div>
      </div>
    </div>
  );
};

export default ProgramsRingComponent;

// position: absolute;
// margin-left: 115px;
// margin-top: 98px;
