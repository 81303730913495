import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import { Formik, useFormikContext } from 'formik';
import { object, string, number } from 'yup';

import {
  Button,
  FormCheckbox,
  Form,
  FormInput,
  FormRow,
  FormSelect,
  Text,
} from 'components';

const styles = (theme) => ({
  button: {
    width: 'calc(50% - 12px)',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0px',
      width: '100%',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    width: '60%',
  },
  container: {
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.primary.gray}`,
    display: 'flex',
    height: 80,
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  form: {
    padding: '24px 0px',
  },
  formRow: {
    padding: '8px 24px',
    margin: 0,
  },
  input: {
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0px',
      width: '100%',
    },
  },
  inputs: {
    display: 'grid',
    gridGap: 16,
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  inputs2: {
    display: 'flex',
  },
  oddRow: {
    backgroundColor: theme.palette.background.default,
  },
});

const ChildPlanForm = ({
  buttonText,
  classes,
  error,
  onCancel,
  onSubmit,
  parentPlans,
  plan,
  processing,
}) => {
  const FormBody = () => {
    const { isValid, validateForm, values } = useFormikContext();

    useEffect(() => {
      validateForm();
    }, [validateForm]);

    return (
      <Form error={error} showDivider={false} containerClass={classes.form}>
        <FormRow containerClass={classes.formRow}>
          <FormInput
            error={error}
            label='plan name'
            name='title'
            placeholder='Plan Name'
          />
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <FormInput
            error={error}
            label='promo text'
            name='promoText'
            placeholder='Promo Text'
          />
        </FormRow>
        {!plan && (
          <FormRow containerClass={classes.formRow}>
            <div className={classes.inputs}>
              <div className={classes.input} style={{ marginTop: -2 }}>
                <FormInput
                  error={error}
                  label='price'
                  name='price'
                  placeholder='Price'
                  type='currency'
                />
              </div>
              <div>
                <FormSelect
                  height={50}
                  width='100%'
                  label='Billing Type'
                  name='billingType'
                  options={[
                    {
                      label: 'Recurring',
                      value: 'recurring',
                    },
                    {
                      label: 'One Time',
                      value: 'once',
                    },
                  ]}
                />
              </div>
              {values.billingType !== 'once' && (
                <div className={classes.input}>
                  <FormSelect
                    height={50}
                    width='100%'
                    label='Interval'
                    name='interval'
                    options={[
                      {
                        label: 'Yearly',
                        value: 'year',
                      },
                      {
                        label: 'Monthly',
                        value: 'month',
                      },
                      {
                        label: 'Weekly',
                        value: 'week',
                      },
                      {
                        label: '3 months',
                        value: '3months',
                      },
                      {
                        label: '6 months',
                        value: '6months',
                      },
                      {
                        label: 'Custom',
                        value: 'custom',
                      },
                    ]}
                  />
                </div>
              )}
            </div>
          </FormRow>
        )}
        {!plan &&
          values.interval === 'custom' &&
          values.billingType !== 'once' && (
            <div className={classes.oddRow}>
              <FormRow containerClass={classes.formRow}>
                <div className={classes.inputs2}>
                  <div style={{ width: 120, marginRight: 16 }}>
                    <FormInput
                      error={error}
                      label='period'
                      name='intervalCount'
                      type='number'
                    />
                  </div>
                  <div className={classes.input} style={{ marginTop: 2 }}>
                    <FormSelect
                      height={50}
                      width='100%'
                      name='intervalType'
                      options={[
                        {
                          label: 'Months',
                          value: 'month',
                        },
                        {
                          label: 'Days',
                          value: 'day',
                        },
                        {
                          label: 'Years',
                          value: 'year',
                        },
                      ]}
                    />
                  </div>
                </div>
              </FormRow>
            </div>
          )}
        <FormRow containerClass={classes.formRow}>
          <FormCheckbox label={<Text>Trial Period</Text>} name='trialPeriod' />
        </FormRow>
        {values.trialPeriod && (
          <div className={classes.oddRow}>
            <FormRow containerClass={classes.formRow}>
              <div className={classes.inputs2}>
                <div style={{ width: 140, marginRight: 16 }}>
                  <FormInput
                    error={error}
                    label='trial duration'
                    name='trialDuration'
                    type='number'
                  />
                </div>
                <div className={classes.input}>
                  <FormInput
                    error={error}
                    label='trial amount'
                    name='trialAmount'
                    placeholder='Trial Amount'
                    type='currency'
                  />
                </div>
              </div>
            </FormRow>
            <FormRow containerClass={classes.formRow}>
              <FormCheckbox
                label={<Text>Allow only ONE Trial</Text>}
                name='onlyOneTrial'
              />
            </FormRow>
          </div>
        )}
        <FormRow containerClass={classes.formRow}>
          <div className={classes.inputs2}>
            <div
              className={classes.input}
              style={{ marginTop: 2, marginRight: 16, flex: 1 }}
            >
              <FormSelect
                height={50}
                width='100%'
                label='Parent'
                name='parentPlan'
                options={parentPlans.map((plan) => {
                  return { label: plan.title, value: plan.id };
                })}
              />
            </div>
            <div className={classes.input}>
              <FormInput
                error={error}
                label='order'
                name='order'
                placeholder='Order'
              />
            </div>
          </div>
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <FormCheckbox label={<Text>Private</Text>} name='isPrivate' />
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <div className={classes.input}>
            <FormSelect
              height={50}
              width='100%'
              label='Payment Channel'
              name='paymentChannel'
              options={[
                {
                  label: 'In App',
                  value: 'in-app',
                },
                {
                  label: 'Site',
                  value: 'site',
                },
              ]}
            />
          </div>
        </FormRow>
        {values.paymentChannel === 'in-app' && (
          <FormRow containerClass={classes.formRow}>
            <FormInput
              error={error}
              label='Product code'
              name='code'
              placeholder='Apple product code'
            />
          </FormRow>
        )}

        <FormRow containerClass={classes.formRow}>
          <div className={classes.buttons}>
            <div className={classes.button}>
              <Button onClick={onCancel} title='cancel' outlined />
            </div>
            <div className={classes.button}>
              <Button
                disabled={!isValid}
                processing={processing}
                processingLabel='Creating...'
                title={buttonText}
                type='submit'
              />
            </div>
          </div>
        </FormRow>
      </Form>
    );
  };

  const fieldRequiredMsg = 'Required';
  const numberTypeMsg = 'Number expected';

  const validationSchema = object().shape({
    title: string().required('Plan name is required'),
    price: string().required(fieldRequiredMsg),
    billingType: string().required(fieldRequiredMsg),
    interval: string().when('billingType', {
      is: 'recurring',
      then: string().required(fieldRequiredMsg),
    }),
    intervalCount: number()
      .nullable()
      .when('interval', {
        is: 'custom',
        then: number().required(fieldRequiredMsg),
      }),
    intervalType: string()
      .nullable()
      .when('interval', {
        is: 'custom',
        then: string().required(fieldRequiredMsg),
      }),
    trialDuration: number()
      .nullable()
      .when('trialPeriod', {
        is: true,
        then: number().required(fieldRequiredMsg),
      }),
    trialAmount: string()
      .nullable()
      .when('trialPeriod', {
        is: true,
        then: string().required(fieldRequiredMsg),
      }),
    code: string()
      .nullable()
      .when('paymentChannel', {
        is: 'in-app',
        then: string().required(fieldRequiredMsg),
      }),
    parentPlan: string().required(fieldRequiredMsg),
    order: number().required(fieldRequiredMsg).typeError(numberTypeMsg),
  });

  const transformValues = (values) => {
    let intervalCount = 1;
    let intervalType = '';

    switch (values.interval) {
      case 'custom':
        intervalCount = values.intervalCount;
        intervalType = values.intervalType;
        break;
      case '3months':
        intervalCount = 3;
        intervalType = 'month';
        break;
      case '6months':
        intervalCount = 6;
        intervalType = 'month';
        break;
      default:
        intervalCount = 1;
        intervalType = values.interval;
    }

    return {
      ...values,
      onlyOneTrial: !!values.onlyOneTrial,
      isPrivate: !!values.isPrivate,
      trial: values.trialPeriod,
      group: values.parentPlan,
      interval: values.billingType === 'once' ? '' : values.interval,
      intervalCount: intervalCount,
      intervalType: intervalType || null,
    };
  };
  return (
    <Formik
      initialValues={plan || { name: '', interval: '', trialPeriod: false , isCompanyPlan : false }}
      onSubmit={(values) => onSubmit(transformValues(values))}
      validationSchema={validationSchema}
    >
      <FormBody />
    </Formik>
  );
};

export default withStyles(styles)(ChildPlanForm);
