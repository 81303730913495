import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Typography } from '@material-ui/core';

import hulaHopIcon from '../../../assets/images/icon-hula-hoop.svg';
import barbellIcon from '../../../assets/images/icon-barbell.svg';
import shapeIcon from '../../../assets/images/icon-shape.svg';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '65px',
    paddingBottom: '95px',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'space-between',
    gap : "50px",
    alignItems: 'center',
    // paddingLeft: '146px',
    // paddingRight: '146px',
    justifyContent : "center",
    [theme.breakpoints.down('xs')]: {},

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      paddingLeft: '0px',
      paddingRight: '0px',
      gap : "0px",
    },
  },

  singleContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    },
  },

  title: {
    fontSize: '38px',
    fontWeight: 900,
    color: '#020401',
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
      paddingLeft: '5px',
    },
  },
  firstTitle: {
    paddingTop: '30px',
    fontSize: '38px',
    fontWeight: 900,
    color: '#020401',
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
      paddingTop: '0px',
    },
  },
  description: {
    fontSize: '18px',
    color: '#585963',
    fontWeight: 400,
    width: '342px',
    paddingTop: '20px',
    lineHeight: '28px',
    [theme.breakpoints.down('xs')]: {
       display : "flex",
       textAlign : "center",
       alignItems : "center",
       
      },
  },
  divider: {
    'border-bottom': `1px solid ${theme.palette.primary.main}`,
    marginTop: '36px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '517px',
    position : "absolute",
    right : "0px",
    marginTop : "390px",
    [theme.breakpoints.down('xs')]: {
       
        width: '50%',
        marginTop : "780px"

       },
  },
  titleContainerDesk: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  titleContainerPhone: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
        paddingTop : "20px",
      fontWeight: 900,
      color: '#020401',
      fontSize: '24px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: "center"
    },
  },
  pitchIcon:{
    [theme.breakpoints.down('xs')]: {
      paddingTop : "25px",
    },
  }
}));

const Rehab = ({}) => {
  const classes = useStyles();

  return (
    <div className={classes.mainContainer}>
      <div className={classes.container}>
        <div className={classes.singleContainer}>
          <div className={classes.pitchIcon}>
            <img src={hulaHopIcon} alt='hula-hop' />
          </div>
          <div className={classes.titleContainerDesk}>
            <div className={classes.firstTitle}>IMPROVE </div>
            <div className={classes.title}>FLEXIBILITY &</div>
            <div className={classes.title}>MOBILITY</div>
          </div>
          <div className={classes.titleContainerPhone}>
            IMPROVE FLEXIBILITY & MOBILITY
          </div>
          <div className={classes.description}>
            Unlock your tight muscles and achieve lasting flexibility results
            with stretching backed by research.
          </div>
        </div>
        <div className={classes.singleContainer}>
          <div className={classes.pitchIcon}>
            <img src={shapeIcon} alt='hula-hop' />
          </div>
          <div className={classes.titleContainerDesk}>
            <div className={classes.firstTitle}>FIX </div>
            <div className={classes.title}>YOUR</div>
            <div className={classes.title}>PAIN</div>
          </div>
          <div className={classes.titleContainerPhone}>FIX YOUR PAIN</div>

          <div className={classes.description}>
            Become pain-free again using stretches <br/> and exercises that actually
            work. Imagine no longer struggling with joint pain.
          </div>
        </div>
        <div className={classes.singleContainer}>
          <div className={classes.pitchIcon}>
            <img src={barbellIcon} alt='hula-hop' />
          </div>
          <div className={classes.titleContainerDesk}>
            <div className={classes.firstTitle}>REHAB </div>
            <div className={classes.title}>YOUR</div>
            <div className={classes.title}>INJURIES</div>
          </div>
          <div className={classes.titleContainerPhone}>REHAB YOUR INJURIES</div>

          <div className={classes.description}>
            Fix your injuries for good using complete rehab programs created by
            world class Doctors of Physical Therapy.
          </div>
        </div>
      </div>
      <div className={classes.divider} />
      {/* <Box
            className={classes.divider}
            width={{
              xs: '50%',
              sm: '100%',
            }}
            ml={{
              xs: '50%',
              sm: 0,
            }}
          /> */}
    </div>
  );
};

export default Rehab;
