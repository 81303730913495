import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Typography } from '@material-ui/core';
import hoolaHop from '../../../assets/images/icon-hoola-hop.png';

import body from '../../../assets/images/icon-body.png';
import heart from '../../../assets/images/icon-heart.png';
import sitUp from '../../../assets/images/icon-sit-up.png';
import stretch from '../../../assets/images/icon-stretch.png';
import running from '../../../assets/images/icon-running.png';
import stretching from '../../../assets/images/icon-stretching.png';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingTop: 79,
    paddingBottom: 79,
    paddingRight: 79,
    paddingLeft: 152,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: "109px",
    [theme.breakpoints.down('xs')]: {
      gap: "0px",
      paddingTop: 20,
      paddingBottom: 20,
      paddingRight: 20,
      paddingLeft: 20,
    },
  },
  image: {
    width: '372px',
    height: '754px',
    backgroundRepeat : "no-repeat",
    objectFit : "fit",
    backgroundSize: "contain",
    [theme.breakpoints.down('xs')]: {
      backgroundPosition: 'center',
      height: '465px',
    },
  },
  imageTwo: {
    width: '495px',
    height: '327px',
    // width: '372px',
    // height: '754px',
    backgroundRepeat : "no-repeat",
    objectFit : "fit",
    backgroundSize: "contain",
    [theme.breakpoints.down('xs')]: {
      width: '327px',
      height: '218px',
    },
  },
  title: {
    color: '#020401',
    fontFamily: 'Lato',
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: 900,
    lineHeight: '52px',
    letterSpacing: '0.559px',
    [theme.breakpoints.down('xs')]: {
      lineHeight: '38px',
      width: 362,
      display : "flex",
      textAlign : "center",
      justifyContent : "center",

    },
  },
  imageContainer: {
    paddingLeft: '115px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      display : "flex",
      alignItems: "center",
      justifyContent : "center",
      marginTop : 76
    },
  },
  desciprtionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingLeft: '60px',
    paddingTop: '20px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
    },
  },
  singleDescription: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '39px',
    alignItems: 'center',
  },
  icon: {
    width: '48px',
    height: '48px',
    [theme.breakpoints.down('xs')]: {
      width: '38px',
      height: '38px',
    },
  },
  description: {
    color: '#020401',
    fontFamily: 'Lato',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '32px',
    paddingLeft: '21px',
    width: 441,
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      fontSize  :"16px",
      lineHeight : "28px"
    },
  },
  buttonText: {
    fontWeight: 'bold',
    fontSize: 18,
    color: 'white',
    lineHeight: 3.56,
    letterSpacing: 1.38,
  },
  button: {
    marginTop: '60px',
    height: 64,
    width: 240,
    borderRadius: 2,
    [theme.breakpoints.down('xs')]: {
      width : 305
      },
      '&:hover': {
        background: theme.palette.primary.mainDark,
      },
  },
  buttonContainer: {
    marginLeft : "120px",
    [theme.breakpoints.down('xs')]: {
    display : "flex",
    alignItems : "center",
    justifyContent : "center",
    marginLeft : "0px",
    },
  },
}));

const ProgramsWillHelpYou = ({
  title,
  descriptionOne,
  descriptionTwo,
  descriptionThree,
  descriptionFour,
  descriptionFive,
  descriptionSix,
  descriptionSeven,
  img,
  image,
  isButton,
  buttonText,
  onClick

}) => {
  const classes = useStyles();

  return (
    <Box
      display='flex'
      flexDirection={{
        xs: 'column',
        sm: 'row',
      }}
      px={{
        xs: 2,
        sm: 0,
      }}
      className={classes.mainContainer}
    >
      <Box>
        <Box className={classes.title}>{title}</Box>
        <Box className={classes.desciprtionContainer}>
          <Box className={classes.singleDescription}>
            <img className={classes.icon} src={heart} />
            <div className={classes.description}>{descriptionOne}</div>
          </Box>
          <Box className={classes.singleDescription}>
            <img className={classes.icon} src={sitUp} />
            <div className={classes.description}>{descriptionTwo}</div>
          </Box>
          <Box className={classes.singleDescription}>
            <img className={classes.icon} src={stretching} />
            <div className={classes.description}>{descriptionThree}</div>
          </Box>
          <Box className={classes.singleDescription}>
            <img className={classes.icon} src={stretch} />
            <div className={classes.description}>{descriptionFour}</div>
          </Box>
          <Box className={classes.singleDescription}>
            <img className={classes.icon} src={hoolaHop} />
            <div className={classes.description}>{descriptionFive}</div>
          </Box>
          <Box className={classes.singleDescription}>
            <img className={classes.icon} src={running} />
            <div className={classes.description}>{descriptionSix}</div>
          </Box>
          <Box className={classes.singleDescription}>
            <img className={classes.icon} src={body} />
            <div className={classes.description}>{descriptionSeven}</div>
          </Box>
          {isButton == true ? (
          <Box className={classes.buttonContainer}>
            {/* <a href='https://app.movementvault.com/auth/signup'> */}
              <Button
                variant='contained'
                color='primary'
                className={classes.button}
                onClick={onClick}

              >
                <Typography className={classes.buttonText} variant='body1'>
                {/* TRY FOR FREE */}
                {buttonText}
                </Typography>
              </Button>
            {/* </a> */}
          </Box>
        ) : null}
        </Box>
        
      </Box>
      {/* <Box
        display='flex'
        alignItems='center'
        // width={{
        //   xs: '100%',
        //   sm: '50%',
        // }}
        className={classes.imageContainer}
      >
        <div
          style={{backgroundImage : img}}
          className={classes.image}
        />
      </Box> */}
      {/* <Box
        display='flex'
        alignItems='center'
        // width={{
        //   xs: '100%',
        //   sm: '50%',
        // }}
        className={classes.imageContainer}
      >
        <div
          style={{backgroundImage : image}}
          className={classes.imageTwo}
        />
        
      </Box> */}
      {img ? <Box
        display='flex'
        alignItems='center'
        // width={{
        //   xs: '100%',
        //   sm: '50%',
        // }}
        className={classes.imageContainer}
      >
        <div
          style={{ backgroundImage: `url(${img})` }}
          className={classes.image}
        />
      </Box> : <Box
        display='flex'
        alignItems='center'
        // width={{
        //   xs: '100%',
        //   sm: '50%',
        // }}
        className={classes.imageContainer}
      >
        <div
        
          style={{backgroundImage: `url(${image})`}}
          className={classes.imageTwo}
        />
        
      </Box> }
    </Box>
  );
};

export default ProgramsWillHelpYou;
