import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingTop: 79,
    paddingBottom: 79,
    // paddingRight: 79,
    // paddingLeft: 152,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: "90px",
    [theme.breakpoints.down('xs')]: {
      gap: "0px",
      paddingTop: 40,
      paddingBottom: 20,
      paddingRight: 20,
      paddingLeft: 20,
      flexDirection: 'column-reverse',
    },
  },
  title: {
    fontSize: 38,
    fontWeight: 900,
    lineHeight: '52px',
    letterSpacing: 0.56,
    width: '473px',
    color: theme.palette.hunterGreen.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: 24,
      width: 362,
      textAlign: 'center',
      lineHeight: '38px',
    },
  },
  dividerText: {
    fontSize: 18,
    fontWeight: 900,
    lineHeight: 1.56,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  text: {
    fontSize: 22,
    lineHeight: 1.56,
    // color: theme.palette.slateGrey.main,
    color: theme.palette.hunterGreen.main,
    paddingTop: '29px',
    maxWidth: 473,
    fontWeight: 600,
    // paddingBottom: 30,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      maxWidth: 'none',
      fontSize: 16,
      width: 362,
      lineHeight: '28px',
    },
  },

  divider: {
    width: '100%',
    'border-bottom': `1px solid ${theme.palette.primary.main}`,
  },
  image: {
    width: '540px',
    height: '768px',
    backgroundRepeat: 'no-repeat',
    // objectFit : "fit",
    backgroundSize: 'contain',
    [theme.breakpoints.down('xs')]: {
      width: 300,
      height: 410,
      marginTop: 60,
    },
  },
  button: {
    height: 64,
    width: 351,
    borderRadius: 2,
    [theme.breakpoints.down('xs')]: {
      width: 240,
    },
  },
  buttonText: {
    fontWeight: 'bold',
    fontSize: 18,
    color: theme.palette.hunterGreen.main,
    lineHeight: 3.56,
    letterSpacing: 1.38,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      lineHeight: 1.56,
    },
  },
   imgur: {
   
    [theme.breakpoints.down('xs')]: {
    display : "flex",
    justifyContent : "center"
    },
  },
}));

const MobilityScore = ({
  title,
  descriptionOne,
  descriptionTwo,
  descriptionThree,
  img,
  buttonText,
  onClick
}) => {
  const classes = useStyles();

  return (
    <Box
      display='flex'
      // flexDirection={{
      //   xs: 'column',
      //   sm: 'row',
      // }}
      // px={{
      //   xs: 2,
      //   sm: 0,
      // }}
      className={classes.mainContainer}
    >
      <Box
        display='flex'
        alignItems='center'
        // width={{
        //   xs: '100%',
        //   sm: '50%',
        // }}
        className={classes.imgur}
      >
        <div style={{  backgroundImage: `url(${img})`  }} className={classes.image} />
      </Box>
      <Box>
        <Typography className={classes.title} variant='h4'>
          {title}
        </Typography>
        <Typography className={classes.text} variant='body1'>
          {descriptionOne}
        </Typography>
        <Typography className={classes.text} variant='body1'>
          {descriptionTwo}
        </Typography>
        <Typography className={classes.text} variant='body1'>
          {descriptionThree}
        </Typography>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '54px',
          }}
        >
          {/* <a href='https://app.movementvault.com/auth/signup'> */}
            <Button
              variant='contained'
              color='secondary'
              className={classes.button}
              onClick={onClick}

            >
              <Typography className={classes.buttonText} variant='body1'>
                {/* START YOUR ASSESSMENT */}
                {buttonText}
              </Typography>
            </Button>
          {/* </a> */}
        </Box>
      </Box>
    </Box>
  );
};

export default MobilityScore;
