import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Typography } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: 'linear-gradient(90deg, rgba(40, 170, 226, 1) 0%, rgba(35, 108, 206, 1) 100%)',
      display:"flex",
      flexDirection : "column",
      justifyContent : "center",
      alignItems : "center",
      textAlign : "center",
      paddingTop : "61px",
      paddingBottom: "81px",
    [theme.breakpoints.down('xs')]: {
    },
  },
  title:{
    fontSize : 38,
    fontWeight : 900,
    color :"#FFFFFF",
    [theme.breakpoints.down('xs')]: {
      fontSize: 24,
    },
  },
  description:{
    fontSize : 22,
    fontWeight : 400,
    color :"#FFFFFF",
    paddingTop : 46,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  button: {
    height: 64,
    width: 240,
    borderRadius: 2,
  },
  buttonText: {
    fontWeight: 'bold',
    fontSize: 18,
    color: theme.palette.hunterGreen.main,
    lineHeight: 3.56,
    letterSpacing: 1.38,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },

 
}));

const ReadyToUnlock = ({
  titleOne,
  titleTwo,
  description
}) => {
  const classes = useStyles();

  return (
   <div className={classes.mainContainer} >
    <div className={classes.title}>
        {titleOne}
    </div>
    <div className={classes.title}>
        {titleTwo}
    </div>
    <div className={classes.description}>
        {description}
    </div>
    <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '54px',
          }}
        >
          <a href='https://app.movementvault.com/auth/signup'>
            <Button
              variant='contained'
              color='secondary'
              className={classes.button}
            >
              <Typography className={classes.buttonText} variant='body1'>
                START FOR FREE
              </Typography>
            </Button>
          </a>
        </Box>
   </div>
  );
};

export default ReadyToUnlock;
