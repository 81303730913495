import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/styles';

import { Box, Typography, Grid, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/styles';
import axios from './client';
import Navbar from './Navbar';

import Footer from './Footer';
import { PageSeoTags } from './SeoTags';
import cx from 'classnames';
import vector from '../../../assets/images/vector.png';
import provenResults from '../../../assets/images/Gradient-Blue-Block-For-Website-1.png';
import star from '../../../assets/images/star.png';
import customersBackground from '../../../assets/images/Gradient-Blue-Block-For-Website-2.png';
import frontSplits from '../../../assets/images/Front-Splits-Before-and-After-Edited-Square-Optimized-1.png';
import male60 from '../../../assets/images/Male-60-Toe-Touch-4-week-Before-and-After-Square-Optimized-1.png';
import louisBalleta from '../../../assets/images/Louis-Balleta-40-Squat-8-weeks-Before-and-After-Square-Optimized-1.png';
import arrow from '../../../assets/images/arrow.png';
import { push as pushTo } from 'connected-react-router';
import { Creators } from 'modules/dashboard/payment/reducer';



const styles = (theme) => ({
  // header: {
  //   height: 320,
  //   backgroundImage: `url('../static/images/image2.jpg')`,
  //   backgroundSize: 'cover',
  //   [theme.breakpoints.down('xs')]: {
  //     backgroundPosition: 'center',
  //   },
  // },
  // plansContainer: {
  //   paddingLeft: '7vw',
  //   paddingRight: '7vw',
  //   // paddingTop: "30px",
  //   paddingBottom: '40px',
  //   [theme.breakpoints.down('xs')]: {
  //     // paddingTop: "24px",
  //     paddingBottom: '49px',
  //   },
  // },
  // title: {
  //   color: 'white',
  //   fontSize: 38,
  //   fontWeight: 900,
  //   letterSpacing: 0.65,
  //   lineHeight: 1.27,
  //   [theme.breakpoints.down('xs')]: {
  //     fontSize: 30,
  //   },
  // },
  // link: {
  //   color: 'white',
  //   fontSize: 16,
  //   fontWeight: 900,
  //   lineHeight: 1.5,
  // },
  // linkContainer: {
  //   cursor: 'pointer',
  //   width: 180,
  //   'border-bottom': `2px solid ${theme.palette.hunterGreen.light}`,
  // },
  // selectedLink: {
  //   'border-bottom': `2px solid ${theme.palette.secondary.main}`,
  // },
  // bulletPoint: {
  //   color: 'blue',
  //   fontSize: 30,
  // },
  // centerText: {
  //   fontSize: 16,
  //   lineHeight: 2,
  //   color: theme.palette.slateGrey.main,
  //   [theme.breakpoints.down('xs')]: {
  //     textAlign: 'center',
  //     lineHeight: 1.63,
  //   },
  // },
  // bulletText: {
  //   fontSize: 18,
  //   lineHeight: 1.56,
  //   color: theme.palette.hunterGreen.main,
  // },
  // mainTitle: {
  //   fontSize: 38,
  //   fontWeight: 'bold',
  //   lineHeight: '1.05',
  //   letterSpacing: 0.56,
  //   color: theme.palette.hunterGreen.main,
  //   textAlign: 'center',
  // },
  // image: {
  //   maxWidth: '100%',
  //   height: 'auto',
  // },
  // imagesContainer: {
  //   paddingLeft: '3vw',
  //   paddingRight: '3vw',
  // },
  // imageContainer: {
  //   paddingRight: '3vw',
  //   paddingLeft: '3vw',
  //   paddingTop: '3vw',
  //   paddingBottom: '3vw',
  // },
  // parentPlan: {
  //   '&:not(:last-child)': {
  //     paddingRight: theme.spacing(2),
  //   },
  // },
  // planContainer: {
  //   width: '350px',
  //   paddingRight: theme.spacing(2),
  //   paddingLeft: theme.spacing(2),
  //   marginTop: -theme.spacing(7),
  //   [theme.breakpoints.down('xs')]: {
  //     width: '100%',
  //     marginTop: theme.spacing(0),
  //     paddingTop: theme.spacing(4),
  //     '&:first-child': {
  //       paddingTop: theme.spacing(8),
  //     },
  //   },
  // },
  // lastImage: {
  //   [theme.breakpoints.down('xs')]: {
  //     paddingBottom: theme.spacing(8),
  //   },
  // },

  // // new CSS

  // button: {
  //   background: '#28AAE2',
  //   color: '#FFFFFF',
  //   width: 320,
  //   height: 56,
  //   [theme.breakpoints.down('md')]: {
  //     width: 275,
  //   },
  // },
  // container: {
  //   width: '100%',
  // },
  // dialog: {
  //   maxWidth: 650,
  // },
  // dialogContainer: {
  //   [theme.breakpoints.down('md')]: {
  //     width: '100%',
  //     padding: '4rem 1rem 1rem',
  //   },
  // },
  // header: {
  //   // background: `url(${choosePlan})`,
  //   backgroundSize: 'cover',
  //   backgroundRepeat: 'no-repeat',
  //   height: 316,
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   width: '100%',
  //   [theme.breakpoints.down('md')]: {
  //     height: 429,
  //   },
  // },
  // headerImageContainer: {
  //   display: 'flex',
  //   paddingTop: 54,
  //   paddingLeft: 150,
  //   paddingRight: 150,
  //   maxWidth: '100%',
  //   [theme.breakpoints.down('md')]: {
  //     paddingTop: 22,
  //     paddingLeft: 10,
  //     paddingRight: 10,
  //   },
  // },
  // imageContainer: {
  //   position: 'absolute',
  //   backgroundImage: 'url("/logo-movementvault.svg")',
  //   cursor: 'pointer',
  //   width: 165,
  //   height: 20,
  // },
  // headerTitle: {
  //   color: 'white',
  //   textAlign: 'center',
  //   textTransform: 'uppercase',
  //   fontSize: 38,
  //   fontWeight: 900,
  //   letterSpacing: 0.5,
  //   [theme.breakpoints.down('md')]: {
  //     fontSize: 28,
  //     width: '100%',
  //     marginTop: -120,
  //   },
  // },
  // interval: {
  //   // padding: '0px 48px',
  //   color: '#020401',
  //   // fontSize: 16,
  //   // marginTop: 24,
  //   width: '100%',
  //   fontWeight: 600,
  //   textTransform: 'capitalize',
  //   display: 'flex',
  //   flexDirection: 'row',
  //   justifyContent: 'space-between',
  //   [theme.breakpoints.down('md')]: {
  //     // padding: '0px 24px',
  //     textAlign: 'center',
  //     width: '100%',
  //     fontSize: 12,
  //   },
  // },
  // memberShipPlanContainer: {
  //   display: 'flex',
  //   justifyContent: 'space-between',
  // },
  // // plan: {
  // //   width: "100%",
  // //   height: "88px",
  // // //   width: "414px",
  // // //   height: "88px",
  // //   // borderRadius: 6,
  // //   margin: '0px 12px',
  // //   boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25);",
  // // //   boxShadow: "0 8px 16px 2px rgba(15, 18, 26, 0.08)",
  // //   cursor: 'pointer',
  // //   // position: 'relative',
  // //   marginBottom: 24,
  // //   borderColor : "#000000",
  // //   borderRadius: "4px",
  // //   border : "solid",
  // //   display : "flex",
  // //   alignItems : "center",
  // //   [theme.breakpoints.down('md')]: {
  // //     height: 66,
  // //     width: '343px',

  // //   },
  // // },
  // unclickable: {
  //   cursor: 'default',
  // },
  // plans: {
  //   display: 'flex',
  //   flexWrap: 'wrap',
  //   width: '100%',
  //   justifyContent: 'center',
  //   flexDirection: 'column-reverse',
  // },
  // planHeader: {
  //   borderTopLeftRadius: 6,
  //   borderTopRightRadius: 6,
  //   height: 80,
  //   backgroundColor: theme.palette.primary.main,
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   color: 'white',
  //   fontWeight: 'bold',
  //   textTransform: 'uppercase',
  // },
  // text: {
  //   [theme.breakpoints.down('xs')]: {
  //     fontSize: 18,
  //     fontWeight: 'bold',
  //     lineHeight: 1.22,
  //   },
  // },
  // planSelected: {
  //   backgroundColor: theme.palette.secondary.main,
  //     color: theme.palette.primary.dark,
  //   borderColor: '#28AAE2',
  //   borderWidth: '4px !important',
  //   //   borderStyle : "solid"
  // },
  // currentPlan: {
  //   backgroundColor: theme.palette.secondary.gray,
  // },
  // planContent: {
  //   height: 180,
  //   padding: '0px 48px',
  //   flexDirection: 'column',
  //   display: 'flex',
  //   textAlign: 'left',
  //   justifyContent: 'center',
  //   [theme.breakpoints.down('md')]: {
  //     padding: '0px 24px',
  //     width: '100%',
  //     textAlign: 'center',
  //   },
  // },
  // tab: {
  //   color: 'white',
  //   fontSize: 16,
  //   borderBottom: '2px solid ${theme.palette.primary.gray}',
  //   [theme.breakpoints.down('xs')]: {
  //     width: '50%',
  //   },
  // },
  // tabSelected: {
  //   color: 'white',
  //   fontSize: 16,
  //   fontWeight: 900,
  // },
  // tabsContainer: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   marginTop: -120,
  //   [theme.breakpoints.down('xs')]: {
  //     marginTop: -240,
  //   },
  // },
  // tabsHeader: {
  //   [theme.breakpoints.down('xs')]: {
  //     width: '100%',
  //     padding: '0 15px',
  //   },
  // },
  // tabsContent: {
  //   marginTop: 32,
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'center',
  //   [theme.breakpoints.down('xs')]: {
  //     marginTop: 256,
  //   },
  // },
  // titleL: {
  //   color: theme.palette.primary.main,
  //   fontSize: 30,
  //   lineHeight: 2,
  //   fontWeight: 'bold',
  //   [theme.breakpoints.down('md')]: {
  //     fontSize: 30,
  //     lineHeight: 1.2,
  //   },
  // },

  // choosePlanMainContainer: {
  //   width: '100%',
  //   height: '100%',
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   flexDirection: 'column',
  //   paddingTop: '100px',
  //   [theme.breakpoints.down('md')]: {
  //     paddingTop: 79,
  //   },
  // },
  // boldText: {
  //   fontWeight: 900,
  // },
  // headerText: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   flexDirection: 'column',
  //   fontSize: '38px',
  //   fontWeight: 500,
  //   lineHeight: '52px',
  //   [theme.breakpoints.down('xs')]: {
  //     fontSize: '18px',
  //     lineHeight: '22px',
  //   },
  // },
  // cancelAnyTimeText: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   flexDirection: 'column',
  //   fontSize: '38px',
  //   fontWeight: 500,
  //   lineHeight: '52px',
  //   marginTop: '31px',
  //   [theme.breakpoints.down('xs')]: {
  //     fontSize: '18px',
  //     lineHeight: '22px',
  //     marginTop: '13px',
  //   },
  // },
  // memberShipContainer: {
  //   display: 'flex',
  //   justifyContent: 'center',
  //   flexDirection: 'column',
  //   marginTop: 48,
  //   [theme.breakpoints.down('xs')]: {
  //     marginTop: 43,
  //     alignItems: 'unset',
  //     width: 343,
  //   },
  // },
  // memberShipMainText: {
  //   fontSize: '26px',
  //   fontWeight: 500,
  //   [theme.breakpoints.down('xs')]: {
  //     fontSize: '16px',
  //     lineHeight: '22px',
  //   },
  // },
  // memberShipLine: {
  //   width: '414px',
  //   color: '#000000',
  //   border: '1px solid #000000',
  //   marginTop: 11,
  //   [theme.breakpoints.down('xs')]: {
  //     width: '259px',
  //     marginTop: 4,
  //   },
  // },
  // memberShipInfoListConatiner: {
  //   display: 'flex',
  //   justifyContent: 'flex-start',
  //   flexDirection: 'column',
  //   marginTop: '33px',
  //   width: '100%',
  // },
  // memberShipInfoListText: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   marginTop: '12px',
  // },
  // memberShipInfoListTextEachText: {
  //   marginLeft: '10px',
  //   fontWeight: 500,
  //   color: '#474D45',
  //   fontSize: '18px',
  //   [theme.breakpoints.down('xs')]: {
  //     fontSize: '14px',
  //     lineHeight: '22px',
  //   },
  // },
  // vectorImgStyles: {
  //   width: '24px',
  //   height: '24px',
  // },
  // NewPlanContainer: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   width: '100%',
  //   padding: '15px',
  //   borderColor: '#000000',
  //   borderRadius: '4px',
  //   border: 'solid',
  // },
  // provenResults: {
  //   background: `url(${provenResults})`,
  //   backgroundSize: 'cover',
  //   backgroundRepeat: 'no-repeat',
  //   // height: 457,
  //   display: 'flex',
  //   alignItems: 'center',
  //   // justifyContent: 'space-around',
  //   padding: '76px 0px 53px 0px',
  //   width: '100%',
  //   flexDirection: 'column',
  //   color: '#FFFFFF',
  //   [theme.breakpoints.down('xs')]: {
  //     height: '228px',
  //     padding: '27px 0px 27px 0px',
  //   },
  // },
  // provenResultsContainer: {
  //   width: '662px',
  // },
  // provenResultsText: {
  //   fontSize: 38,
  //   fontWeight: 600,
  //   [theme.breakpoints.down('xs')]: {
  //     fontSize: '18px',
  //   },
  // },
  // provenResultsMainText: {
  //   fontSize: 38,
  //   fontWeight: 600,
  //   [theme.breakpoints.down('xs')]: {
  //     fontSize: '18px',
  //   },
  // },
  // provenResultsTextBased: {
  //   fontSize: 32,
  //   // fontWeight: 600,
  //   paddingTop: '35px',
  //   [theme.breakpoints.down('xs')]: {
  //     fontSize: '16px',
  //     paddingTop: '20px',
  //   },
  // },
  // movmentVaultUsersText: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   flexDirection: 'column',
  //   lineHeight: '52px',
  //   paddingTop: '20px',
  //   width: '662px',
  //   [theme.breakpoints.down('xs')]: {
  //     fontSize: '18px',
  //     lineHeight: '30px',
  //     width: '100%',
  //   },
  // },
  // strechBetterContainer: {
  //   backgroundColor: 'white',
  //   backgroundSize: 'cover',
  //   backgroundRepeat: 'no-repeat',
  //   height: '100%',
  //   display: 'flex',
  //   alignItems: 'center',
  //   width: '100%',
  //   flexDirection: 'column',
  //   color: '#FFFFFF',
  //   padding: '0px 0px 58px 0px',
  //   [theme.breakpoints.down('xs')]: {
  //     padding: '0px 0px 27px 0px',
  //   },
  // },
  // pic1: {
  //   width: '411px',
  //   // marginLeft: '55px',
  //   paddingLeft: '30px',
  //   marginTop: '51px',
  //   [theme.breakpoints.down('xs')]: {
  //     width: '275px',
  //     height: '275px',
  //     marginLeft: '0px',
  //     marginTop: '27px',
  //     paddingLeft: '0px',
  //   },
  // },
  // pic2: {
  //   width: '381px',
  //   marginTop: '51px',
  //   // padding : "30px",
  //   [theme.breakpoints.down('xs')]: {
  //     width: '275px',
  //     height: '275px',
  //     marginTop: '27px',
  //   },
  // },
  // pic3: {
  //   width: '411px',
  //   // marginLeft: '7px',
  //   paddingLeft: '30px',
  //   marginTop: '51px',
  //   [theme.breakpoints.down('xs')]: {
  //     width: '275px',
  //     height: '275px',
  //     marginTop: '27px',
  //     paddingLeft: '0px',
  //   },
  // },
  // stretchBetter: {
  //   fontWeight: 700,
  //   fontSize: '38px',
  //   marginTop: '68px',
  //   color: '#020401',
  //   [theme.breakpoints.down('xs')]: {
  //     fontSize: '18px',
  //     width: '252px',
  //     marginTop: '15px',
  //   },
  // },
  // stretchBetterParagraph: {
  //   marginTop: '15px',
  //   fontWeight: 500,
  //   fontSize: '38px',
  //   marginTop: '15px',
  //   color: '#020401',
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   flexDirection: 'column',
  //   lineHeight: '52px',
  //   [theme.breakpoints.down('xs')]: {
  //     fontSize: '18px',
  //     lineHeight: '30px',
  //     width: '310px',
  //     marginTop: '30px',
  //   },
  // },
  // customersRatingMainContainer: {
  //   background: `url(${customersBackground})`,
  //   backgroundSize: 'cover',
  //   backgroundRepeat: 'no-repeat',
  //   height: 426,
  //   display: 'flex',
  //   alignItems: 'center',
  //   // justifyContent: 'space-around',
  //   padding: '64px 0px 65px 0px',
  //   width: '100%',
  //   flexDirection: 'row',
  //   color: '#FFFFFF',
  //   justifyContent: 'center',
  //   [theme.breakpoints.down('xs')]: {
  //     flexDirection: 'column',
  //     height: '100%',
  //     padding: '42px 27px 42px 43px',
  //   },
  // },
  // customersSingleReviewContainer: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   flexDirection: 'column',
  //   paddingLeft: '132px',
  //   marginTop : "-35px",
  //   [theme.breakpoints.down('xs')]: {
  //     marginTop: '32px',
  //     paddingLeft: '0px',
  //   },
  // },
  // customersSingleReviewContainer2: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   flexDirection: 'column',
  //   paddingLeft : "215px",
  //   [theme.breakpoints.down('xs')]: {
  //     marginTop: '32px',
  //   },
  // },
  // usersReviewDescirption: {
  //   marginTop: '25px',
  //   fontWeight: 600,
  //   fontSize: '26px',
  //   lineHeight: '40px',
  //   [theme.breakpoints.down('xs')]: {
  //     fontSize: '18px',
  //     lineHeight: '32px',
  //     marginTop: '15px',
  //   },
  // },
  // customer: {
  //   fontWeight: 500,
  //   fontSize: '26px',
  //   marginTop: '15px',
  //   [theme.breakpoints.down('xs')]: {
  //     fontSize: '18px',
  //   },
  // },
  // customerStars: {
  //   // background:  `url('/assets/img/star.png')`,
  //   backgroundImage: `url(${star})`,
  //   // backgroundSize: 'cover',
  //   backgroundSize: 'cover',
  //   width: '150px',
  // },
  // helpContainer: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   flexDirection: 'column',
  //   paddingTop: '75px',
  //   paddingBottom: '118px',
  //   [theme.breakpoints.down('xs')]: {
  //     paddingTop: '39px',
  //   },
  // },
  // helpContainerText: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   fontWeight: 500,
  //   fontSize: '38px',
  //   lineHeight: '52px',
  //   flexDirection: 'column',
  //   [theme.breakpoints.down('xs')]: {
  //     lineHeight: '30px',
  //     fontSize: '18px',
  //   },
  // },
  // customersSelectPlanContainer: {
  //   marginTop: '40px',
  // },
  // helpTextMobile: {
  //   display: 'none',
  //   [theme.breakpoints.down('xs')]: {
  //     width: '297px',
  //     display: 'flex',
  //     justifyContent: 'center',
  //     alignItems: 'center',
  //     flexDirection: 'column',
  //     width: '100%',
  //   },
  // },

  // helpTextDesktop: {
  //   [theme.breakpoints.down('xs')]: {
  //     display: 'none',
  //   },
  // },

  // flexMobile: {
  //   display: 'none',
  //   [theme.breakpoints.down('xs')]: {
  //     width: '297px',
  //     display: 'flex',
  //     justifyContent: 'center',
  //     alignItems: 'center',
  //     flexDirection: 'column',
  //     width: '100%',
  //     marginTop: '12px',
  //   },
  // },

  // flexDesktop: {
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   flexDirection: 'column',
  //   [theme.breakpoints.down('xs')]: {
  //     display: 'none',
  //   },
  // },
  // physicalTherapyDesktop: {
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   flexDirection: 'column',
  //   [theme.breakpoints.down('xs')]: {
  //     display: 'none',
  //   },
  // },
  // physicalTherapyMobile: {
  //   display: 'none',
  //   [theme.breakpoints.down('xs')]: {
  //     display: 'flex',
  //     justifyContent: 'center',
  //     alignItems: 'center',
  //     flexDirection: 'column',
  //   },
  // },
  // transformDesktop: {
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   flexDirection: 'column',
  //   [theme.breakpoints.down('xs')]: {
  //     display: 'none',
  //   },
  // },
  // transformMobile: {
  //   display: 'none',
  //   [theme.breakpoints.down('xs')]: {
  //     display: 'flex',
  //     justifyContent: 'center',
  //     alignItems: 'center',
  //     flexDirection: 'column',
  //   },
  // },
  // promoContainer: {
  //   padding: '5px',
  //   maxWidth: '100%',
  //   maxHeight: '100%',
  //   // width: "150px",
  //   // height: "30px",
  //   backgroundColor: '#28AAE2',
  //   color: '#FFFFFF',
  //   position: 'absolute',
  //   marginLeft: '200px',
  //   marginTop: '-19px',
  //   border: '3px solid #28AAE2',
  //   borderRadius: '4px',
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   [theme.breakpoints.down('xs')]: {
  //     fontSize: '12px',
  //     marginLeft: '220px',
  //   },
  // },
  // plan: {
  //   // maxWidth: "100%",
  //   height: '80px',
  //   width: '414px',
  //   padding: '15px 12px 15px 21px',
  //   // padding : "12px 10px 12px 18px",
  //   //   height: "88px",
  //   // borderRadius: 6,
  //   // margin: '0px 12px',
  //   boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25);',
  //   //   boxShadow: "0 8px 16px 2px rgba(15, 18, 26, 0.08)",
  //   cursor: 'pointer',
  //   // position: 'relative',
  //   marginTop: 24,
  //   // borderColor : "#000000",
  //   borderRadius: '4px',
  //   borderStyle: 'solid',
  //   display: 'flex',
  //   alignItems: 'center',
  //   borderWidth: '1px',
  //   [theme.breakpoints.down('md')]: {
  //     height: 66,
  //     width: '343px',
  //     padding: '12px 10px 12px 18px',
  //     height: '66px',
  //   },
  // },
  // planWithoutPromo: {},
  // pricing: {
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   textTransform: 'lowercase',
  //   // paddingLeft : "54px",
  //   fontSize: '16px',
  //   [theme.breakpoints.down('xs')]: {
  //     fontSize: '14px',
  //     paddingLeft: '0px',
  //   },
  // },
  // monthGap: {
  //   [theme.breakpoints.down('md')]: {
  //     display: 'flex',
  //     justifyContent: 'center',
  //     // alignItems : "center",
  //     flexDirection: 'column',
  //   },
  // },
  // tst: {
  //   padding: '5px',
  //   // maxWidth: "100%",
  //   // maxHeight: "100%",
  //   // width: "100%",
  //   // height: "100%",
  //   backgroundColor: '#28AAE2',
  //   color: '#FFFFFF',
  //   // position: 'absolute',
  //   // marginLeft : "200px",
  //   position: 'absolute',
  //   marginTop: '-97px',
  //   border: '3px solid #28AAE2',
  //   borderRadius: '4px',
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   fontSize: '14px',
  //   fontWeight: 700,
  //   [theme.breakpoints.down('xs')]: {
  //     fontSize: '12px',
  //     marginTop: '-82px',
  //     // marginLeft : "220px",
  //   },
  // },
  // currentPlanContainer: {
  //   padding: '5px',
  //   // maxWidth: "100%",
  //   // maxHeight: "100%",
  //   // width: "100%",
  //   // height: "100%",
  //   backgroundColor: 'gray',
  //   color: '#FFFFFF',
  //   // position: 'absolute',
  //   // marginLeft : "200px",
  //   position: 'absolute',
  //   marginTop: '-97px',
  //   border: '3px solid gray',
  //   borderRadius: '4px',
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   fontSize: '14px',
  //   fontWeight: 700,
  //   [theme.breakpoints.down('xs')]: {
  //     fontSize: '12px',
  //     marginTop: '-82px',
  //     // marginLeft : "220px",
  //   },
  // },
  // tst2: {
  //   display: 'flex',
  //   justifyContent: 'flex-end',
  //   paddingRight: '25px',
  // },
  // yearContainer: {
  //   display: 'flex',
  //   flexDirection: 'column',
  // },
  // saleLine: {
  //   color: "#000000",
  //   width: "66px",
  //   border: "1px solid #000000",
  //   position : "absolute",
  //   marginTop : "-8px",
  //   borderWidth : "0.3px",
  //   [theme.breakpoints.down('xs')]: {
  //       marginTop : "-7px",
  //       width: "44px",
  //       // marginLeft : "220px",
  //   },
  //   [theme.breakpoints.down('md')]: {
  //       width : "55px",
  //       marginTop : "-7px",

  //     },
  //   textDecoration: 'line-through',
  // },
  // arrow: {
  //   paddingLeft: '17px',
  // },
  // planMainContainer: {
  //   display: 'flex',
  //   flexDirection: 'column-reverse',
  // },
  // planTrialDuration: {
  //   fontSize: '18px',
  //   [theme.breakpoints.down('xs')]: {
  //     fontSize: '16px',
  //   },
  // },
  // prices: {
  //   fontSize: '16px',
  //   display: 'flex',
  //   [theme.breakpoints.down('xs')]: {
  //     fontSize: '14px',
  //   },
  // },
  // monthPrices: {
  //   fontSize: '16px',
  //   [theme.breakpoints.down('xs')]: {
  //     fontSize: '14px',
  //   },
  // },
  // strentchingPicturesContainer: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   flexWrap: 'wrap',
  //   padding: '0px 89px 0px 89px',
  //   [theme.breakpoints.down('xs')]: {
  //     padding: '0px',
  //   },
  // },
  // signOutContianer: {
  //   display: 'flex',
  //   justifyContent: 'flex-end',
  //   width: '100%',
  // },
  // signOutButton: {
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   width: '100%',
  //   backgroundColor: 'white',
  //   borderStyle: 'solid',
  //   borderWidth: '2px',
  //   borderColor: '#28AAE2',
  //   width: '100px',
  //   height: '20px',
  //   borderRadius: '30px',
  //   color: '#28AAE2',
  //   fontSize: 12,
  //   cursor: 'pointer',
  //   '&:hover': {
  //     backgroundColor: '#d3d3d3',
  //     color: 'white',
  //   },
  // },
  // starsContainer: {
  //   display: 'flex',
  //   flexDirection: 'row',
  // },


  header: {
    height: 320,
    backgroundImage: `url('../static/images/image2.jpg')`,
    backgroundSize: 'cover',
    [theme.breakpoints.down('xs')]: {
      backgroundPosition: 'center',
    },
  },
  plansContainer: {
    paddingLeft: '7vw',
    paddingRight: '7vw',
    // paddingTop: "30px",
    paddingBottom: '40px',
    [theme.breakpoints.down('xs')]: {
      // paddingTop: "24px",
      paddingBottom: '49px',
    },
  },
  title: {
    color: 'white',
    fontSize: 38,
    fontWeight: 900,
    letterSpacing: 0.65,
    lineHeight: 1.27,
    [theme.breakpoints.down('xs')]: {
      fontSize: 30,
    },
  },
  link: {
    color: 'white',
    fontSize: 16,
    fontWeight: 900,
    lineHeight: 1.5,
  },
  linkContainer: {
    cursor: 'pointer',
    width: 180,
    'border-bottom': `2px solid ${theme.palette.hunterGreen.light}`,
  },
  selectedLink: {
    'border-bottom': `2px solid ${theme.palette.secondary.main}`,
  },
  bulletPoint: {
    color: 'blue',
    fontSize: 30,
  },
  centerText: {
    fontSize: 16,
    lineHeight: 2,
    color: theme.palette.slateGrey.main,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      lineHeight: 1.63,
    },
  },
  bulletText: {
    fontSize: 18,
    lineHeight: 1.56,
    color: theme.palette.hunterGreen.main,
  },
  mainTitle: {
    fontSize: 38,
    fontWeight: 'bold',
    lineHeight: '1.05',
    letterSpacing: 0.56,
    color: theme.palette.hunterGreen.main,
    textAlign: 'center',
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
  },
  imagesContainer: {
    paddingLeft: '3vw',
    paddingRight: '3vw',
  },
  imageContainer: {
    paddingRight: '3vw',
    paddingLeft: '3vw',
    paddingTop: '3vw',
    paddingBottom: '3vw',
  },
  parentPlan: {
    '&:not(:last-child)': {
      paddingRight: theme.spacing(2),
    },
  },
  planContainer: {
    width: '350px',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    marginTop: -theme.spacing(7),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(0),
      paddingTop: theme.spacing(4),
      '&:first-child': {
        paddingTop: theme.spacing(8),
      },
    },
  },
  lastImage: {
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(8),
    },
  },

  // new CSS

  button: {
    background: '#28AAE2',
    color: '#FFFFFF',
    width: 320,
    height: 56,
    [theme.breakpoints.down('md')]: {
      width: 275,
    },
  },
  container: {
    width: '100%',
  },
  dialog: {
    maxWidth: 650,
  },
  dialogContainer: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '4rem 1rem 1rem',
    },
  },
  header: {
    // background: `url(${choosePlan})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: 316,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      height: 429,
    },
  },
  headerImageContainer: {
    display: 'flex',
    paddingTop: 54,
    paddingLeft: 150,
    paddingRight: 150,
    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
      paddingTop: 22,
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  imageContainer: {
    position: 'absolute',
    backgroundImage: 'url("/logo-movementvault.svg")',
    cursor: 'pointer',
    width: 165,
    height: 20,
  },
  headerTitle: {
    color: 'white',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 38,
    fontWeight: 900,
    letterSpacing: 0.5,
    [theme.breakpoints.down('md')]: {
      fontSize: 28,
      width: '100%',
      marginTop: -120,
    },
  },
  interval: {
    // padding: '0px 48px',
    color: '#020401',
    // fontSize: 16,
    // marginTop: 24,
    width: '100%',
    fontWeight: 600,
    textTransform: 'capitalize',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      // padding: '0px 24px',
      textAlign: 'center',
      width: '100%',
      fontSize: 12,
    },
  },
  memberShipPlanContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  // plan: {
  //   width: "100%",
  //   height: "88px",
  // //   width: "414px",
  // //   height: "88px",
  //   // borderRadius: 6,
  //   margin: '0px 12px',
  //   boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25);",
  // //   boxShadow: "0 8px 16px 2px rgba(15, 18, 26, 0.08)",
  //   cursor: 'pointer',
  //   // position: 'relative',
  //   marginBottom: 24,
  //   borderColor : "#000000",
  //   borderRadius: "4px",
  //   border : "solid",
  //   display : "flex",
  //   alignItems : "center",
  //   [theme.breakpoints.down('md')]: {
  //     height: 66,
  //     width: '343px',

  //   },
  // },
  unclickable: {
    cursor: 'default',
  },
  plans: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column-reverse',
  },
  planHeader: {
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    height: 80,
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  text: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
      fontWeight: 'bold',
      lineHeight: 1.22,
    },
  },
  planSelected: {
    // backgroundColor: theme.palette.secondary.main,
    //   color: theme.palette.primary.dark,
    borderColor: '#28AAE2',
    borderWidth: '4px !important',
    //   borderStyle : "solid"
  },
  currentPlan: {
    backgroundColor: theme.palette.secondary.gray,
  },
  planContent: {
    height: 180,
    padding: '0px 48px',
    flexDirection: 'column',
    display: 'flex',
    textAlign: 'left',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '0px 24px',
      width: '100%',
      textAlign: 'center',
    },
  },
  tab: {
    color: 'white',
    fontSize: 16,
    borderBottom: '2px solid ${theme.palette.primary.gray}',
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    },
  },
  tabSelected: {
    color: 'white',
    fontSize: 16,
    fontWeight: 900,
  },
  tabsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: -120,
    [theme.breakpoints.down('xs')]: {
      marginTop: -240,
    },
  },
  tabsHeader: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: '0 15px',
    },
  },
  tabsContent: {
    marginTop: 32,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: 256,
    },
  },
  titleL: {
    color: theme.palette.primary.main,
    fontSize: 30,
    lineHeight: 2,
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: 30,
      lineHeight: 1.2,
    },
  },

  choosePlanMainContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: '100px',
    [theme.breakpoints.down('md')]: {
      paddingTop: 79,
    },
  },
  boldText: {
    fontWeight: 900,
  },
  headerText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: '38px',
    fontWeight: 500,
    lineHeight: '52px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
      lineHeight: '22px',
    },
  },
  cancelAnyTimeText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: '38px',
    fontWeight: 500,
    lineHeight: '52px',
    marginTop: '31px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
      lineHeight: '22px',
      marginTop: '13px',
    },
  },
  memberShipContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 48,
    [theme.breakpoints.down('xs')]: {
      marginTop: 43,
      alignItems: 'unset',
      width: 343,
    },
  },
  memberShipMainText: {
    fontSize: '26px',
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      lineHeight: '22px',
    },
  },
  memberShipLine: {
    width: '414px',
    color: '#000000',
    border: '1px solid #000000',
    marginTop: 11,
    [theme.breakpoints.down('xs')]: {
      width: '259px',
      marginTop: 4,
    },
  },
  memberShipInfoListConatiner: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginTop: '33px',
    width: '100%',
  },
  memberShipInfoListText: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '12px',
  },
  memberShipInfoListTextEachText: {
    marginLeft: '10px',
    fontWeight: 500,
    color: '#474D45',
    fontSize: '18px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
      lineHeight: '22px',
    },
  },
  vectorImgStyles: {
    width: '24px',
    height: '24px',
  },
  NewPlanContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '15px',
    borderColor: '#000000',
    borderRadius: '4px',
    border: 'solid',
  },
  provenResults: {
    background: `url(${provenResults})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    // height: 457,
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-around',
    padding: '76px 0px 53px 0px',
    width: '100%',
    flexDirection: 'column',
    color: '#FFFFFF',
    [theme.breakpoints.down('xs')]: {
      height: '228px',
      padding: '27px 0px 27px 0px',
    },
  },
  provenResultsContainer: {
    width: '662px',
  },
  provenResultsText: {
    fontSize: 38,
    // fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  provenResultsMainText: {
    fontSize: 38,
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  provenResultsTextBased: {
    fontSize: 32,
    // fontWeight: 600,
    paddingTop: '35px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      paddingTop: '20px',
    },
  },
  movmentVaultUsersText: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    lineHeight: '52px',
    paddingTop: '20px',
    width: '662px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
      lineHeight: '30px',
      width: '100%',
    },
  },
  strechBetterContainer: {
    backgroundColor: 'white',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'column',
    color: '#FFFFFF',
    padding: '0px 0px 58px 0px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 0px 27px 0px',
    },
  },
  pic1: {
    width: '411px',
    // marginLeft: '55px',
    paddingLeft: '30px',
    marginTop: '51px',
    [theme.breakpoints.down('xs')]: {
      width: '275px',
      height: '275px',
      marginLeft: '0px',
      marginTop: '27px',
      paddingLeft: '0px',
    },
  },
  pic2: {
    width: '381px',
    marginTop: '51px',
    // padding : "30px",
    [theme.breakpoints.down('xs')]: {
      width: '275px',
      height: '275px',
      marginTop: '27px',
    },
  },
  pic3: {
    width: '411px',
    // marginLeft: '7px',
    paddingLeft: '30px',
    marginTop: '51px',
    [theme.breakpoints.down('xs')]: {
      width: '275px',
      height: '275px',
      marginTop: '27px',
      paddingLeft: '0px',
    },
  },
  stretchBetter: {
    fontWeight: 700,
    fontSize: '38px',
    marginTop: '68px',
    color: '#020401',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
      width: '252px',
      marginTop: '15px',
    },
  },
  stretchBetterParagraph: {
    marginTop: '15px',
    fontWeight: 500,
    fontSize: '38px',
    marginTop: '15px',
    color: '#020401',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    lineHeight: '52px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
      lineHeight: '30px',
      width: '310px',
      marginTop: '30px',
    },
  },
  customersRatingMainContainer: {
    background: `url(${customersBackground})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: 426,
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-around',
    padding: '64px 0px 65px 0px',
    width: '100%',
    flexDirection: 'row',
    color: '#FFFFFF',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      height: '100%',
      padding: '42px 27px 42px 43px',
    },
  },
  customersSingleReviewContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: '132px',
    // marginTop : "-35px",
    [theme.breakpoints.down('xs')]: {
      marginTop: '32px',
      paddingLeft: '0px',
    },
  },
  customersSingleReviewContainer2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    // paddingLeft : "215px",
    [theme.breakpoints.down('xs')]: {
      marginTop: '32px',
    },
  },
  usersReviewDescirption: {
    marginTop: '25px',
    fontWeight: 600,
    fontSize: '26px',
    lineHeight: '40px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
      lineHeight: '32px',
      marginTop: '15px',
    },
  },
  customer: {
    fontWeight: 500,
    fontSize: '26px',
    marginTop: '15px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  customerStars: {
    // background:  `url('/assets/img/star.png')`,
    backgroundImage: `url(${star})`,
    // backgroundSize: 'cover',
    backgroundSize: 'cover',
    width: '150px',
  },
  helpContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: '75px',
    // paddingBottom: '118px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '39px',
    },
  },
  helpContainerText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    fontSize: '38px',
    lineHeight: '52px',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      lineHeight: '30px',
      fontSize: '18px',
    },
  },
  customersSelectPlanContainer: {
    marginTop: '40px',
  },
  helpTextMobile: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      width: '297px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
    },
  },

  helpTextDesktop: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },

  flexMobile: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      width: '297px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      marginTop: '12px',
    },
  },

  flexDesktop: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  physicalTherapyDesktop: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  physicalTherapyMobile: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
  transformDesktop: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  transformMobile: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
  promoContainer: {
    padding: '5px',
    maxWidth: '100%',
    maxHeight: '100%',
    // width: "150px",
    // height: "30px",
    backgroundColor: '#28AAE2',
    color: '#FFFFFF',
    position: 'absolute',
    marginLeft: '200px',
    marginTop: '-19px',
    border: '3px solid #28AAE2',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      marginLeft: '220px',
    },
  },
  plan: {
    // maxWidth: "100%",
    height: '80px',
    width: '414px',
    padding: '15px 12px 15px 21px',
    // padding : "12px 10px 12px 18px",
    //   height: "88px",
    // borderRadius: 6,
    // margin: '0px 12px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25);',
    //   boxShadow: "0 8px 16px 2px rgba(15, 18, 26, 0.08)",
    cursor: 'pointer',
    // position: 'relative',
    marginTop: 24,
    // borderColor : "#000000",
    borderRadius: '4px',
    borderStyle: 'solid',
    display: 'flex',
    alignItems: 'center',
    borderWidth: '1px',
    [theme.breakpoints.down('md')]: {
      height: 66,
      width: '343px',
      padding: '12px 10px 12px 18px',
      height: '66px',
    },
  },
  planWithoutPromo: {},
  pricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'lowercase',
    // paddingLeft : "54px",
    fontSize: '16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
      paddingLeft: '0px',
    },
  },
  monthGap: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
      // alignItems : "center",
      flexDirection: 'column',
    },
  },
  tst: {
    padding: '5px',
    // maxWidth: "100%",
    // maxHeight: "100%",
    // width: "100%",
    // height: "100%",
    backgroundColor: '#28AAE2',
    color: '#FFFFFF',
    // position: 'absolute',
    // marginLeft : "200px",
    position: 'absolute',
    marginTop: '-97px',
    border: '3px solid #28AAE2',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      marginTop: '-82px',
      // marginLeft : "220px",
    },
  },
  currentPlanContainer: {
    padding: '5px',
    // maxWidth: "100%",
    // maxHeight: "100%",
    // width: "100%",
    // height: "100%",
    backgroundColor: 'gray',
    color: '#FFFFFF',
    // position: 'absolute',
    // marginLeft : "200px",
    position: 'absolute',
    marginTop: '-97px',
    border: '3px solid gray',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      marginTop: '-82px',
      // marginLeft : "220px",
    },
  },
  tst2: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '25px',
  },
  yearContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  saleLine: {
    // color: "#000000",
    // width: "66px",
    // border: "1px solid #000000",
    // position : "absolute",
    // marginTop : "-8px",
    // borderWidth : "0.3px",
    // [theme.breakpoints.down('xs')]: {
    //     marginTop : "-7px",
    //     width: "44px",
    //     // marginLeft : "220px",
    // },
    // [theme.breakpoints.down('md')]: {
    //     width : "55px",
    //     marginTop : "-7px",

    //   },
    textDecoration: 'line-through',
  },
  arrow: {
    paddingLeft: '17px',
  },
  planMainContainer: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  planTrialDuration: {
    fontSize: '18px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  prices: {
    fontSize: '16px',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  monthPrices: {
    fontSize: '16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  strentchingPicturesContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: '0px 89px 0px 89px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px',
    },
  },
  signOutContianer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  signOutButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'white',
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: '#28AAE2',
    width: '100px',
    height: '20px',
    borderRadius: '30px',
    color: '#28AAE2',
    fontSize: 12,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#d3d3d3',
      color: 'white',
    },
  },
  starsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
 
});

const Pricing = ({ pricingPlans, isSelected,plans,getPaymentPlans ,classes}) => {

  


  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [selectedPlan, setSelectedPlan] = useState(0);
  const [value, setValue] = React.useState(0);



  useEffect(() => {
    getPaymentPlans();
  }, [getPaymentPlans]);

  console.log(plans , "THIS IS PLANES")



  // console.log(pricingPlans[selectedPlan], 'ES ARIS DATA');

  // console.log(pricingPlans, 'pricing plans');

  const TabPanel = ({ children, value, index, ...other }) => {
    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && <div>{children}</div>}
      </div>
    );
  };

  const getIntervalName = ({
    billingType,
    interval,
    intervalCount,
    intervalType,
  }) => {
    if (billingType === 'once') {
      return 'single payment';
    }

    if (interval === 'year') {
      return 'yearly';
    }
    if (interval === 'month') {
      return 'monthly';
    }
    if (interval === 'week') {
      return 'weekly';
    }
    if (interval === '3months') {
      return '3 months';
    }
    if (interval === '6months') {
      return '6 months';
    }

    if (interval === 'custom') {
      if (parseInt(intervalCount) === 1) {
        if (intervalType === 'day') {
          return 'daily';
        }
        if (intervalType === 'month') {
          return 'monthly';
        }
        if (intervalType === 'year') {
          return 'yearly';
        }
      }
      return `${intervalCount} ${intervalType}s`;
    }
  };

  

  const WEB_APP_URL = 'https://app.movementvault.com/'
  const handlePlanClick = async (code) => {
    window.location.href = `${WEB_APP_URL}auth/signup/${code}`;
  };

  let title = 'Movement Vault - The #1 flexibility and mobility app';
  let description =
    'Stretching workouts. Mobility workouts. Rehab workouts. Physical therapy workouts. Created by Dr. Grayson Wickham. Start Free.';

  const Plan = ({ isSelected, onClick, plan, index, hidePromo }) => {
    const isCurrentPlan = false;
    const price = Math.trunc(plan.pricePerMonth || plan.price) / 100;
    const planPrice = Math.trunc(plan.price) / 100;
    const priceForMostExpensivePlan =
      Math.trunc(plan.priceForMostExpensivePlan) / 100;

      console.log(priceForMostExpensivePlan,"KUTU")
    return (
      <div
        onClick={() => handlePlanClick(plan.code)}
        className={classes.planMainContainer}
      >
        {isCurrentPlan || plan.promoText ? (
          <div className={classes.tst2}>
            <div
              className={
                isCurrentPlan ? classes.currentPlanContainer : classes.tst
              }
            >
              <div>
                {isCurrentPlan ? 'Current plan' : ''}
                {isCurrentPlan && plan.promoText ? ': ' : ''}
                {plan.promoText}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div
          className={cx(
            classes.plan,

            plan.promoText ? '' : classes.planWithoutPromo,

            isCurrentPlan ? classes.unclickable : ''

            // isSelected ? classes.planSelected : ''
          )}
          onClick={isCurrentPlan ? () => {} : onClick}
        >
          <div className={cx(classes.interval)}>
            <div className={classes.yearContainer}>
              <div className={classes.monthGap}>
                <div className={classes.planTrialDuration}>
                  <span className={classes.boldText}>
                    {getIntervalName(plan)} -{' '}
                  </span>
                  {plan.trialDuration
                    ? `Free ${plan.trialDuration}-Day Trial`
                    : 'No Free Trial'}
                </div>
                {/* <b/> */}
                <div className={classes.prices}>
                  {price !== planPrice ? (
                    <div>
                      $
                      <span className={classes.saleLine}>
                        {priceForMostExpensivePlan}
                      </span>
                      <span
                        className={classes.boldText}
                        style={{ marginLeft: '6px' }}
                      >
                        {plan.price / 100} /{' '}
                        {plan.intervalCount > 1 ? `${plan.intervalCount} ` : ''}
                        {getIntervalName(plan) === 'yearly' ? 'yr' : 'mo'}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className={classes.pricing}>
              <span className={classes.boldText}>
                $ {price}
                {plan.billingType === 'once' ? '' : '/ mo'}
              </span>{' '}
              <img className={classes.arrow} src={arrow} alt='' />{' '}
            </div>
          </div>
        </div>
      </div>
    );
  };




  const Plans = ({ plans }) => {
    let mostExpensivePlan = 0;
    const plansToRender = plans
    .filter((plan) => plan.isPrivate === false)
    ?.map((plan) => {
        if (plan.billingType == 'once') {
          return plan;
        }
        const newPlan = {
          ...plan,
        };
        let divider = 1;
        if (plan.interval == 'year') {
          divider *= 12;
        }
        if (plan.intervalCount > 0) {
          divider *= plan.intervalCount;
        }
        newPlan.pricePerMonth = newPlan.price / divider;
        if (mostExpensivePlan < newPlan.pricePerMonth) {
          mostExpensivePlan = newPlan.pricePerMonth;
        }
        return newPlan;
      })
      .map((plan) => {
        let multiplier = 1;
        if (plan.interval == 'year') {
          multiplier *= 12;
        }
        if (plan.intervalCount > 0) {
          multiplier *= plan.intervalCount;
        }
        const priceForMostExpensivePlan = mostExpensivePlan * multiplier;
        return {
          ...plan,
          priceForMostExpensivePlan,
        };
      });

    return (
      <div className={classes.plans}>
        {plansToRender.map((plan, index) => (
          <Plan
            key={plan.code + index}
            plan={plan}
            index={index}
            hidePromo={true}
            isSelected={selectedPlan === plan.code}
            onClick={() => setSelectedPlan(plan.code)}
          />
        ))}
      </div>
    );
  };




  return (
    <div  className={classes.nunu}>
      <head>
     

        {PageSeoTags({ title, description })}
      </head>
      <Navbar
        transparent={false}
        tab='PRICING'
        hasStartButton={false}
        changeWhenScroll
        position='sticky'
      />

      <div className={classes.container}>
        <div className={classes.choosePlanMainContainer}>
          <div className={classes.headerText}>
            <div>
              <span className={classes.boldText}>Free</span> Movement Vault
              Premium
            </div>
            <div>for 7-Days</div>
          </div>
          <div className={classes.cancelAnyTimeText}>
            <div>
              <span className={classes.boldText}>$0</span> today. Cancel
              anytime.
            </div>
          </div>

          <div className={classes.memberShipContainer}>
            <div className={classes.memberShipMainText}>
              Your Premium membership includes:
            </div>
            <div className={classes.memberShipLine} />
            <div className={classes.memberShipInfoListConatiner}>
              <div className={classes.memberShipInfoListText}>
                <img alt='' src={vector} className={classes.vectorImgStyles} />
                <div className={classes.memberShipInfoListTextEachText}>
                  A new stretching + mobility class every day
                </div>
              </div>
              <div className={classes.memberShipInfoListText}>
                <img alt='' src={vector} />
                <div className={classes.memberShipInfoListTextEachText}>
                  1500+ classes to choose from
                </div>
              </div>
              <div className={classes.memberShipInfoListText}>
                <img alt='' src={vector} />
                <div className={classes.memberShipInfoListTextEachText}>
                  Complete pain & injury programs
                </div>
              </div>
              <div className={classes.memberShipInfoListText}>
                <img alt='' src={vector} />
                <div className={classes.memberShipInfoListTextEachText}>
                  Pre-workout, recovery, desk routines + more
                </div>
              </div>
              <div className={classes.memberShipInfoListText}>
                <img alt='' src={vector} />
                <div className={classes.memberShipInfoListTextEachText}>
                  Life changing results
                </div>
              </div>
              <div className={classes.memberShipInfoListText}>
                <img alt='' src={vector} />
                <div className={classes.memberShipInfoListTextEachText}>
                  Cancel anytime
                </div>
              </div>
            </div>
          </div>
          <div></div>

          <div className={classes.plansContainer}>
            {plans?.map((plan, index) => {
              return (
                <TabPanel key={plan.id} value={value} index={index}>
                  <Plans plans={plan.plans.sort((a, b) => a.order - b.order)} />
                </TabPanel>
              );
            })}
          </div>

         

          <div className={classes.provenResults}>
            {/* <div className={classes.provenResultsContainer}> */}
            <div className={classes.provenResultsMainText}>Proven Results</div>
            <div className={classes.provenResultsText}>
              <div className={classes.movmentVaultUsersText}>
                <div>92% of Movement Vault users achieve</div>
                <div> improved flexibility and mobility within</div>
                <div> 30 days.</div>
              </div>
            </div>
            <div className={classes.provenResultsTextBased}>
              *based on 5,000 user survey
            </div>
            {/* </div> */}
          </div>

          <div className={classes.strechBetterContainer}>
            <div className={classes.strentchingPicturesContainer}>
              <img alt='' src={louisBalleta} className={classes.pic2} />
              <img alt='' src={frontSplits} className={classes.pic1} />
              <img alt='' src={male60} className={classes.pic3} />
            </div>
            <div className={classes.stretchBetter}>
              Stretch better. Stretch smarter.
            </div>
            <div className={classes.stretchBetterParagraph}>
              <div className={classes.physicalTherapyMobile}>
                <div>Created by top Doctors of Physical </div>
                <div>Therapy.</div>
              </div>
              <div className={classes.physicalTherapyDesktop}>
                Created by top Doctors of Physical Therapy.
              </div>

              <div className={classes.transformDesktop}>
                You will transform the way your joints feel and move.
              </div>

              <div className={classes.transformMobile}>
                You will transform the way your
                <div>joints feel and move.</div>
              </div>
            </div>
          </div>

          <div className={classes.customersRatingMainContainer}>
            <div className={classes.customersSingleReviewContainer2}>
              <div className={classes.starsContainer}>
                {/* <div className={classes.customerStars}/> */}
                <img alt='' src={star} />
                <img alt='' src={star} />
                <img alt='' src={star} />
                <img alt='' src={star} />
                <img alt='' src={star} />
              </div>
              <div className={classes.usersReviewDescirption}>
                <div>“This app helped me improve my</div>
                <div>mobility faster than any other app or</div>
                <div>program I've tried. I never knew</div>
                <div>stretching this way could make such a</div>
                <div>difference.”</div>
              </div>
              <div className={classes.customer}>-Jarod K.</div>
            </div>
            <div className={classes.customersSingleReviewContainer}>
              <div className={classes.starsContainer}>
                <img alt='' src={star} />
                <img alt='' src={star} />
                <img alt='' src={star} />
                <img alt='' src={star} />
                <img alt='' src={star} />
              </div>
              <div className={classes.usersReviewDescirption}>
                <div>“Movement Vault changed my life. I</div>
                <div>tried everything, but it was MV that</div>
                <div>finally helped me fix my back and knee</div>
                <div>pain. I feel better than I ever </div>
                <div>have.”</div>
              </div>
              <div className={classes.customer}>-Christine P.</div>
            </div>
          </div>

          <div className={classes.helpContainer}>
            <div className={classes.helpContainerText}>
              <div className={classes.helpTextMobile}>
                {' '}
                <div>
                  We have helped{' '}
                  <span className={classes.boldText}>100,000+</span> people
                </div>
                <div> just like you.</div>
              </div>
              <div className={classes.helpTextDesktop}>
                {' '}
                We have helped{' '}
                <span className={classes.boldText}>100,000+</span> people just
                like you.
              </div>

              <div className={classes.flexMobile}>
                <div> Improve your flexibility and mobility</div>
                <div> and fix your nagging pain right now.</div>
              </div>

              <div className={classes.flexDesktop}>
                <div>
                  {' '}
                  Improve your flexibility and mobility and fix your nagging
                </div>
                <div> pain right now.</div>
              </div>
            </div>
            <div className={classes.customersSelectPlanContainer}>
              {/* aq aris plan choose */}
            </div>
            <div className={classes.plansContainer}>
              {plans?.map((plan, index) => {
                return (
                  <TabPanel key={plan.id} value={value} index={index}>
                    <Plans
                      plans={plan.plans.sort((a, b) => a.order - b.order)}
                    />
                  </TabPanel>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};





const mapStateToProps = ({ auth: { user, planId }, payment: { plans } }) => ({
  plans,
  planId,
  user,
});


const mapDispatchToProps = {
  getPaymentPlans: Creators.getPaymentPlans,
  pushTo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Pricing));





// export default Pricing;

// Pricing.propTypes = {
//   pricingPlans: PropTypes.array.isRequired,
// };

// export async function getServerSideProps() {
//   const { data } = await axios.get('/site/plans');

//   return {
//     props: {
//       pricingPlans: data,
//     },
//   };
// }







