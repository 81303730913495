import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useScrollTrigger,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';

import logo from '../../../assets/images/logo.svg';

const useStyles = makeStyles((theme) => ({
  logo: {
    paddingLeft: '1vw',
    paddingRight: '1vw',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '5vw',
    },
    zIndex: 1,
  },
  newLogo: {
    width: 350,
    [theme.breakpoints.down('xs')]: {
      width: 139,
    },
  },
  root: {
    height: '100%',
    paddingLeft: '10vw',
    paddingRight: '10vw',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  linkWrapper: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'space-around',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  navbar: {
    background: ({ transparent }) =>
      transparent ? 'transparent' : theme.palette.hunterGreen.main,
    height: ({ hasStartButton, isSmallScreen }) =>
      isSmallScreen ? (hasStartButton ? 88 : 50) : 88,
    overflow: 'none',
  },
  defaultLink: {
    display: 'inline-block',
    overflow: 'hidden',
    textDecoration: 'none',
  },
  startButton: {
    height: 40,
    width: 160,
    borderRadius: 2,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
    [theme.breakpoints.down('xs')]: {
      backgroundColor: ({ changeWhenScroll, isIndexPage }) =>
        changeWhenScroll || !isIndexPage
          ? theme.palette.secondary.main
          : 'transparent',
      borderColor: ({ changeWhenScroll }) =>
        changeWhenScroll ? 'none' : 'white',
      color: ({ changeWhenScroll, isIndexPage }) =>
        changeWhenScroll || !isIndexPage
          ? theme.palette.hunterGreen.main
          : 'white',
      width: 'auto',
      paddingLeft: '3vw',
      paddingRight: '3vw',
    },
  },
  startButtonText: {
    fontWeight: 'bold',
    fontSize: 13,
    letterSpacing: 1,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  menuButton: {
    color: 'white',
    // [theme.breakpoints.down('xs')]: {
    //   transform: "scale(1.8)"
    // },
  },
  programs: {
    fontWeight: 500,
    fontSize: '11px',
    letterSpacing: '0.85px',
    color: 'white',
  },
  programsListItem: {
    fontFamily: 'Lato',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '24px',
    color: 'white',
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 24,
    '&:hover': {
      color: '#4C4C4C',
      backgroundColor: '#CCFF00',
      fontWeight: 900,
    },
  },
  menuPaper: {
    paddingTop: 0,
    // paddingBottom: 12,
    width: 263,
    backgroundColor: 'rgb(2, 4, 1)',
    boxShadow: 'none',
    borderRadius: 0,
  },
  programsHover: {
    // '&:hover': {
    //   borderBottom: "4px solid rgb(204, 255, 0)"
    // },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

const PricingNavbar = ({
  transparent,
  tab,
  hasStartButton = true,
  changeWhenScroll = false,
  position = 'static',
  isIndexPage = false,
  onClick,
}) => {
  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
  });
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles({
    transparent: changeWhenScroll && scrollTrigger ? false : transparent,
    changeWhenScroll: changeWhenScroll && scrollTrigger,
    hasStartButton,
    isSmallScreen,
    isIndexPage,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    console.log('CLICKED');
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);


  const handleClickLink = () => {
    // window.location.href = `https://app.movementvault.com/auth/signup/price/${planId}/discount-code/${couponId1}`
    };

    // window.location.href = `https://app.movementvault.com/auth/signup/price/price_1Lz6deFI7NIXOnaQP1NUTcPV/discount-code/AARONMV20`  };


  return (
    <AppBar className={classes.navbar} elevation={0} position={position}>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        className={classes.root}
      >
        <Box alignItems='center' display='flex' className={classes.logo}>
          <a
            className={classes.defaultLink}
            href="/auth/claim-discount"
            target='_self'
            rel='noopener noreferrer'
          >
            <img className={classes.newLogo} src={logo} alt='movementvault' />
          </a>
        </Box>

        {/* <div>
          <div
            // id="basic-button"
            // aria-controls={open ? 'basic-menu' : undefined}
            // aria-haspopup="true"
            // aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            style={{ cursor: 'pointer' }}
            className={classes.programsHover}
          >
            <Box className={classes.programs}>PROGRAMS</Box>
          </div>
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            style={{
              marginTop: '30px',

              borderRadius: '0px',
              boxShadow: 'none',
              padding: 0,
              width: 263,
            }}
            classes={{ paper: classes.menuPaper }}
          >
            <a href='/program/hypermobility'>
              <MenuItem
                style={{ backgroundColor: 'black', color: 'white' }}
                className={classes.programsList}
              >
                Hypermobility Program
              </MenuItem>
            </a>
            <a href='/program/knee-pain'>
              <MenuItem
                style={{
                  backgroundColor: 'black',
                  color: 'white',
                  paddingTop: 15,
                }}
                className={classes.programsList}
              >
                Knee Pain Program
              </MenuItem>
            </a>
            <a href='/program/low-back-pain'>
              <MenuItem
                style={{
                  backgroundColor: 'black',
                  color: 'white',
                  paddingTop: 15,
                }}
                className={classes.programsList}
              >
                Low Back Pain Program
              </MenuItem>
            </a>
            <a href='/program/neck-pain'>
              <MenuItem
                style={{
                  backgroundColor: 'black',
                  color: 'white',
                  paddingTop: 15,
                }}
                className={classes.programsList}
              >
                Neck Pain Program
              </MenuItem>
            </a>
            <a href='/program/plantar-fasciitis'>
              <MenuItem
                style={{
                  backgroundColor: 'black',
                  color: 'white',
                  paddingTop: 15,
                }}
                className={classes.programsList}
              >
                Plantar Fasciitis Program
              </MenuItem>
            </a>
          </Menu>
        </div> */}

        <Box className={classes.linkWrapper}>
          {/* {PRICING_NAVBAR_LINKS.map(
            ({ label, link, isBig, target, childItems, style }) => {
              return (
                <>
                  <NavbarLink
                    style={style}
                    key={label}
                    isSelected={tab === label}
                    text={label}
                    isBig={isBig}
                    link={link}
                    target={target}
                    onClick={handleClick}
                  />
                  {childItems ? (
                    <Menu
                      id='basic-menu'
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                      style={{
                        marginTop: '53px',
                        marginLeft: '-24px',
                        borderRadius: '0px',
                        boxShadow: 'none',
                        padding: '0px',
                        width: 263,
                      }}
                      classes={{ paper: classes.menuPaper }}
                    >
                      {childItems?.map(({ label, link }) => {
                        return (
                          <Link href={link}>
                            <MenuItem className={classes.programsListItem}>
                              {label}
                            </MenuItem>
                          </Link>
                        );
                      })}
                    </Menu>
                  ) : null}
                </>
              );
            }
          )} */}

          {/* {programs?.map((labelName) => {
  return (<>
    {labelName.hypermobility}
    </>
  );
})} */}
        </Box>
        <Box display='flex'>
          {(!isSmallScreen || hasStartButton) && (
            <Box
              display='flex'
              alignItems='center'
              pl={{
                xs: 0,
                sm: '1vw',
              }}
              pr={{
                xs: '3vw',
                sm: '1vw',
              }}
            >
              <Button
                variant={
                  isSmallScreen || (changeWhenScroll && scrollTrigger)
                    ? 'outlined'
                    : 'contained'
                }
                className={classes.startButton}
                onClick={onClick}
              >
                <Typography variant='body1' className={classes.startButtonText}>
                  CLAIM DISCOUNT
                </Typography>
              </Button>
            </Box>
          )}
          <Box
            display={{
              xs: 'flex',
              sm: 'none',
            }}
            pr={{
              sm: 0,
            }}
          >
            {/* <IconButton
              // edge='start'
              className={classes.menuButton}
              color='inherit'
              aria-label='menu'
              onClick={() => setIsMenuOpen(true)}
            >
              <MenuIcon height={40} width={90} style={{ fontSize: 30 }} />
            </IconButton> */}
          </Box>
        </Box>
      </Box>
      {/* <NavbarMobile isOpen={isMenuOpen} setIsOpen={setIsMenuOpen} /> */}
    </AppBar>
  );
};

PricingNavbar.propTypes = {
  transparent: PropTypes.bool,
  tab: PropTypes.string,
  hasStartButton: PropTypes.bool,
  changeWhenScroll: PropTypes.bool,
  position: PropTypes.string,
  isIndexPage: PropTypes.bool,
};

export default PricingNavbar;
