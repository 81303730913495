import { createActions, createReducer } from 'reduxsauce';

const INITIAL_STATE = {
  plans: [],
  processing: false,
  isCancelledState: false,
};

const { Types, Creators } = createActions(
  {
    getPaymentPlansSuccess: ['response'],
    getPaymentPlansFail: ['error'],
    getPaymentPlans: () => ({
      type: 'payment/GET_PAYMENT_PLANS',
      payload: {
        request: {
          url: 'site/plans',
          method: 'get',
        },
      },
    }),
    checkCouponCodeSuccess: ['response'],
    checkCouponCodeFail: ['error'],
    checkCouponCode: (couponCode) => {
      console.log(couponCode, 'couponCode');
      return {
        type: 'teamMembers/UPDATE_TEAM_ACCOUNT_INVITE_ID',
        payload: {
          request: {
            url: `payment/stripe/check/${couponCode}`,
            method: 'get',
          },
        },
      }
    },

    sendManageSubscriptionEmailSuccess: ['response'],
    sendManageSubscriptionEmailFail: ['error'],
    sendManageSubscriptionEmail: () => ({
      type: 'payment/SEND_MANAGE_SUBSCRIPTION_EMAIL',
      payload: {
        request: {
          url: 'user/me/send-smemail',
          method: 'post',
        },
      },
    }),
    getPlanGroupsSuccess: ['response'],
    getPlanGroupsFail: ['error'],
    getPlanGroups: () => ({
      type: 'plans/GET_PLAN_GROUPS',
      payload: {
        request: {
          url: '/membership-plan-group?join=plans',
          method: 'get',
        },
      },
    }),
    cancelPlanSuccess: ['response'],
    cancelPlanFail: ['error'],
    cancelPlan: () => ({
      type: 'payment/CANCEL_PLAN',
      payload: {
        request: {
          url: 'payment/stripe/pay',
          method: 'delete',
        },
      },
    }),
    setPaymentMethodSuccess: ['response'],
    setPaymentMethodFail: ['error'],
    setPaymentMethod: ({ paymentMethod }) => ({
      type: 'payment/SET_PAYMENT_METHOD',
      payload: {
        request: {
          url: 'payment/stripe/defaultmethod',
          data: { paymentMethod },
          method: 'put',
        },
      },
    }),
    setIsCancelledState: ['isCancelledState'],
  },
  { prefix: 'payment/' }
);

// const getPaymentPlansSuccess = (
//   state = INITIAL_STATE,
//   { payload: { data } }
// ) => ({
//   ...state,
//   plans: data,
// });

const getPaymentPlansSuccess = (state = INITIAL_STATE, { payload: { data } }) => {
  console.log('DATAAAA', data); 
  return {
    ...state,
    plans: data,
  };
};

const getPlanGroupsSuccess = (
  state = INITIAL_STATE,
  { payload: { data } }
) => ({
  ...state,
  plans: data,
});

const cancelPlanSuccess = (state = INITIAL_STATE, { response }) => {
  return {
    ...state,
    ...INITIAL_STATE,
    processing: false,
    isCancelledState: true,
  };
};

const setPaymentMethodSuccess = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE,
});

const setIsCancelledState = (state = INITIAL_STATE, { isCancelledState }) => ({
  ...state,
  isCancelledState,
});

const Reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PAYMENT_PLANS_SUCCESS]: getPaymentPlansSuccess,
  [Types.SET_IS_CANCELLED_STATE]: setIsCancelledState,
  [Types.GET_PLAN_GROUPS_SUCCESS]: getPlanGroupsSuccess,
  [Types.CANCEL_PLAN_SUCCESS]: cancelPlanSuccess,
  [Types.SET_PAYMENT_METHOD_SUCCESS]: setPaymentMethodSuccess,
  [Types.SET_PAYMENT_METHOD_SUCCESS]: setPaymentMethodSuccess,
  // [Types.CHECK_COUPON_CODE]: checkCouponCode,
});

export { Creators, Types, Reducer };
