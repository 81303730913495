import React from 'react';
import { Box, Typography, Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import MobileStoreButton from 'react-mobile-store-button';
import cs from 'classnames';
import logo from '../../../assets/images/mv-logo-footer.svg';
import facebook from '../../../assets/images/002-facebook.svg';
import instagram from '../../../assets/images/001-instagram.svg';
import twitter from '../../../assets/images/003-twitter.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 384,
    backgroundColor: theme.palette.hunterGreen.main,
  },
  appStoreBadge: {
    height: 40,
    width: 140,
    '& a': {
      backgroundPosition: 'center !important',
      minWidth: 140,
      minHeight: 40,
    },
  },
  title: {
    fontSize: 14,
    fontWeight: 300,
    lineHeight: 2.86,
    letterSpacing: 0.21,
    color: 'white',
  },
  divider: {
    width: '80%',
    paddingTop: 20,
    'border-bottom': `1px solid ${theme.palette.slateGrey.main}`,
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  image: {
    width: '100%',
    height: 'auto',
  },
  link: {
    fontSize: 14,
    lineHeight: 3.14,
    letterSpacing: 0.21,
    color: 'white',
  },
  defaultLink: {
    display: 'block',
    overflow: 'hidden',
    textDecoration: 'none',
  },
  socialMedia: {
    padding: 5,
  },
  gridContainer: {
    paddingLeft: '10%',
    paddingRight: '10%',
    paddingTop: '1%',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '5%',
      paddingRight: '5%',
    },
  },
  logoImage: {
    paddingTop: 10,
  },
  copyright: {
    opacity: 0.44,
    fontSize: 14,
    lineHeight: 2.29,
    letterSpacing: 0.21,
    color: 'white',
  },
}));

const NewFooter = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

   const INSTAGRAM_URL = 'https://www.instagram.com/movementvault/';
 const FACEBOOK_URL = 'https://www.facebook.com/movementvault/';
 const TWITTER_URL = 'https://www.twitter.com/movementvault/';

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      className={classes.root}
    >
      {/* <Typography className={classes.title} variant='h4'>
        DOWNLOAD THE MOVEMENT VAULT APP
      </Typography>
      <MobileStoreButton
        className={classes.appStoreBadge}
        store='ios'
        url={IOS_APP_URL}
        linkProps={{ title: 'Movement vault' }}
        width={140}
        height={40}
      /> */}
      <div className={classes.divider} />
      {isSmallScreen && (
        <Box display='flex' flexDirection='column' justifyContent='center'>
          <Box display='flex' justifyContent='center' mt={2}>
            <img src={logo} alt='assess-your-own-body' height={32} width={34} />
          </Box>
          <a
            className={classes.defaultLink}
            href='https://www.movementvault.com/movement-vault-for-work'
            target='_self'
            rel='noopener noreferrer'
          >
            <Box display='flex' justifyContent='center'>
              <Typography className={classes.link} variant='h4'>
                MV For Work & Groups
              </Typography>
            </Box>
          </a>
          <a
            className={classes.defaultLink}
            href='https://support.movementvault.com/hc/en-us'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Box display='flex' justifyContent='center'>
              <Typography className={classes.link} variant='h4'>
                Help Center
              </Typography>
            </Box>
          </a>

          <a
            className={classes.defaultLink}
            href='https://www.movementvault.com/blog/latest'
            target='_self'
            rel='noopener noreferrer'
          >
            <Box display='flex' justifyContent='center'>
              <Typography className={classes.link} variant='h4'>
                Blog
              </Typography>
            </Box>
          </a>


          <a
            className={classes.defaultLink}
            href='https://www.movementvault.com/contact'
            target='_self'
            rel='noopener noreferrer'
          >
          <Box display='flex' justifyContent='center'>
            <Typography className={classes.link} variant='h4'>
              Contact
            </Typography>
          </Box>
</a>

          <a
            className={classes.defaultLink}
            href='https://www.movementvault.com/about-us'
            target='_self'
            rel='noopener noreferrer'
          >
            <Box display='flex' justifyContent='center'>
              <Typography className={classes.link} variant='h4'>
                About Us
              </Typography>
            </Box>
          </a>
          <a
            className={classes.defaultLink}
            href='https://www.movementvault.com/tos'
            target='_self'
            rel='noopener noreferrer'
          >
            <Box display='flex' justifyContent='center'>
              <Typography className={classes.link} variant='h4'>
                Terms
              </Typography>
            </Box>
          </a>
          <a
            className={classes.defaultLink}
            href='https://www.movementvault.com/privacy-policy'
            target='_self'
            rel='noopener noreferrer'
          >
            {' '}
            <Box display='flex' justifyContent='center'>
              <Typography className={classes.link} variant='h4'>
                Privacy
              </Typography>
            </Box>
          </a>
          <a
            className={classes.defaultLink}
            href='https://www.movementvault.com/cookies'
            target='_self'
            rel='noopener noreferrer'
          >
            <Box display='flex' justifyContent='center'>
              <Typography className={classes.link} variant='h4'>
                Cookies
              </Typography>
            </Box>
          </a>
          {/* <Box display='flex' justifyContent='center'>
            <Typography className={classes.link} variant='h4'>
              Affiliate Sign-up
            </Typography>
          </Box>
          <Box display='flex' justifyContent='center'>
            <Typography className={classes.link} variant='h4'>
              Download the Movement Vault app
            </Typography>
          </Box> */}
          <Box display='flex' justifyContent='center' py={2}>
            <a
              className={cs(classes.defaultLink, classes.socialMedia)}
              href={INSTAGRAM_URL}
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={instagram} alt='instagram' height={24} width={24} />
            </a>
            <a
              className={cs(classes.defaultLink, classes.socialMedia)}
              href={FACEBOOK_URL}
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={facebook} alt='facebook' height={24} width={24} />
            </a>
            <a
              className={cs(classes.defaultLink, classes.socialMedia)}
              href={TWITTER_URL}
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={twitter} alt='twitter' height={24} width={24} />
            </a>
          </Box>
        </Box>
      )}
      {!isSmallScreen && (
        <Grid container className={classes.gridContainer}>
          <Grid item xs={3}>
            <img
              src={logo}
              alt='assess-your-own-body'
              height={32}
              width={34}
              className={classes.logoImage}
            />
          </Grid>
          <Grid item xs={3}>
            <a
              className={classes.defaultLink}
              href='https://www.movementvault.com/movement-vault-for-work'
              target='_self'
              rel='noopener noreferrer'
            >
              <Typography className={classes.link} variant='h4'>
                MV For Work & Groups
              </Typography>
            </a>
            <a
              className={classes.defaultLink}
              href='https://support.movementvault.com/hc/en-us'
              target='_blank'
              rel='noopener noreferrer'
            >
              <Typography className={classes.link} variant='h4'>
                Help Center
              </Typography>
            </a>

            <a
              className={classes.defaultLink}
              href='https://www.movementvault.com/blog/latest'
              target='_self'
              rel='noopener noreferrer'
            >
              <Typography className={classes.link} variant='h4'>
                Blog
              </Typography>
            </a>

            <a
              className={classes.defaultLink}
              href='https://www.movementvault.com/contact'
              target='_self'
              rel='noopener noreferrer'
            >
            <Typography className={classes.link} variant='h4'>
              Contact
            </Typography>
            </a>
          </Grid>
          <Grid item xs={3}>
            <a
              className={classes.defaultLink}
              href='https://www.movementvault.com/about-us'
              target='_self'
              rel='noopener noreferrer'
            >
              <Typography className={classes.link} variant='h4'>
                About Us
              </Typography>
            </a>
            <a
              className={classes.defaultLink}
              href='https://www.movementvault.com/tos'
              target='_self'
              rel='noopener noreferrer'
            >
              <Typography className={classes.link} variant='h4'>
                Terms
              </Typography>
            </a>
            <a
              className={classes.defaultLink}
              href='https://www.movementvault.com/privacy-policy'
              target='_self'
              rel='noopener noreferrer'
            >
              <Typography className={classes.link} variant='h4'>
                Privacy
              </Typography>
            </a>
            <a
              className={classes.defaultLink}
              href='https://www.movementvault.com/cookies'
              target='_self'
              rel='noopener noreferrer'
            >
              <Typography className={classes.link} variant='h4'>
                Cookies
              </Typography>
            </a>
          </Grid>
          <Grid item xs={3}>
            <Box display='flex' justifyContent='flex-end'>
              <a
                className={cs(classes.defaultLink, classes.socialMedia)}
                href={INSTAGRAM_URL}
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={instagram} alt='instagram' height={24} width={24} />
              </a>
              <a
                className={cs(classes.defaultLink, classes.socialMedia)}
                href={FACEBOOK_URL}
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={facebook} alt='facebook' height={24} width={24} />
              </a>
              <a
                className={cs(classes.defaultLink, classes.socialMedia)}
                href={TWITTER_URL}
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={twitter} alt='twitter' height={24} width={24} />
              </a>
            </Box>
          </Grid>
        </Grid>
      )}
      <Box
        display='flex'
        justifyContent={isSmallScreen ? 'center' : 'flex-start'}
        width={isSmallScreen ? '90%' : '80%'}
      >
        <Typography className={classes.copyright}>
          Movement Vault, LLC &copy; {new Date().getFullYear()}. All rights
          reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default NewFooter;
