import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import SignIn from './components/SignInForm';
import SignUp from './components/SignUpForm';
import SignUpFormWithTeamToken from './components/SignUpFormWithTeamToken';
import RequestReset from './components/RequestResetForm';
import EmailSent from './components/EmailSent';
import ResetPassword from './components/ResetPasswordForm';
import { PrivacyPolicy, ToS } from 'components';
// import Cookies from './components/Cookies';
import MainLayout from './components/MainLayout';
import SelectPlan from 'modules/dashboard/payment/components/SelectPlan';
import { NotFound } from 'components';
import Affiliate from './components/Affiliate';
import Pricing from './components/Pricing';
import ClaimDiscount from './components/ClaimDiscount';

export default (props) => {
  const {
    match: { url },
    location: { pathname },
  } = props;
  return (
    <MainLayout
      useLayout={
        !pathname.startsWith('/auth/signup') &&
        !pathname.startsWith('/auth/affiliate') &&
        !pathname.startsWith('/auth/pricing') &&
        !pathname.startsWith('/auth/new-member-offer') 
      }
    >

      <Switch>
        

        {/* <Route path={`${url}/new-member-offer/`} component={ClaimDiscount} /> */}
        <Route path={`${url}/new-member-offer/price/:planId?/discount-code/:couponId1`} component={ClaimDiscount} />

        <Route path={`${url}/affiliate`} component={Affiliate} />
        <Route path={`${url}/pricing`} component={Pricing} />
        <Route path={`${url}/signin`} component={SignIn} />

        <Route
          path={`${url}/signup/team/:teamToken?`}
          component={SignUpFormWithTeamToken}
        />

        <Route path={`${url}/signup/price/:planId?/discount-code/:couponId`} component={SignUp} />

        <Route path={`${url}/signup/:planId?`} component={SignUp} />
        {/* <Route path={`${url}/signup/price/:planId?/discount-code/:couponId`} component={SignUp} /> */}
        <Route path={`${url}/forgot-password`} component={RequestReset} />
        <Route path={`${url}/email-sent`} component={EmailSent} />
        <Route
          path={`${url}/reset-password/:token`}
          component={ResetPassword}
        />
        <Route path={`${url}/privacy-policy`} component={PrivacyPolicy} />
        <Route path={`${url}/terms-and-conditions`} component={ToS} />
        <Route path={`${url}/choose-plan`} component={SelectPlan} />
        <Redirect exact={true} from={url} to={`${url}/signin`} />
        <Route component={NotFound} />
      </Switch>
    </MainLayout>
  );
};
