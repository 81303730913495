import { createActions, createReducer } from 'reduxsauce';
import { LOCATION_CHANGE } from 'connected-react-router';

import { toFormErrorObject } from 'core';
import {
  identify,
  MixpanelEvents,
  setSuperProperty,
  track,
} from 'core/utils/mixpanel';
import moment from 'moment';

const initialUser = {
  avatar: '',
  bio: '',
  email: '',
  firstName: '',
  id: '',
  lastName: '',
  role: '',
  status: '',
  averageDuration: '',
  sessionsComplete: '',
  totalMobilityTime: '',
  maxStreak: '',
  currentStreak: '',
  method: '',
  paymentMethod: '',
  membership: '',
  affiliateCode: '',
  credits: 0,
  teamAccountInviteId: '',
};

const INITIAL_STATE = {
  error: {},
  initializing: false,
  processing: false,
  rememberMe: false,
  user: initialUser,
  persona: 'user',
  planId: null,
  couponId : null,
  plans:[]
};

const { Types, Creators } = createActions(
  {







    meSuccess: ['response'],
    meFail: ['error'],
    me: ({ loadingVisible } = { loadingVisible: true }) => ({
      type: 'auth/ME',
      payload: {
        loadingVisible,
        request: {
          url: 'user/me',
          method: 'get',
        },
      },
    }),
    updateMeSuccess: ['response'],
    updateMeFail: ['error'],
    updateMe: (user) => ({
      type: 'auth/UPDATE_ME',
      payload: {
        request: {
          url: 'user/me',
          method: 'patch',
          data: user,
        },
      },
    }),

    regenerateTeamAccountInviteIdSuccess: ['response'],
    regenerateTeamAccountInviteIdFail: ['error'],
    regenerateTeamAccountInviteId: (accountId, user) => {
      console.log(accountId, 'TEAM ACCOUNT INVITEEEE ID');
      return {
        type: 'auth/REGENERATE_TEAM_ACCOUNT_INVITE_ID',
        payload: {
          request: {
            url: `user/team/member/update/${accountId}`,
            method: 'patch',
            data: user,
          },
        },
      };
    },
    deactivateMeSuccess: ['response'],
    deactivateMeFail: ['error'],
    deactivateMe: () => ({
      type: 'auth/DEACTIVATE_ME',
      payload: {
        request: {
          url: 'user/me/deactivate',
          method: 'post',
        },
      },
    }),
    updatePasswordSuccess: ['response'],
    updatePasswordFail: ['error'],
    updatePassword: (password) => ({
      type: 'auth/UPDATE_PASSWORD',
      payload: {
        request: {
          url: '/user/me/password',
          method: 'patch',
          data: {
            password,
          },
        },
      },
    }),
    requestResetSuccess: ['response'],
    requestResetFail: ['error'],
    requestReset: ({ email }) => ({
      type: 'auth/REQUEST_RESET',
      payload: {
        request: {
          url: 'auth/reset-password-request',
          method: 'put',
          data: {
            email,
          },
        },
      },
    }),
    sendInviteSuccess: ['response'],
    sendInviteFail: ['error'],
    sendInvite: ({ email }) => ({
      type: 'auth/SEND_INVITE',
      payload: {
        request: {
          url: 'user/me/invite',
          method: 'post',
          data: {
            email,
          },
        },
      },
    }),
    resetPasswordSuccess: ['response'],
    resetPasswordFail: ['error'],
    resetPassword: ({ token, newPassword, confirmPassword }) => ({
      type: 'auth/RESET_PASSWORD',
      payload: {
        request: {
          url: '/auth/reset-password',
          method: 'post',
          data: {
            token,
            password: newPassword,
            passwordConfirmation: confirmPassword,
          },
        },
      },
    }),
    signInSuccess: ['response'],
    signInFail: ['error'],
    signIn: ({ email, password, rememberMe, persona = 'user' }) => ({
      type: 'auth/SIGN_IN',
      payload: {
        persona,
        request: {
          url: 'auth/login',
          method: 'post',
          data: {
            email,
            password,
            rememberMe,
          },
        },
      },
    }),
    signInWithFbSuccess: ['response'],
    signInWithFbFail: ['error'],
    signInWithFb: ({ accessToken }) => ({
      type: 'auth/SIGN_IN_WITH_FB',
      payload: {
        request: {
          url: 'auth/login-facebook',
          method: 'post',
          data: { token: accessToken },
        },
      },
    }),
    signUpSuccess: ['response'],
    signUpFail: ['error'],
    signUp: ({
      firstName,
      lastName,
      email,
      password,
      planId,
      couponId,
      inviteCode,
      teamToken,
    }) => ({
      type: 'auth/SIGN_UP',
      payload: {
        request: {
          url: 'auth/register',
          method: 'post',
          data: {
            firstName,
            lastName,
            email,
            password,
            planId,
            couponId,
            inviteCode,
            teamToken,
          },
        },
      },
    }),
    signOut: null,
    setPlanId: ['planId'],
    setCouponId: ['couponId'],
    redeemCreditsSuccess: ['response'],
    redeemCreditsFail: ['error'],
    redeemCredits: () => ({
      type: 'auth/REDEEM_CREDITS',
      payload: {
        request: {
          url: 'user/me/redeem',
          method: 'post',
        },
      },
    }),
  },
  { prefix: 'auth/' }
);

const me = (state = INITIAL_STATE, { payload: { loadingVisible } }) => ({
  ...state,
  initializing: loadingVisible,
});

const meSuccess = (
  state = INITIAL_STATE,
  {
    payload: {
      data: {
        avatar,
        bio,
        email,
        firstName,
        id,
        lastName,
        role,
        status,
        averageDuration,
        sessionsComplete,
        totalMobilityTime,
        maxStreak,
        currentStreak,
        method,
        paymentMethod,
        membership,
        affiliateCode,
        stripeCredit,
        appStoreId,
        ...otherData
      },
    },
  }
) => {
  return {
    ...state,
    initializing: false,
    user: {
      ...state.user,
      ...otherData,
      avatarUrl: avatar?.publicLocation || '',
      bio,
      email,
      firstName,
      id,
      lastName,
      role,
      status: status,
      averageDuration,
      sessionsComplete,
      totalMobilityTime,
      maxStreak,
      currentStreak,
      method,
      appStoreId,
      paymentMethod: {
        expMonth: paymentMethod?.expMonth,
        expYear: paymentMethod?.expYear,
        last4digits: paymentMethod?.last4digits,
        id: paymentMethod?.id,
      },
      affiliateCode,
      avatar: avatar?.id,
      credits: stripeCredit,
      membership: {
        ...membership,
        status: (() => {
          switch (membership?.status) {
            case 'active':
              return 'Active';
            case 'trial':
              return 'Trial Period';
            case 'notActive':
              return 'Inactive';
            case 'canceled':
              return 'Active (cancelled)';
            default:
              return 'unknown';
          }
        })(),
      },
    },
  };
};

const meFail = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE,
});

const resetPassword = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const resetPasswordSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    processing: false,
  };
};

const resetPasswordFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  ...INITIAL_STATE,
  error: toFormErrorObject(payload.response),
});

const updatePassword = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const updatePasswordSuccess = (state = INITIAL_STATE) => {
  track(MixpanelEvents.changedPassword, { changed: true });

  return {
    ...state,
    processing: false,
  };
};

const updatePasswordFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  ...INITIAL_STATE,
  error: toFormErrorObject(payload.response),
});

const updateMe = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const updateMeSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: {
          request: { data },
        },
      },
    },
  }
) => {
  console.log(data, 'datucha');

  const previousUser = state.user;
  const nextUser = data;

  if (previousUser.email !== nextUser.email) {
    track(MixpanelEvents.changedEmail, { value: true });
  }

  return {
    ...state,
    processing: false,
    user: {
      ...state.user,
      ...data,
    },
  };
};

const regenerateTeamAccountInviteId = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const regenerateTeamAccountInviteIdSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: {
          request: { user },
        },
      },
    },
    payload: {
      data: { teamAccountInviteId },
    },
  }
) => {
  return {
    ...state,
    processing: false,

    user: {
      ...state.user,
      teamAccountInviteId,
    },
  };
};

const updateMeFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  error: toFormErrorObject(payload.response),
});

const deactivateMe = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const deactivateMeSuccess = (state = INITIAL_STATE) => {
  track(MixpanelEvents.deletedAccount, { value: true });

  return {
    ...state,
    ...INITIAL_STATE,
  };
};

const sendInviteFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  error: toFormErrorObject(payload.response),
});

const sendInvite = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const sendInviteSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    processing: false,
  };
};

const deactivateMeFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  error: toFormErrorObject(payload.response),
});

const requestReset = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const requestResetSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    processing: false,
  };
};

const requestResetFail = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE,
});

const signIn = (state = INITIAL_STATE, action) => ({
  ...state,
  processing: true,
  rememberMe: action.payload.request.data.rememberMe,
});

const signInSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: { persona },
      },
    },
    payload: {
      data: { avatar, bio, email, id, firstName, lastName, role, status },
    },
  }
) => {
  identify(id);
  track(MixpanelEvents.loggedIn, {
    loginMethod: 'email',
    loginDate: moment().format('YYYY-MM-DD'),
    platform: 'web',
  });

  return {
    ...state,
    processing: false,
    persona,
    user: {
      avatar,
      bio,
      email,
      firstName,
      lastName,
      role,
      status,
    },
  };
};

const signInFbSuccess = (
  state = INITIAL_STATE,
  {
    payload: {
      data: { user },
    },
  }
) => {
  return {
    ...state,
    processing: false,
    user: {
      id: user.id,
      role: user.role,
    },
  };
};

const signInFail = (state = INITIAL_STATE, { payload: { response } }) => {
  return {
    ...INITIAL_STATE,
    processing: false,
    error: toFormErrorObject(response),
  };
};
const signUp = (state = INITIAL_STATE, action) => {
  console.log('Action:', action); 


  const planId = action.payload?.request?.data?.planId || null;
  const couponId = action.payload?.request?.data?.couponId || null;

  return {
    ...state,
    planId,
    couponId,
    processing: true,
  };
};

const signUpSuccess = (
  state = INITIAL_STATE,
  {
    payload: {
      data: { bio, email, firstName, id, lastName, role, status },
    },
  }
) => {
  identify(id);

  setSuperProperty({
    name: `${firstName} ${lastName}`,
    email,
    registrationDate: moment().format('YYYY-MM-DD'),
    registrationMethod: 'email',
    platform: 'web',
    // registrationLocation: '',
    // referred: true/false,
    // referredBy: name,
    // trafficSource: '', // utm param
  });

  track(MixpanelEvents.webRegistrationCompleted, {
    // referredBy: name,
    // inviteFriendCodeApplied: true/false,
    // inviteFriendCode: '',
  });

  return {
    ...state,
    processing: false,
    user: {
      firstName,
      lastName,
      id,
      email,
      bio,
      role,
      status,
    },
  };
};

const signUpFail = (state = INITIAL_STATE, { payload }) => ({
  ...INITIAL_STATE,
  error: toFormErrorObject(payload.response),
});

const signOut = (state = INITIAL_STATE) => ({
  ...INITIAL_STATE,
  persona: state.persona,
});

const setPlanId = (state = INITIAL_STATE, { planId }) => ({
  ...state,
  planId,
});

const setCouponId = (state = INITIAL_STATE, { couponId }) => ({
  ...state,
  couponId,
});

const locationChange = (state = INITIAL_STATE) => ({
  ...state,
  error: {},
});

const redeemCredits = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const redeemCreditsSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    processing: false,
    user: {
      ...state.user,
      credits: 0,
    },
  };
};

const redeemCreditsFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  error: toFormErrorObject(payload.response),
});





const getPaymentPlansSuccess = (
  state = INITIAL_STATE,
  { payload: { data } }
) => ({
  ...state,
  plans: data,
});


const Reducer = createReducer(INITIAL_STATE, {
  [Types.ME]: me,
  [Types.ME_SUCCESS]: meSuccess,
  [Types.ME_FAIL]: meFail,
  [Types.REQUEST_RESET]: requestReset,
  [Types.REQUEST_RESET_SUCCESS]: requestResetSuccess,
  [Types.REQUEST_RESET_FAIL]: requestResetFail,
  [Types.RESET_PASSWORD]: resetPassword,
  [Types.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [Types.RESET_PASSWORD_FAIL]: resetPasswordFail,
  [Types.UPDATE_PASSWORD]: updatePassword,
  [Types.UPDATE_PASSWORD_SUCCESS]: updatePasswordSuccess,
  [Types.UPDATE_PASSWORD_FAIL]: updatePasswordFail,
  [Types.UPDATE_ME]: updateMe,
  [Types.UPDATE_ME_SUCCESS]: updateMeSuccess,
  [Types.UPDATE_ME_FAIL]: updateMeFail,
  [Types.DEACTIVATE_ME]: deactivateMe,
  [Types.DEACTIVATE_ME_SUCCESS]: deactivateMeSuccess,
  [Types.DEACTIVATE_ME_FAIL]: deactivateMeFail,
  [Types.SIGN_IN]: signIn,
  [Types.SIGN_IN_SUCCESS]: signInSuccess,
  [Types.SIGN_IN_FAIL]: signInFail,
  [Types.SIGN_IN_WITH_FB_SUCCESS]: signInFbSuccess,
  [Types.SIGN_IN_WITH_FB_FAIL]: signInFail,
  [Types.SIGN_UP]: signUp,
  [Types.SIGN_UP_SUCCESS]: signUpSuccess,
  [Types.SIGN_UP_FAIL]: signUpFail,
  [Types.SIGN_OUT]: signOut,
  [Types.SET_PLAN_ID]: setPlanId,
  [Types.SET_COUPON_ID]: setCouponId,
  [Types.REDEEM_CREDITS]: redeemCredits,
  [Types.REDEEM_CREDITS_SUCCESS]: redeemCreditsSuccess,
  [Types.REDEEM_CREDITS_FAIL]: redeemCreditsFail,
  [Types.SEND_INVITE]: sendInvite,
  [Types.SEND_INVITE_SUCCESS]: sendInviteSuccess,
  [Types.SEND_INVITE_FAIL]: sendInviteFail,
  [Types.REGENERATE_TEAM_ACCOUNT_INVITE_ID]: regenerateTeamAccountInviteId,
  [Types.REGENERATE_TEAM_ACCOUNT_INVITE_ID_SUCCESS]: regenerateTeamAccountInviteIdSuccess,

  [LOCATION_CHANGE]: locationChange,
});

export { Creators, Types, Reducer };
