import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import { useTable, useSortBy, useRowSelect, usePagination } from 'react-table';
import ReactPaginate from 'react-paginate';

import TableMenu from './TableMenu';
import { Creators, INITIAL_FILTER } from '../reducer';
import TopBar from './TopBar';
import moment from 'moment';
import Dots from 'components/icons/Dots';

import arrowDown from 'assets/svg/arrow-down.svg';
import arrowUp from 'assets/svg/arrow-up.svg';

const styles = (theme) => ({
  activeText: {
    color: theme.palette.secondary.dark,
    size: 14,
  },
  bottomControls: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 24,
  },
  checkbox: {
    color: theme.palette.primary.main,
  },
  dots: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  exportButton: {
    background: theme.palette.secondary.main,
    color: theme.palette.primary.dark,
    width: 230,
  },
  headerCell: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 10,
    letterSpacing: '0.06em',
    padding: 8,
    textAlign: 'start',
    textTransform: 'uppercase',
  },
  headerRow: {
    height: 48,
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    listStyleType: 'none',
    paddingInlineStart: 0,
    marginBlockStart: 0,
    marginBlockEnd: 0,
    '& > li': {
      color: theme.palette.primary.generalText,
      cursor: 'pointer',
      fontSize: 12,
      fontWeight: 600,
      letterSpacing: '0.06em',
      margin: 12,
    },
    '& li:first-child': {
      color: theme.palette.primary.main,
    },
    '& li:last-child': {
      color: theme.palette.primary.main,
    },
    '& > li.disabled': {
      color: 'rgba(74, 75, 107, 0.4)',
      cursor: 'initial',
    },
    '& > li.selected': {
      color: theme.palette.primary.main,
      cursor: 'initial',
    },
  },
  role: {
    fontSize: 10,
    lineHeight: 1.5,
    textTransform: 'uppercase',
  },
  roleAdmin: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  sortIcon: {
    marginLeft: 6,
  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0 4px',
    width: '100%',
  },
  tableRow: {
    background: theme.palette.background.paper,
    '& td': {
      color: theme.palette.primary.generalText,
      fontSize: 14,
      padding: 8,
    },
    '& td:first-child': {
      borderTopLeftRadius: 6,
      borderBottomLeftRadius: 6,
    },
    '& td:last-child': {
      borderTopRightRadius: 6,
      borderBottomRightRadius: 6,
    },
  },
  text: {
    color: theme.palette.primary.dark,
    fontSize: 12,
    letterSpacing: '0.04em',
    marginRight: 3,
    textTransform: 'uppercase',
  },
  textGray: {
    color: 'rgba(74, 75, 107, 0.4)',
  },
});

const Coupons = ({
  classes,
  data,
  deleteUsers,
  filter,
  getCoupons,
  setFilter,
  showModal,
}) => {
  const [coupons, setCoupons] = useState(data);
  const theme = useTheme();
  const { currentPage, statusFilter, searchTerm } = filter;

  const filterData = () => {
    return data.filter((item) => {
      return (
        (searchTerm
          ? (item['code'] || '')
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          : true) && (statusFilter ? item.status === statusFilter : true)
      );
    });
  };

 

  useEffect(() => {
    getCoupons();
  }, [getCoupons]);

  useEffect(() => {
    const unsorted = filterData();
    setCoupons(unsorted.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Code',
        accessor: 'code',
        Cell: ({ cell: { value } }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: 'Created',
        accessor: (row) => moment(row.createdAt).format('x'),
        Cell: ({ cell: { value } }) => {
          return <div>{moment(parseInt(value)).format('LLLL')}</div>;
        },
      },
      {
        Header: 'Discount',
        accessor: (row) =>
          row.type === 'amount'
            ? '$' + row.amount
            : row.amount + (row.type === 'credit' ? 'cr' : '%'),
        sortType: 'alphanumeric',
      },
      {
        Header: 'Mode',
        accessor: 'discountMode',
        sortType: 'alphanumeric',
      },
      {
        Header: 'Expires',
        accessor: (row) =>
          row.couponExpiration
            ? moment(row.couponExpiration).format('x')
            : false,
        Cell: ({ cell: { value } }) => {
          return (
            <div>
              {value ? moment(parseInt(value)).format('LLLL') : 'Never'}
            </div>
          );
        },
      },
      {
        Header: 'Usage Count',
        accessor: (row) =>
          (row.usageCount || '0') + '/' + (row.maxUsage || 'unlimited'),
        sortType: 'alphanumeric',
      },
      {
        Header: 'Applies to',
        accessor: 'membershipPlans',
        Cell: ({ cell: { value } }) => {
          return <div>{value.map((m) => m.title).join(', ')}</div>;
        },
      },
      {
        Header: () => <Dots fill={theme.palette.primary.generalText} />,
        Cell: ({ cell: { value } }) => {
          return <TableMenu couponId={value} />;
        },
        accessor: 'code',
        id: 'actions',
      },
    ],
    [theme]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageCount,
    gotoPage,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      autoResetPage: false,
      data: coupons,
      initialState: { pageSize: 10, pageIndex: currentPage },
    },
    useSortBy,
    usePagination,
    useRowSelect
    // (hooks) => {
    //   hooks.visibleColumns.push((columns) => [
    //     // Let's make a column for selection
    //     {
    //       id: 'selection',
    //       // The header can use the table's getToggleAllRowsSelectedProps method
    //       // to render a checkbox
    //       Header: ({ getToggleAllRowsSelectedProps }) => (
    //         <div>
    //           <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
    //         </div>
    //       ),
    //       // The cell can use the individual row's getToggleRowSelectedProps method
    //       // to the render a checkbox
    //       Cell: ({ row }) => (
    //         <div>
    //           <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //         </div>
    //       ),
    //     },
    //     ...columns,
    //   ]);
    // }
  );

  const statusSelect = {
    label: 'Status',
    onChange: (value) => setFilter({ ...filter, statusFilter: value }),
    options: [
      {
        label: 'All Statuses',
        value: '',
      },
      {
        label: 'Trash',
        value: 'trash',
      },
    ],
    value: statusFilter,
  };

  const searchInput = {
    value: searchTerm,
    onChange: (value) => setFilter({ ...filter, searchTerm: value }),
  };

  return (
    <div
      style={{
        width: '100%',
        marginBottom: 24,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
      }}
    >
      <Helmet>
        <title>Coupons</title>
      </Helmet>
      <div style={{ position: 'absolute', width: '100%' }}>
        <TopBar
          onBulkDelete={() =>
            showModal(
              'deleteConfirmation',
              () => deleteUsers(selectedFlatRows.map((d) => d.original.id)),
              'Are you sure you want to delete the user(s)?'
            )
          }
          onClearAll={() => setFilter(INITIAL_FILTER)}
          onSearch={() => {
            setFilter({ ...filter, currentPage: 0 });
            setCoupons(filterData());
            gotoPage(0);
          }}
          // statusSelect={statusSelect}
          searchInput={searchInput}
          showDelete={selectedFlatRows.length > 0}
        />
      </div>
      <div style={{ marginTop: 80, padding: '0px 16px' }}>
        <table className={classes.table} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                className={classes.headerRow}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className={classes.headerCell}>
                      {column.render('Header')}
                      <span className={classes.sortIcon}>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <img src={arrowDown} alt='arrow down' />
                          ) : (
                            <img src={arrowUp} alt='arrow up' />
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr className={classes.tableRow} {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className={classes.bottomControls}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{ display: 'flex', alignItems: 'center', marginRight: 30 }}
            >
              <span className={classes.text}>
                total {coupons.length} items.
              </span>
              <span className={cx(classes.text, classes.textGray)}>
                showing from 1 of {pageCount} pages
              </span>
            </div>
            <ReactPaginate
              activeClassName={classes.activePage}
              breakLabel={'...'}
              containerClassName={classes.pagination}
              marginPagesDisplayed={2}
              nextLabel={'NEXT'}
              onPageChange={({ selected }) => {
                gotoPage(selected);
                setFilter({ ...filter, currentPage: selected });
              }}
              pageClassName={classes.page}
              pageCount={pageCount}
              pageRangeDisplayed={5}
              previousLabel={'PREV'}
              subContainerClassName={'pages pagination'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// const mapStateToProps = ({ coupons, plan }) => ({
//   data: coupons.coupons,
//   filter: coupons.filter,
// });



const mapStateToProps = ({ coupons, plan }) => {
  // Log the entire state structure for debugging
  console.log('State in mapStateToProps:', { coupons, plan });

  // Log specific parts if needed
  console.log('Coupons:', coupons);
  console.log('Filter:', coupons.filter);
  console.log('Plan:', plan);

  return {
    data: coupons.coupons,
    filter: coupons.filter,
  };
};


const mapDispatchToProps = {
  getCoupons: Creators.getCoupons,
  setFilter: Creators.setFilter,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Coupons));
