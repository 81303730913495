import { Box, Button, NoSsr, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import menHealth from '../../../assets/images/menHealth.svg';
import womanHealth from '../../../assets/images/womanHealth.svg';
import newYorkTimes from '../../../assets/images/newYorkTimes.svg';
import liveStrong from '../../../assets/images/liveStrong.svg';
import healthLine from '../../../assets/images/healthLine.svg';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: '#F7F8FA',
    paddingTop: '70px',
    paddingBottom: '70px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexWrap : "wrap",
      paddingTop: '37px',
      paddingBottom: '37px',

      },
  },
  test: {
    backgroundImage: `url('../static/images/menHealth.svg')`,
  },
  smallBanner: {
   
    [theme.breakpoints.down('xs')]: {
      width : 155,
      padding : 12
      },
  },
  largeBanner: {
   
    [theme.breakpoints.down('xs')]: {
      width : 303,
      padding : 10
      },
  },
  imageContainer:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexWrap : "wrap",
      width : 320
      },
  }
}));

const ProgramsSponsors = ({}) => {
  const classes = useStyles();
  const [videoModalOpen, setVideoModalOpen] = useState(false);

  return (
    <div className={classes.mainContainer}>
      <div className={classes.imageContainer}>
      <img className={classes.smallBanner} src={menHealth} />
      <img className={classes.smallBanner} src={womanHealth} />
      <img className={classes.smallBanner} src={newYorkTimes} />
      <img className={classes.smallBanner} src={healthLine} />
      <img className={classes.largeBanner} src={liveStrong} />
      </div>
    </div>
  );
};

export default ProgramsSponsors;
