import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingTop: 79,
    paddingBottom: 79,
    paddingRight: 79,
    paddingLeft: 152,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: "109px",
    [theme.breakpoints.down('xs')]: {
      paddingTop: 31,
      gap: "0px",
      paddingBottom: 20,
      paddingRight: 20,
      paddingLeft: 20,
    },
  },

  title: {
    fontSize: 38,
    fontWeight: 900,
    lineHeight: 1.16,
    letterSpacing: 0.56,
    width: '473px',
    color: theme.palette.hunterGreen.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: 24,
      width: '100%',
      textAlign: 'center',
      lineHeight: '32px',
    },
  },
  dividerText: {
    fontSize: 18,
    fontWeight: 900,
    lineHeight: 1.56,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  text: {
    fontSize: 22,
    lineHeight: 1.56,
    // color: theme.palette.slateGrey.main,
    color: theme.palette.hunterGreen.main,
    paddingTop: '26px',
    maxWidth: 470,
    fontWeight: 600,
    // paddingBottom: 30,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      maxWidth: 'none',
      fontSize: 16,
      lineHeight: '28px',
    },
  },
  buttonText: {
    fontWeight: 'bold',
    fontSize: 18,
    color: 'white',
    lineHeight: 3.56,
    letterSpacing: 1.38,
  },
  button: {
    marginTop: '60px',
    height: 64,
    width: 240,
    borderRadius: 2,
    [theme.breakpoints.down('xs')]: {
      width : 305
      },
      '&:hover': {
        background: theme.palette.primary.mainDark,
      },
  },
  divider: {
    width: '100%',
    'border-bottom': `1px solid ${theme.palette.primary.main}`,
  },
  image: {
    width: '609px',
    height: '523px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    [theme.breakpoints.down('xs')]: {
      width: '294px',
      height: '285px',
      marginTop: 40,
    },
  },
  buttonContainer: {
   
    [theme.breakpoints.down('xs')]: {
    display : "flex",
    alignItems : "center",
    justifyContent : "center"
    },
  },
}));

const ProgramsHaveYouTried = ({
  title,
  descriptionOne,
  descriptionTwo,
  descriptionThree,
  isButton,
  img,
  buttonText,
  onClick={onClick}

}) => {
  const classes = useStyles();
  return (
    <Box
      display='flex'
      flexDirection={{
        xs: 'column',
        sm: 'row',
      }}
      px={{
        xs: 2,
        sm: 0,
      }}
      className={classes.mainContainer}
    >
      <Box>
        <Typography className={classes.title} variant='h4'>
          {title}
        </Typography>
        <Typography className={classes.text} variant='body1'>
          {descriptionOne}
        </Typography>
        <Typography className={classes.text} variant='body1'>
          {descriptionTwo}
        </Typography>
        <Typography className={classes.text} variant='body1'>
          {descriptionThree}
        </Typography>
        {isButton == true ? (
          <Box className={classes.buttonContainer}>
            {/* <a href='https://app.movementvault.com/auth/signup'> */}
              <Button
                variant='contained'
                color='primary'
                className={classes.button}
                onClick={onClick}

              >
                <Typography className={classes.buttonText} variant='body1'>
                {/* TRY FOR FREE */}
                {buttonText}
                </Typography>
              </Button>
            {/* </a> */}
          </Box>
        ) : null}
      </Box>
      <Box
      // display='flex'
      // alignItems='center'
      // width={{
      //   xs: '100%',
      //   sm: '50%',
      // }}
      >
        {/* <img
          src={img}
          alt='Women performing a shoulder stretch including a lat foam rolling exercise'
          className={classes.image}
        /> */}
        <div style={{  backgroundImage: `url(${img})`  }} className={classes.image} />
      </Box>
    </Box>
  );
};

export default ProgramsHaveYouTried;
