import React from 'react';
import { withStyles } from '@material-ui/styles';

import Layout from './Layout';
import { Dialog } from '@material-ui/core';


const styles = ({ breakpoints, palette: { primary } }) => ({
  popup: {
    backgroundColor: '#00000080'
  },
  overlay: {
    backdropFilter: 'blur(10px)',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    [breakpoints.down('xs')]: {
      left: 0,
      width: '100%',
    },
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    paddingTop: 100,
    top: 0,
    left: 0,
    width: '100%',
    [breakpoints.down('xs')]: {
      width: '100%',
      left: 0,
    },
  },
  menuContainer: {
    [breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  menuItem: {
    background: "#FFFFFFE5",
    borderRadius: 6,
    color: primary.generalText,
    display: 'flex',
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 16,
    padding: '20px 11px',
    minWidth: 'min(460px, 100%)',
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  menuItemBody: {
    '& > img': {
      marginLeft: '1rem',
      height: 20,
      width: 20,
    },
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
  },
  menuItemIcon: {
    alignItems: 'center',
    background: primary.main,
    borderRadius: 24,
    display: 'flex',
    height: 48,
    justifyContent: 'center',
    width: 48,
    minWidth: 48,
    marginRight: '1rem',
  },
  title: {
    color: '#ffffff',
    fontSize: 32,
    fontWeight: 'bold',
    marginBottom: 24,
    [breakpoints.down('xs')]: {
      width: 'auto',
      alignSelf: 'flex-start',
      paddingLeft: '10%',
    },
  },
});

const Home = ({ classes, menuItems, onClose }) => {
  const MenuItem = ({ data: { image, link, label } }) => (
    <a href={`${link}`} style={{ textDecoration: 'none' }}>
      <div className={classes.menuItem}>
        <div className={classes.menuItemIcon}>
          <img src={image} alt={label} />
        </div>
        <div className={classes.menuItemBody}>
          <div>{label}</div>
        </div>
      </div>
    </a>
  );

  return (
    <Dialog 
        open={menuItems.length}
        fullScreen
        
        PaperProps={{
          className: classes.popup,
          onClick:onClose
        }}
    >   
    <Layout>
      <div className={classes.overlay} />
      <div className={classes.container}>
        <div className={classes.title}>Programs</div>
        <div className={classes.menuContainer}>
          {menuItems.map((menuItem) => (
            <MenuItem key={menuItem.link} data={menuItem} />
          ))}
        </div>
      </div>
    </Layout>
    </Dialog>
  );
};

export const SubMenuPopup = withStyles(styles)(Home);
