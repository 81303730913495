
import cx from 'classnames';

import { Creators  } from 'modules/dashboard/payment/reducer';
import { Creators as CouponCreators } from "modules/admin/dashboard/membership/coupons/reducer";
import { push as pushTo } from 'connected-react-router';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

import React, { useEffect, useState } from 'react';
import { Box,  NoSsr, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Navbar from './Navbar';
import NewFooter from './NewFooter';
import { PageSeoTags } from './SeoTags';
import ReactPlayer from 'react-player';
import ProgramsHeader from './ProgramsHeader';
import ProgramsSponsors from './ProgramsSponsors';
import HowMovmentVaultWorks from './HowMovmentVaultWorks';
import ProgramsWillHelpYou from './ProgramsWillHelpYou';
import ProgramsUsersPercentage from './ProgramsUsersPercentage';
import ProgramsDoctor from './ProgramsDoctor';
import ProgramsHaveYouTried from './ProgramsHaveYouTried';
import ProgramsHelped from './ProgramsHelped';
import MobilityScore from './MobilityScore';
import ReadyToUnlock from './ReadyToUnlock';
import Rehab from './Rehab';
import Consistent from './Consistent';
import {Review} from './Review';
import freeClassAllDevices from '../../../assets/images/free-class-all-devices.png';
import freeclassgraysonsterchingphonevertical from '../../../assets/images/free-class-grayson-sterching-phone-vertical.png';
import howmovmentvaultworks from '../../../assets/images/howmovmentvaultworks.png';
import freeclassmaurostrentching from '../../../assets/images/free-class-mauro-strentching.png';
import { Button } from '@material-ui/core';
import backgroundImage from '../../../assets/images/img-background@2x.jpg';


import freeclassmeandcatherine from "../../../assets/images/free-class-me-and-catherine.png"
import doublePhone from "../../../assets/images/doublePhone.png"
import howmovmentvaultwokrsphone from "../../../assets/images/howmovmentvaultwokrsphone.png"
import PricingNavbar from './PricingNavbar';
import { useParams } from 'react-router-dom';
import { BASE_URL, TOKEN } from 'core/constants';
import * as localStorage from 'core/utils/localStorage';









const styles = (theme) => ({
  header: {
    height: 768,
    marginTop: -88,
    // backgroundImage: `url('../static/images/image-home-banner.jpg')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('xs')]: {
      height: 480,
      // height: 520,
      paddingTop: 88,
    },
    [theme.breakpoints.down('sm')]: {
      height: 500,
      // height: 520,
      paddingTop: 88,
    },
  },
  title: {
    color: 'white',
    fontSize: 44,
    fontWeight: 900,
    letterSpacing: 0.65,
    lineHeight: 1.27,
    padding: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: 26,
      paddingTop: '10px',
      padding: 0,
    },
    textAlign: 'center',
  },
  titleFirst: {
    color: 'white',
    fontSize: 44,
    fontWeight: 900,
    letterSpacing: 0.65,
    lineHeight: 1.27,
    padding: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: 26,
      paddingTop: '2px',
      padding: 0,
    },
    textAlign: 'center',
  },
  subtitle: {
    color: 'white',
    fontSize: 24,
    letterSpacing: 0.65,
    // paddingTop: 21,
    paddingBottom: 112,
    marginTop: '90px',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 10,
      paddingTop: 20,
      fontSize: 18,
      marginTop: '0px',
    },
    textAlign: 'center',
  },
  subtitleDesktop: {
    color: 'white',
    fontSize: 24,
    letterSpacing: 0.65,
    // paddingTop: 21,
    paddingBottom: 112,
    marginTop: '40px',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 10,
      paddingTop: 18,
      fontSize: 18,
      marginTop: '0px',
    },
    textAlign: 'center',
  },
  startButton: {
    height: 64,
    width: 312,
    borderRadius: 2,
    [theme.breakpoints.down('xs')]: {
      height: 56,
      width: 260,
      marginBottom: '-35px',
    },
    [theme.breakpoints.down('sm')]: {
      height: 56,
      width: 260,
      // marginBottom : "-32px",
      marginBottom: '-8px',
    },
  },
  startButtonText: {
    fontWeight: 'bold',
    fontSize: 18,
    color: 'white',
    lineHeight: 3.56,
    letterSpacing: 1.38,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  divider: {
    'border-bottom': `1px solid ${theme.palette.primary.main}`,
    marginTop: '36px',
  },
  pitchIcon: {
    paddingTop: 25,
  },
  pitchTitle: {
    paddingTop: 36,
    paddingBottom: 30,
    fontSize: 38,
    fontWeight: 900,
    lineHeight: 1.16,
    letterSpacing: 0.56,
    color: theme.palette.hunterGreen.main,
    wordSpacing: 500,
    'word-wrap': 'break-word',
    [theme.breakpoints.down('xs')]: {
      fontSize: 24,
      textAlign: 'center',
      wordSpacing: 0,
      'word-wrap': 'normal',
    },
  },
  pitchTitleImprove: {
    paddingTop: 36,
    paddingBottom: 30,
    fontSize: 38,
    fontWeight: 900,
    lineHeight: 1.16,
    letterSpacing: 0.56,
    width: '600px',
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.hunterGreen.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: 24,
      textAlign: 'center',
      width: '100%',
    },
  },
  pitchSubtitle: {
    fontSize: 18,
    lineHeight: 1.56,
    color: '#585963',
    maxWidth: 320,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      maxWidth: 'none',
      paddingLeft: '5%',
      paddingRight: '5%',
    },
  },
  fixYourPainPitchSubtitle: {
    fontSize: 18,
    lineHeight: 1.56,
    color: '#585963',
    maxWidth: 400,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      maxWidth: 'none',
      paddingLeft: '5%',
      paddingRight: '5%',
      maxWidth: 320,
    },
  },
  subHeader: {
    backgroundColor: theme.palette.hunterGreen.main,
  },
  subheaderBoxTitle: {
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 30,
    paddingRight: 30,
    fontSize: 30,
    fontWeight: 900,
    lineHeight: 1.2,
    letterSpacing: 0.56,
    color: 'white',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 2,
    maxWidth: 500,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontSize: 24,
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  subheaderText: {
    fontSize: 18,
    lineHeight: 1.56,
    color: 'white',
    maxWidth: 560,
    marginRight: '30px',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontSize: 16,
      lineHeight: 1.63,
      marginRight: '0px',
    },
  },
  howItWorksTitle: {
    maxWidth: 500,
    fontSize: 38,
    fontWeight: 900,
    lineHeight: 1.16,
    letterSpacing: 0.56,
    color: theme.palette.hunterGreen.main,
  },
  color: {
    color: 'white',
  },
  customMobility: {
    maxWidth: 500,
    fontSize: 38,
    fontWeight: 900,
    lineHeight: 1.16,
    letterSpacing: 0.56,
    color: 'white',
  },
  customRoutinesTitle: {
    maxWidth: 'none',
    paddingBottom: 25,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontSize: 24,
    },
  },
  featuredIn: {
    maxWidth: 'none',
    paddingBottom: 25,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
      fontSize: 24,
      paddingBottom: 4,
    },
  },
  hearFromOurUsersTitle: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  customRoutinesText: {
    maxWidth: 'none',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontSize: 16,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  reactPlayerRoot: {
    paddingLeft: '15%',
    paddingRight: '15%',
    paddingBottom: 80,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  },
  downloadMovementVaultContainer: {
    paddingLeft: '10vw',
    paddingRight: '10vw',
    paddingBottom: 60,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '3vw',
      paddingRight: '3vw',
    },
  },
  rehab: {
    marginLeft: 0,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  bodyAssessmentBlock: {
    backgroundColor: '#f7f8fa',
  },
  forEveryBody: {
    marginLeft: '25px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0px',
    },
  },
  mobilityBanner: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url('../static/images/circle-banner.png')`,
    backgroundPosition: '0px 100%',
    backgroundSize: '380px 300px',
    backgroundRepeat: 'no-repeat',
  },
  mobilityVideosText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  mobileView: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
    },
  },
  desktopView: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  painRehabDesktop: {
    marginTop: '10px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '0px',
    },
  },
  painRehabMobile: {
    marginTop: '0px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '10px',
    },
  },
  anyTimeMobile: {
    marginTop: '0px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '10px',
    },
  },
  mainText: {
    marginTop: '100px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '0px',
    },
  },
  ourStretch: {
    marginLeft: '140px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0px',
    },
  },
  titleForVideo: {
    fontSize: '38px',
    fontWeight: 900,
    diplay: 'flex',
    justifyContet: 'center',
    textAlign: 'center',
    paddingTop: '20px',
    // width : "907px",
    [theme.breakpoints.down('xs')]: {
      fontSize: '32px',
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  },
  reactPlayerRoot: {
    diplay: 'flex',
    justifyContet: 'center',
    textAlign: 'center',
    alignItems: 'center',
    paddingLeft: '15%',
    paddingRight: '15%',
    paddingBottom: 80,
    paddingTop: 75,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  },

  header1: {
    height: 603,
    // marginTop: -88,
    // backgroundImage: `url('../../../assets/images/img-background@2x.jpg')`,
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: '0px 100%',
    backgroundRepeat: 'no-repeat',
    padding: 151,
    justifyContent: 'center',
    gap: "109px",
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      height: "100%",
      paddingTop : 60,
      paddingBottom : 74,
      paddingLeft : 20,
      paddingRight : 20,
      gap : "61px",
    },
  },
  headerTitle1: {
    color: 'white',
    fontFamily: 'Lato',
    fontSize: '38px',
    fontStyle: 'normal',
    fontWeight: 900,
    lineHeight: '52px',
    letterSpacing: '0.8px',
    textTransform: 'uppercase',
    width: '700px',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '390px',
    },
  },
  headerDescription1: {
    color: 'white',
    fontFamily: 'Lato',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '28px',
    marginTop: '16px',
  },
  buttonText1: {
    fontWeight: 'bold',
    fontSize: 18,
    color: 'white',
    lineHeight: 3.56,
    letterSpacing: 1.38,
  },
  buttonContainer1: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  button1: {
    marginTop: '32px',
    height: 64,
    width: 348,
    borderRadius: 2,
    [theme.breakpoints.down('xs')]: {
      width: 305,
    },
    '&:hover': {
      background: theme.palette.primary.mainDark,
    },
  },
  bottomLine1: {
    background: '#28AAE2',
    height: '8px',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  image1: {
    width: 386,
    height: 255,
    backgroundRepeat: 'no-repeat',
    objectFit: 'fit',
    backgroundSize: 'contain',
    // backgroundImage :`url('../static/images/ashley-trap-ball.png')`,
    [theme.breakpoints.down('xs')]: {
      height: 217,
      width: 327,
      // marginTop: 50,
    },
  },
  centeredText1: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },

  planMainContainer2: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  tst2: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '25px',
  },

  currentPlanContainer2: {
    padding: '5px',
    // maxWidth: "100%",
    // maxHeight: "100%",
    // width: "100%",
    // height: "100%",
    backgroundColor: 'gray',
    color: '#FFFFFF',
    // position: 'absolute',
    // marginLeft : "200px",
    position: 'absolute',
    marginTop: '-97px',
    border: '3px solid gray',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      marginTop: '-88px',
      // marginLeft : "220px",
    },
  },

  tst1: {
    paddingTop: '6px',
    paddingBottom: '6px',
    paddingLeft: '18px',
    paddingRight: '18px',
    // maxWidth: "100%",
    // maxHeight: "100%",
    // width: "100%",
    // height: "100%",
    backgroundColor: '#28AAE2',
    color: '#FFFFFF',
    // position: 'absolute',
    // marginLeft : "200px",
    position: 'absolute',
    marginTop: '-144px',
    border: '4px solid #28AAE2',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '15px',
    fontWeight: 700,
    marginRight: 215,
    width: 195,
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      marginTop: '-107px',
      // marginLeft : "220px",
      marginRight : 0,
    },
  },


  tst30: {
    paddingTop: '6px',
    paddingBottom: '6px',
    paddingLeft: '18px',
    paddingRight: '18px',
    // maxWidth: "100%",
    // maxHeight: "100%",
    // width: "100%",
    // height: "100%",
    backgroundColor: '#28AAE2',
    color: '#FFFFFF',
    // position: 'absolute',
    // marginLeft : "200px",
    position: 'absolute',
    marginTop: '-144px',
    border: '4px solid #28AAE2',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '15px',
    fontWeight: 700,
    marginRight: 40,
    width: 195,
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      marginTop: '-107px',
      // marginLeft : "220px",
      marginRight : 0,
    },
  },

  tst9: {
    paddingTop: '6px',
    paddingBottom: '6px',
    paddingLeft: '18px',
    paddingRight: '18px',
    // maxWidth: "100%",
    // maxHeight: "100%",
    // width: "100%",
    // height: "100%",
    backgroundColor: '#28AAE2',
    color: '#FFFFFF',
    // position: 'absolute',
    // marginLeft : "200px",
    position: 'absolute',
    marginTop: '-144px',
    border: '4px solid #28AAE2',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '15px',
    fontWeight: 700,
    marginRight: 37,
    width: 195,
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      marginTop: '-108px',
      marginRight : "0px",
    },
  },

  planWithoutPromo2: {},

  plan2: {
    backgroundColor: 'white',
    // maxWidth: "100%",
    height: '128px',
    width: '522px',
    // padding: '15px 12px 15px 21px',
    paddingLeft: 45,
    paddingRight: 45,
    paddingTop: 39,
    paddingBottom: 39,
    // padding : "12px 10px 12px 18px",
    //   height: "88px",
    // borderRadius: 6,
    // margin: '0px 12px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25);',
    //   boxShadow: "0 8px 16px 2px rgba(15, 18, 26, 0.08)",
    cursor: 'pointer',
    // position: 'relative',
    marginTop: 24,
    // borderColor : "#000000",
    borderRadius: '20px',
    borderStyle: 'solid',
    display: 'flex',
    alignItems: 'center',
    borderWidth: '8px',
    borderColor: '#28AAE2',
    [theme.breakpoints.down('md')]: {
      height: 66,
      width: '343px',
      padding: '12px 10px 12px 18px',
      height: '95px',
    },
  },
  unclickable2: {
    cursor: 'default',
  },

  interval2: {
    // padding: '0px 48px',
    color: '#020401',
    // fontSize: 16,
    // marginTop: 24,
    width: '100%',
    fontWeight: 600,
    textTransform: 'capitalize',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      // padding: '0px 24px',
      textAlign: 'center',
      width: '100%',
      fontSize: 12,
    },
  },

  yearContainer2: {
    display: 'flex',
    flexDirection: 'column',
  },
  saleLine2: {
    // color: "#000000",
    // width: "66px",
    // border: "1px solid #000000",
    // position : "absolute",
    // marginTop : "-8px",
    // borderWidth : "0.3px",
    // [theme.breakpoints.down('xs')]: {
    //     marginTop : "-7px",
    //     width: "44px",
    //     // marginLeft : "220px",
    // },
    // [theme.breakpoints.down('md')]: {
    //     width : "55px",
    //     marginTop : "-7px",

    //   },
    textDecoration: 'line-through',
  },

  monthGap2: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
      // alignItems : "center",
      flexDirection: 'column',
    },
  },

  planTrialDuration2: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '20px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },

  boldText2: {
    fontWeight: 800,
    fontSize: '20px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },

  prices2: {
    fontSize: '20px',
    display: 'flex',
    paddingTop: '8px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
      paddingTop: '5px',
    },
  },

  pricing2: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'lowercase',
    // paddingLeft : "54px",
    fontSize: '16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
      paddingLeft: '0px',
    },
  },

  mainContainer5: {
    background:
      'linear-gradient(90deg, rgba(40, 170, 226, 1) 0%, rgba(35, 108, 206, 1) 100%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingTop: '61px',
    paddingBottom: '81px',
    [theme.breakpoints.down('xs')]: {},
  },
  title5: {
    fontSize: 38,
    fontWeight: 900,
    color: '#FFFFFF',
    [theme.breakpoints.down('xs')]: {
      fontSize: 24,
      paddingLeft : "15px",
      paddingRight : "15px"
    },
  },
  description5: {
    fontSize: 22,
    fontWeight: 400,
    color: '#FFFFFF',
    paddingTop: 46,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      paddingLeft : "15px",
      paddingRight : "15px"
    },
  },
  button5: {
    height: 64,
    width: 240,
    borderRadius: 2,
  },
  buttonText5: {
    fontWeight: 'bold',
    fontSize: 18,
    color: theme.palette.hunterGreen.main,
    lineHeight: 3.56,
    letterSpacing: 1.38,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },

  alo: {
    marginTop: 22,
  },
  mob: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
});

// background-size: 380px 300px;
// background-color: #28aae2;
// background-image: url(../static/images/circle-banner.png);
// background-position: 0px 100%;

const ClaimDiscount = ({ onFullscreenStatusChange, getPaymentPlans,plans,classes,couponId,data,getCoupons ,  filter,setFilter}) => {



  


  const { currentPage, statusFilter, searchTerm } = filter;

  const filterData = () => {
    return data.filter((item) => {
      return (
        (searchTerm
          ? (item['code'] || '')
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          : true) && (statusFilter ? item.status === statusFilter : true)
      );
    });
  };

  useEffect(() => {
    const unsorted = filterData();
    setCoupons(unsorted.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);


  console.log(plans, 'BUBU');
  const { planId, couponId1 } = useParams();
  const [coupons, setCoupons] = useState(data);

  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(0);
  const [value, setValue] = React.useState(0);
  const [couponResponse, setCouponResponse] = useState(null);

  const [couponCode, setCopounCode] = useState(couponId1 || "");


  console.log(333333,couponCode)


  console.log(planId ,couponId1,couponCode,"BABAJANANANANA")


  


  useEffect(() => {
    console.log('DATA11111 Fetching coupons...');

    getCoupons();
    console.log('DATA11111 Fetching coupons..222222.');

  }, [getCoupons]);

  useEffect(() => {
    getPaymentPlans();
  }, [getPaymentPlans]);

  useEffect(() => {
    setCoupons(data);
  }, [data]);

  console.log(coupons,"DATA11111")




  // NIKAia Lelievre30 eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImtqYWhqa2FrZEBhc2psZGhhc2tkLmFzZCIsImlkIjoiZTk4NzU2YTAtYTZiOC00NzJjLTk1YzQtYzUyZDNmZDI1YWRkIiwicmVtZW1iZXJNZSI6ZmFsc2UsImlhdCI6MTczMjA5ODQ4NCwiZXhwIjoxNzMyMTg0ODg0fQ.cfyxy-0I1gA8S5pwmkNMEfeWngJ1roa9dPk0bnhOZ3M Token



  async function checkCouponCode(couponCode) {
    // const token = localStorage.setItem(TOKEN,couponCode);
    // localStorage.setItem("Token", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImtqYWhqa2FrZEBhc2psZGhhc2tkLmFzZCIsImlkIjoiZTk4NzU2YTAtYTZiOC00NzJjLTk1YzQtYzUyZDNmZDI1YWRkIiwicmVtZW1iZXJNZSI6ZmFsc2UsImlhdCI6MTczMjA5ODQ4NCwiZXhwIjoxNzMyMTg0ODg0fQ.cfyxy-0I1gA8S5pwmkNMEfeWngJ1roa9dPk0bnhOZ3M")
    const token = localStorage.getItem("Token");
    console.log("NIKAia" , couponCode, token, TOKEN)

    try {
      const response = await fetch(
        `${BASE_URL}/coupon/stripe/check/${couponCode}`,
        {
          method: 'get',
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response,"RESP")
      const body = await response.json();
      console.log(body,"BODY")

      if (response.ok) {
        const codeIncludesPlan = body.membershipPlans.find((plan) => {
          return plan.code == selectedPlan.code;
        });

        // if (body.membershipPlans.length > 0 && !codeIncludesPlan) {
        //   return setCouponResponse({
        //     data: null,
        //     errorMessage: 'Coupon code does not apply to the selected plan',
        //   });
        // }
        setCouponResponse({
          data: body,
          errorMessage: null,
        });
      } else {
        setCouponResponse({
          data: null,
          errorMessage: body.message,
        });
      }
    } catch (e) {
      setCouponResponse({
        data: null,
        errorMessage: e,
      });
    }
  }


  useEffect(() => {
    if (couponId1) {
      console.log("datoia" , couponCode)

      checkCouponCode(couponId1);
    }
  }, [couponId1]);

  console.log(couponResponse,55555)

  const amount = couponResponse?.data?.amount;  // "15"
const numericAmount = parseInt(amount);  // 15

console.log(numericAmount,"VAAa")

  // let title = 'Movement Vault - The #1 app for stretching and mobility';
  // let description =
  //   'Try Movement Vault for free. 1000+ short and effective stretching and mobility video routines. Improve mobility, alleviate pain, prevent injury.';

  const TabPanel = ({ children, value, index, ...other }) => {
    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && <div>{children}</div>}
      </div>
    );
  };

  const getIntervalName = ({
    billingType,
    interval,
    intervalCount,
    intervalType,
  }) => {
    if (billingType === 'once') {
      return 'single payment';
    }

    if (interval === 'year') {
      return 'yearly';
    }
    if (interval === 'month') {
      return 'monthly';
    }
    if (interval === 'week') {
      return 'weekly';
    }
    if (interval === '3months') {
      return '3 months';
    }
    if (interval === '6months') {
      return '6 months';
    }

    if (interval === 'custom') {
      if (parseInt(intervalCount) === 1) {
        if (intervalType === 'day') {
          return 'daily';
        }
        if (intervalType === 'month') {
          return 'monthly';
        }
        if (intervalType === 'year') {
          return 'yearly';
        }
      }
      return `${intervalCount} ${intervalType}s`;
    }
  };

  // const handlePlanClick = async (code) => {
  //   window.location.href = `${WEB_APP_URL}auth/signup/${code}`;
  // };

  const handleClickLink = () => {
    window.location.href = `https://app.movementvault.com/auth/signup/price/${planId}/discount-code/${couponId1}`  };
    // window.location.href = `http://localhost:3000/auth/signup/price/${planId}/discount-code/${couponId1}`  };


    // http://localhost:3001/auth/signup/price_1Lz6deFI7NIXOnaQP1NUTcPV

    // https://app.movementvault.com/auth/signup/price/price_1Lz6deFI7NIXOnaQP1NUTcPV/discount-code/AARONMV20


  // const handleClickLink = window.location.href = `https://app.movementvault.com/auth/signup/price_1Lz6deFI7NIXOnaQP1NUTcPV`

  const Plan = ({ isSelected, onClick, plan, index, hidePromo }) => {
    const isCurrentPlan = false;
    const price = Math.trunc(plan.pricePerMonth || plan.price) / 100;
    const planPrice = Math.trunc(plan.price) / 100;
    const priceForMostExpensivePlan =
      Math.trunc(plan.priceForMostExpensivePlan) / 100;

      console.log(plan,"KUTU")

    console.log(priceForMostExpensivePlan, 'BAG');

    const t = priceForMostExpensivePlan * numericAmount / 100

    const discountedPlanPrice = plan.price / 100 - t


    const monthlyPrice = (discountedPlanPrice / 12).toFixed(2);

    const monthlyAmount = priceForMostExpensivePlan - parseFloat(couponResponse?.data.amount)

    const monthlyPriceAmount = (monthlyAmount / 12).toFixed(0)


    return (
      <div
        onClick={handleClickLink}
        className={classes.planMainContainer2}
      >
        {isCurrentPlan || plan.promoText ? (
          <div className={classes.tst2}>
            <div
              className={
                isCurrentPlan ? classes.currentPlanContainer2 : classes.tst1
              }
            >
              <div style={{ fontWeight: 700 }}>
                {isCurrentPlan ? 'Current plan' : ''}
                {isCurrentPlan && plan.promoText ? ': ' : ''}
                {plan.promoText} 
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div
          className={cx(
            classes.plan2,

            plan.promoText ? '' : classes.planWithoutPromo2,

            isCurrentPlan ? classes.unclickable2 : ''

            // isSelected ? classes.planSelected : ''
          )}
          onClick={isCurrentPlan ? () => {} : onClick}
        >
          <div className={cx(classes.interval2)}>
            <div className={classes.yearContainer2}>
              <div className={classes.monthGap2}>
                <div className={classes.planTrialDuration2}>
                  <span className={classes.boldText2}> {plan.interval === "month" ? "Monthly Subscription" : "Yearly Subscription" } </span>
                </div>
                {/* <b/> */}
                <div className={classes.prices2}>
                  {price !== planPrice ? (
                    <div>
                      <span className={classes.saleLine2}>
                        ${priceForMostExpensivePlan}
                      </span>
                      <span
                        className={classes.boldText2}
                        style={{ marginLeft: '18px' }}
                      >
                        {/* {plan.price / 100 - t} / */}
                        {/* {discountedPlanPrice} / {" "} */}
                        {/* ${Math.round(discountedPlanPrice)}  / {" "} */}

                      {couponResponse?.data.type === 'percent' ? `$ ${Math.round(discountedPlanPrice)} / ` : null }
                        {couponResponse?.data.type === 'amount' ? `$ ${priceForMostExpensivePlan - parseFloat(couponResponse?.data.amount) }  / ` : null }

                        {/* {plan.intervalCount > 1 ? `${plan.intervalCount} ` : ''} */}
                        {getIntervalName(plan) === 'yearly' ? 'year' : 'month'}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            

                  {plan.interval === "month" ? null : <div className={classes.pricing2}>
              <span className={classes.boldText2}>
                $ {couponResponse?.data.type === 'percent' ? monthlyPrice : null } {couponResponse?.data.type === 'amount' ? monthlyPriceAmount : null }  {plan.billingType === 'once' ? '' : '/ month'}
              </span>{' '}
            </div> }
           
          </div>
        </div>
      </div>
    );
  };


  const Plan2 = ({ isSelected, onClick, plan, index, hidePromo }) => {
    const isCurrentPlan = false;
    const price = Math.trunc(plan.pricePerMonth || plan.price) / 100;
    const planPrice = Math.trunc(plan.price) / 100;
    const priceForMostExpensivePlan =
      Math.trunc(plan.priceForMostExpensivePlan) / 100;

      console.log(plan,"KUTU")

    console.log(priceForMostExpensivePlan, 'BAG');

    const t = priceForMostExpensivePlan * numericAmount / 100

    const discountedPlanPrice = plan.price / 100 - t


    const monthlyPrice = (discountedPlanPrice / 12).toFixed(2);

    const monthlyAmount = priceForMostExpensivePlan - parseFloat(couponResponse?.data.amount)

    const monthlyPriceAmount = (monthlyAmount / 12).toFixed(0)


    return (
      <div
        onClick={handleClickLink}
        className={classes.planMainContainer2}
      >
        {isCurrentPlan || plan.promoText ? (
          <div className={classes.tst2}>
            <div
              className={
                isCurrentPlan ? classes.currentPlanContainer2 : classes.tst30
              }
            >
              <div style={{ fontWeight: 700 }}>
                {isCurrentPlan ? 'Current plan' : ''}
                {isCurrentPlan && plan.promoText ? ': ' : ''}
                {plan.promoText} 
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div
          className={cx(
            classes.plan2,

            plan.promoText ? '' : classes.planWithoutPromo2,

            isCurrentPlan ? classes.unclickable2 : ''

            // isSelected ? classes.planSelected : ''
          )}
          onClick={isCurrentPlan ? () => {} : onClick}
        >
          <div className={cx(classes.interval2)}>
            <div className={classes.yearContainer2}>
              <div className={classes.monthGap2}>
                <div className={classes.planTrialDuration2}>
                  <span className={classes.boldText2}> {plan.interval === "month" ? "Monthly Subscription" : "Yearly Subscription" } </span>
                </div>
                {/* <b/> */}
                <div className={classes.prices2}>
                  {price !== planPrice ? (
                    <div>
                      <span className={classes.saleLine2}>
                        ${priceForMostExpensivePlan}
                      </span>
                      <span
                        className={classes.boldText2}
                        style={{ marginLeft: '18px' }}
                      >
                        {/* {plan.price / 100 - t} / */}
                        {/* {discountedPlanPrice} / {" "} */}
                        {/* ${Math.round(discountedPlanPrice)}  / {" "} */}

                        {couponResponse?.data.type === 'percent' ? `$ ${Math.round(discountedPlanPrice)} / ` : null }
                        {couponResponse?.data.type === 'amount' ? `$ ${priceForMostExpensivePlan - parseFloat(couponResponse?.data.amount) }  / ` : null }

                        {/* {plan.intervalCount > 1 ? `${plan.intervalCount} ` : ''} */}
                        {getIntervalName(plan) === 'yearly' ? 'year' : 'month'}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            

                  {plan.interval === "month" ? null : <div className={classes.pricing2}>
              <span className={classes.boldText2}>
                $ {couponResponse?.data.type === 'percent' ? monthlyPrice : null } {couponResponse?.data.type === 'amount' ? monthlyPriceAmount : null } {plan.billingType === 'once' ? '' : '/ month'}
              </span>{' '}
            </div> }
           
          </div>
        </div>
      </div>
    );
  };

  const Plans = ({ plans }) => {
  

    console.log(plans,"BUBU1")

const yearlyPlans = plans.filter(plan => plan.code === planId);
let mostExpensivePlan = 0;


const plansToRender = yearlyPlans
  .map((plan) => {
    const newPlan = { ...plan };

    let divider = 12; 

    newPlan.pricePerMonth = newPlan.price / divider;

    if (newPlan.pricePerMonth > mostExpensivePlan) {
      mostExpensivePlan = newPlan.pricePerMonth;
    }

    return newPlan;
  })
  .map((plan) => {
    let multiplier = 12;

  

    console.log(mostExpensivePlan * multiplier , "KOKO")
    const priceForMostExpensivePlan = mostExpensivePlan * multiplier;
    console.log(priceForMostExpensivePlan,"KASHIA")

    const priceForMostExpensivePlan1 = 11999 + 5989

    return {
      ...plan,
      priceForMostExpensivePlan,
    };
  }

      );
    

// const yearlyPlans = plans.filter(plan => plan.code === planId);
// let mostExpensivePlan = 0;


// const plansToRender = yearlyPlans?.map((plan) => {
//     if (plan.billingType == 'once') {
//       return plan;
//     }
//     const newPlan = {
//       ...plan,
//     };
//     let divider = 1;
//     if (plan.interval == 'year') {
//       divider *= 12;
//     }
//     if (plan.intervalCount > 0) {
//       divider *= plan.intervalCount;
//     }
//     newPlan.pricePerMonth = newPlan.price / divider;
//     if (mostExpensivePlan < newPlan.pricePerMonth) {
//       mostExpensivePlan = newPlan.pricePerMonth;
//     }
//     return newPlan;
//   })
//   .map((plan) => {
//     let multiplier = 1;
//     if (plan.interval == 'year') {
//       multiplier *= 12;
//     }
//     if (plan.intervalCount > 0) {
//       multiplier *= plan.intervalCount;
//     }
//     const priceForMostExpensivePlan = mostExpensivePlan * multiplier;
//     return {
//       ...plan,
//       priceForMostExpensivePlan,
//     };
//   });

// const yearlyPlans = plans.filter(plan => plan.code === planId);

// let mostExpensivePlan = 0;

// // Process yearlyPlans instead of plans
// const plansToRender = yearlyPlans
//   .map((plan) => {
//     // Skip "once" billingType plans as before
//     if (plan.billingType === 'once') {
//       return plan;
//     }

//     const newPlan = {
//       ...plan,
//     };

//     // Calculate divider for monthly price
//     let divider = 1;
//     if (plan.interval === 'year') {
//       divider *= 12;
//     }
//     if (plan.intervalCount > 0) {
//       divider *= plan.intervalCount;
//     }

//     // Calculate monthly price
//     newPlan.pricePerMonth = newPlan.price / divider;

//     // Update mostExpensivePlan based on monthly price
//     if (mostExpensivePlan < newPlan.pricePerMonth) {
//       mostExpensivePlan = newPlan.pricePerMonth;
//     }

//     return newPlan;
//   })
//   .map((plan) => {
//     let multiplier = 1;

//     // Reverse the monthly price calculation to get the yearly price
//     if (plan.interval === 'year') {
//       multiplier *= 12;
//     }
//     if (plan.intervalCount > 0) {
//       multiplier *= plan.intervalCount;
//     }

//     const priceForMostExpensivePlan = mostExpensivePlan * multiplier;

//     return {
//       ...plan,
//       priceForMostExpensivePlan,
//     };
//   });






    return (
      <div className={classes.plans}>
        {plansToRender.map((plan, index) => (
          <Plan
            key={plan.code + index}
            plan={plan}
            index={index}
            hidePromo={true}
            isSelected={selectedPlan === plan.code}
            onClick={() => setSelectedPlan(plan.code)}
          />
        ))}
      </div>
    );
  };


  const Plan1 = ({ isSelected, onClick, plan, index, hidePromo }) => {
    const isCurrentPlan = false;
    const price = Math.trunc(plan.pricePerMonth || plan.price) / 100;
    const planPrice = Math.trunc(plan.price) / 100;
    const priceForMostExpensivePlan =
      Math.trunc(plan.priceForMostExpensivePlan) / 100;

      console.log(plan,"KUTU")

    console.log(priceForMostExpensivePlan, 'BAG');

    const t = priceForMostExpensivePlan * numericAmount / 100

    const discountedPlanPrice = plan.price / 100 - t


    const monthlyPrice = (discountedPlanPrice / 12).toFixed(2);


    return (
      <div
        onClick={handleClickLink}
        className={classes.planMainContainer2}
      >
        {isCurrentPlan || plan.promoText ? (
          <div className={classes.tst2}>
            <div
              className={
                isCurrentPlan ? classes.currentPlanContainer2 : classes.tst
              }
            >
              <div style={{ fontWeight: 700 }}>
                {isCurrentPlan ? 'Current plan' : ''}
                {isCurrentPlan && plan.promoText ? ': ' : ''}
                {plan.promoText} 
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div
          className={cx(
            classes.plan2,

            plan.promoText ? '' : classes.planWithoutPromo2,

            isCurrentPlan ? classes.unclickable2 : ''

            // isSelected ? classes.planSelected : ''
          )}
          onClick={isCurrentPlan ? () => {} : onClick}
        >
          <div className={cx(classes.interval2)}>
            <div className={classes.yearContainer2}>
              <div className={classes.monthGap2}>
                <div className={classes.planTrialDuration2}>
                  <span className={classes.boldText2}> {plan.interval === "month" ? "Monthly Subscription" : "Yearly Subscription" } </span>
                </div>
                {/* <b/> */}
                <div className={classes.prices2}>
                  {price !== planPrice ? (
                    <div>
                      <span className={classes.saleLine2}>
                        ${priceForMostExpensivePlan}
                      </span>
                      <span
                        className={classes.boldText2}
                        style={{ marginLeft: '18px' }}
                      >
                        {/* {plan.price / 100 - t} / */}
                        {/* {discountedPlanPrice} / {" "} */}
                        ${Math.round(discountedPlanPrice)}  / {" "}

                        {/* {plan.intervalCount > 1 ? `${plan.intervalCount} ` : ''} */}
                        {getIntervalName(plan) === 'yearly' ? 'year' : 'month'}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            

                  {plan.interval === "month" ? null : <div className={classes.pricing2}>
              <span className={classes.boldText2}>
                $ {monthlyPrice} {plan.billingType === 'once' ? '' : '/ month'}
              </span>{' '}
            </div> }
           
          </div>
        </div>
      </div>
    );
  };

  const Plans1 = ({ plans }) => {
  

    console.log(plans,"BUBU1")

const yearlyPlans = plans.filter(plan => plan.code === planId);
let mostExpensivePlan = 0;


const plansToRender = yearlyPlans
  .map((plan) => {
    const newPlan = { ...plan };

    let divider = 12; 

    newPlan.pricePerMonth = newPlan.price / divider;

    if (newPlan.pricePerMonth > mostExpensivePlan) {
      mostExpensivePlan = newPlan.pricePerMonth;
    }

    return newPlan;
  })
  .map((plan) => {
    let multiplier = 12;

  

    console.log(mostExpensivePlan * multiplier , "KOKO")
    const priceForMostExpensivePlan = mostExpensivePlan * multiplier;
    console.log(priceForMostExpensivePlan,"KASHIA")

    const priceForMostExpensivePlan1 = 11999 + 5989

    return {
      ...plan,
      priceForMostExpensivePlan,
    };
  }

      );
    

// const yearlyPlans = plans.filter(plan => plan.code === planId);
// let mostExpensivePlan = 0;


// const plansToRender = yearlyPlans?.map((plan) => {
//     if (plan.billingType == 'once') {
//       return plan;
//     }
//     const newPlan = {
//       ...plan,
//     };
//     let divider = 1;
//     if (plan.interval == 'year') {
//       divider *= 12;
//     }
//     if (plan.intervalCount > 0) {
//       divider *= plan.intervalCount;
//     }
//     newPlan.pricePerMonth = newPlan.price / divider;
//     if (mostExpensivePlan < newPlan.pricePerMonth) {
//       mostExpensivePlan = newPlan.pricePerMonth;
//     }
//     return newPlan;
//   })
//   .map((plan) => {
//     let multiplier = 1;
//     if (plan.interval == 'year') {
//       multiplier *= 12;
//     }
//     if (plan.intervalCount > 0) {
//       multiplier *= plan.intervalCount;
//     }
//     const priceForMostExpensivePlan = mostExpensivePlan * multiplier;
//     return {
//       ...plan,
//       priceForMostExpensivePlan,
//     };
//   });

// const yearlyPlans = plans.filter(plan => plan.code === planId);

// let mostExpensivePlan = 0;

// // Process yearlyPlans instead of plans
// const plansToRender = yearlyPlans
//   .map((plan) => {
//     // Skip "once" billingType plans as before
//     if (plan.billingType === 'once') {
//       return plan;
//     }

//     const newPlan = {
//       ...plan,
//     };

//     // Calculate divider for monthly price
//     let divider = 1;
//     if (plan.interval === 'year') {
//       divider *= 12;
//     }
//     if (plan.intervalCount > 0) {
//       divider *= plan.intervalCount;
//     }

//     // Calculate monthly price
//     newPlan.pricePerMonth = newPlan.price / divider;

//     // Update mostExpensivePlan based on monthly price
//     if (mostExpensivePlan < newPlan.pricePerMonth) {
//       mostExpensivePlan = newPlan.pricePerMonth;
//     }

//     return newPlan;
//   })
//   .map((plan) => {
//     let multiplier = 1;

//     // Reverse the monthly price calculation to get the yearly price
//     if (plan.interval === 'year') {
//       multiplier *= 12;
//     }
//     if (plan.intervalCount > 0) {
//       multiplier *= plan.intervalCount;
//     }

//     const priceForMostExpensivePlan = mostExpensivePlan * multiplier;

//     return {
//       ...plan,
//       priceForMostExpensivePlan,
//     };
//   });






    return (
      <div className={classes.plans}>
        {plansToRender.map((plan, index) => (
          <Plan2
            key={plan.code + index}
            plan={plan}
            index={index}
            hidePromo={true}
            isSelected={selectedPlan === plan.code}
            onClick={() => setSelectedPlan(plan.code)}
          />
        ))}
      </div>
    );
  };




  
  // const Plan1 = ({ isSelected, onClick, plan, index, hidePromo }) => {
  //   const isCurrentPlan = false;
  //   const price = Math.trunc(plan.pricePerMonth || plan.price) / 100;
  //   const planPrice = Math.trunc(plan.price) / 100;
  //   const priceForMostExpensivePlan =
  //     Math.trunc(plan.priceForMostExpensivePlan) / 100;

  //   // console.log(priceForMostExpensivePlan, 'BAG');

  //   return (
  //     <div
  //       onClick={handleClickLink}
  //       className={classes.planMainContainer2}
  //     >
  //       {isCurrentPlan || plan.promoText ? (
  //         <div className={classes.tst2}>
  //           <div
  //             className={
  //               isCurrentPlan ? classes.currentPlanContainer2 : classes.tst9
  //             }
  //           >
  //             <div style={{ fontWeight: 700 }}>
  //               {isCurrentPlan ? 'Current plan' : ''}
  //               {isCurrentPlan && plan.promoText ? ': ' : ''}
  //               {plan.promoText} regular price
  //             </div>
  //           </div>
  //         </div>
  //       ) : (
  //         <></>
  //       )}
  //       <div
  //         className={cx(
  //           classes.plan2,

  //           plan.promoText ? '' : classes.planWithoutPromo2,

  //           isCurrentPlan ? classes.unclickable2 : ''

  //           // isSelected ? classes.planSelected : ''
  //         )}
  //         onClick={isCurrentPlan ? () => {} : onClick}
  //       >
  //         <div className={cx(classes.interval2)}>
  //           <div className={classes.yearContainer2}>
  //             <div className={classes.monthGap2}>
  //               <div className={classes.planTrialDuration2}>
  //                 <span className={classes.boldText2}>{plan.interval === "month" ? "Monthly Subscription" : "Yearly Subscription" } </span>
  //               </div>
  //               {/* <b/> */}
  //               <div className={classes.prices2}>
  //                 {price !== planPrice ? (
  //                   <div>
  //                     <span className={classes.saleLine2}>
  //                       ${priceForMostExpensivePlan} 
  //                       {/* 179.88 */}
  //                     </span>
  //                     <span
  //                       className={classes.boldText2}
  //                       style={{ marginLeft: '18px' }}
  //                     >
                        
  //                       {plan.price / 100} /{' '}
  //                       {/* {plan.intervalCount > 1 ? `${plan.intervalCount} ` : ''} */}
  //                       {getIntervalName(plan) === 'yearly' ? 'year' : 'month'} 
  //                     </span>
  //                   </div>
  //                 ) : null}
  //               </div>
  //             </div>
  //           </div>

  //           {plan.interval === "month" ? null : <div className={classes.pricing2}>
  //             <span className={classes.boldText2}>
  //               $ {price} {plan.billingType === 'once' ? '' : '/ month'}
  //             </span>{' '}
  //           </div> }

  //           {/* <div className={classes.pricing2}>
  //             <span className={classes.boldText2}>
  //               $ {price} {plan.billingType === 'once' ? '' : '/ month'}
  //             </span>
  //           </div> */}
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // const Plans1 = ({ plans }) => {



  //   console.log(plans,"BUBU1")

  //   const yearlyPlans = plans.filter(plan => plan.code === planId);
  //   let mostExpensivePlan = 0;
    
    
  //   const plansToRender = yearlyPlans
  //     .map((plan) => {
  //       const newPlan = { ...plan };
    
  //       let divider = 12; 
    
  //       newPlan.pricePerMonth = newPlan.price / divider;
    
  //       if (newPlan.pricePerMonth > mostExpensivePlan) {
  //         mostExpensivePlan = newPlan.pricePerMonth;
  //       }
    
  //       return newPlan;
  //     })
  //     .map((plan) => {
  //       let multiplier = 12;
    
      
    
  //       console.log(mostExpensivePlan * multiplier , "KOKO")
  //       const priceForMostExpensivePlan = mostExpensivePlan * multiplier;
  //       console.log(priceForMostExpensivePlan,"KASHIA")
    
  //       const priceForMostExpensivePlan1 = 11999 + 5989
    
  //       return {
  //         ...plan,
  //         priceForMostExpensivePlan,
  //       };
  //     }
    
  //         );

  //   // console.log(plans, 'PLANEBII');
  //   // let mostExpensivePlan = 0;
  //   // const yearlyPlan = plans.filter((plan) => plan.code === planId);

  //   // const plansToRender = yearlyPlan
  //   //   .map((plan) => {
  //   //     if (plan.billingType == 'once') {
  //   //       return plan;
  //   //     }
  //   //     const newPlan = {
  //   //       ...plan,
  //   //     };
  //   //     let divider = 1;
  //   //     if (plan.interval == 'year') {
  //   //       divider *= 12;
  //   //     }
  //   //     if (plan.intervalCount > 0) {
  //   //       divider *= plan.intervalCount;
  //   //     }
  //   //     newPlan.pricePerMonth = newPlan.price / divider;
  //   //     if (mostExpensivePlan < newPlan.pricePerMonth) {
  //   //       mostExpensivePlan = newPlan.pricePerMonth;
  //   //     }
  //   //     return newPlan;
  //   //   })
  //   //   .map((plan) => {
  //   //     let multiplier = 1;
  //   //     if (plan.interval == 'year') {
  //   //       multiplier *= 12;
  //   //     }
  //   //     if (plan.intervalCount > 0) {
  //   //       multiplier *= plan.intervalCount;
  //   //     }
  //   //     const priceForMostExpensivePlan1 = mostExpensivePlan * multiplier;

  //   //     const priceForMostExpensivePlan = 11999 + 5989

  //   //     return {
  //   //       ...plan,
  //   //       priceForMostExpensivePlan,
  //   //     };
  //   //   });

  //   // const Plans = ({ plans }) => {
  //   //   console.log(plans, "PLANEBII");
  //   //   let mostExpensivePlan = 0;

  //   //   // Calculate the most expensive plan across all plans
  //   //   plans.forEach(plan => {
  //   //     if (plan.billingType === 'recurring') {
  //   //       let divider = 1;
  //   //       if (plan.interval === 'year') {
  //   //         divider *= 12;
  //   //       }
  //   //       if (plan.intervalCount > 0) {
  //   //         divider *= plan.intervalCount;
  //   //       }
  //   //       const pricePerMonth = plan.price / divider;
  //   //       if (mostExpensivePlan < pricePerMonth) {
  //   //         mostExpensivePlan = pricePerMonth;
  //   //       }
  //   //     }
  //   //   });

  //   //   const yearlyPlan = plans.filter(plan => plan.interval === "year");

  //   //   // Now use only the yearly plan with the pre-calculated most expensive plan value
  //   //   const plansToRender = yearlyPlan.map(plan => {
  //   //     let multiplier = 1;
  //   //     if (plan.interval === 'year') {
  //   //       multiplier *= 12;
  //   //     }
  //   //     if (plan.intervalCount > 0) {
  //   //       multiplier *= plan.intervalCount;
  //   //     }
  //   //     const priceForMostExpensivePlan = mostExpensivePlan * multiplier;
  //   //     return {
  //   //       ...plan,
  //   //       priceForMostExpensivePlan,
  //   //     };
  //   //   });

  //   return (
  //     <div className={classes.plans}>
  //       {plansToRender.map((plan, index) => (
  //         <Plan1
  //           key={plan.code + index}
  //           plan={plan}
  //           index={index}
  //           hidePromo={true}
  //           isSelected={selectedPlan === plan.code}
  //           onClick={() => setSelectedPlan(plan.code)}
  //         />
  //       ))}
  //     </div>
  //   );
  // };

  return (
    <div style={{backgroundColor : "white" , 
    // width : "1425px" 
    }} >
     <head>
        <title>#1 Stretching, Rehab, and Mobility app</title>

        <PageSeoTags
          title='#1 Stretching, Rehab, and Mobility app'
          description='Fix your pain with injury rehab programs. Improve flexibility and mobility with 1500+ video classes. Most effective online stretching and mobility program'
        />
      </head>

      <PricingNavbar
        transparent={false}
        tab='PROGRAMS'
        changeWhenScroll
        position='sticky'
        hasStartButton={true}
        isIndexPage
        onClick={handleClickLink}
      />


      <div>
        <Box
          display='flex'
          alignItems='center'
          
          className={classes.header1}
        >
          <Box
            display='flex'
            // alignItems='center'
            justifyContent='center'
            flexDirection='column'
          >
            <Box className={classes.headerTitle1}>
              <div className={classes.centeredText1}>
                {' '}
                STRETCH BETTER. FIX PAIN.
              </div>
            </Box>
            <Box className={classes.headerDescription1}>
              <div className={classes.centeredText1}>
                Daily stretching & mobility video classes and Programs.
              </div>
              <div className={classes.centeredText1}>
                Created by top Doctors of Physical Therapy.
              </div>
            </Box>
            <Box className={classes.mob} style={{ paddingTop: '14px' }}>
              <div className={classes.plansContainer}>
                {plans?.map((plan, index) => {
                  return (
                    <TabPanel key={plan.id} value={value} index={index}>
                      <Plans
                        plans={plan.plans.sort((a, b) => a.order - b.order)}
                      />
                    </TabPanel>
                  );
                })}
              </div>
              <div
                style={{
                  fontWeight: 400,
                  fontSize: 18,
                  paddingTop: 15,
                  color: 'white',
                }}
              >
                *FLASH sale ends in 24-hours
              </div>
            </Box>
            <Box className={classes.buttonContainer1}>
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.button1}
                  onClick={handleClickLink}
                  >
                  <Typography className={classes.buttonText1} variant='body1'>
                    CLAIM DISCOUNT
                  </Typography>
                </Button>
            </Box>
          </Box>
          <Box className={classes.buttonContainer}>
            <div
              style={{
                backgroundImage: `url(${freeClassAllDevices})`,
              }}
              className={classes.image1}
            />
          </Box>
        </Box>
        <div className={classes.bottomLine1} />
      </div>

      <ProgramsSponsors />

      <Rehab />
      <HowMovmentVaultWorks
        title='HOW MOVEMENT VAULT WORKS'
        descriptionOne='Movement Vault is a revolutionary way to stretch, fix and prevent pain and injury.'
        descriptionTwo='Perform daily stretching and mobility video classes from anywhere, on any device. Choose from over 1500+ classes and counting. Classes for all levels, and everybody.'
        descriptionThree='Plus fix your pain and injuries with complete programs created by world class Doctors of Physical Therapy.'
        // img={catherineHypermobility}
        img={howmovmentvaultworks}
        isButton={true}
        buttonText={"CLAIM DISCOUNT"}
        onClick={handleClickLink}
      />

      <ProgramsWillHelpYou
        title='The Movement Vault app will help you:'
        // img={phone}
        img={freeclassgraysonsterchingphonevertical}
        descriptionOne='Improve your joint flexibility, mobility, and stability'
        descriptionTwo='Improve your core activation and stability'
        descriptionThree='Improve your balance'
        descriptionFour='Improve your body awareness'
        descriptionFive='Fix and prevent pain and injury'
        descriptionSix='Improve your performance in your daily activities and in the gym'
        descriptionSeven='Get you back to doing everything you love to do, pain-free'
      />
      <Review
        reviews={[
          {
            text: 'To say that this app changed my life is an understatement. I am pretty active and enjoy working out, but sit all day for work. Over the years I’ve noticed my muscles have been getting tighter, which has led to low back and knee pain. I tried yoga and a few other programs, which were boring and only helped a little. Movement Vault’s daily stretching classes have helped me improve my flexibility tremendously. I felt a difference after my first class and was hooked. 15 minutes a few times a week and I feel amazing!',
            author: 'Janelle P.',
          },
          {
            text: 'I had been living with with back pain for about 6 years and you name it, I tried it. I’d get some relief, but was never able to fully resolve my back pain. The Movement Vault app was a game changer for me. I now have literally zero pain. I am finally able to get back to the gym and rough house with my grandkids. Since completing the back program, I’ve continued with their daily stretching classes. I highly recommend their low back program and daily stretching video classes.',
            author: 'Mike D.',
          },
          {
            text: 'I never knew I was stretching the wrong way. I have always stretched, but learned that I was doing static stretches, which aren’t very effective. No wonder I have made little progress. Movement Vault’s active stretching was way different than anything else I’ve tried. My shoulder and hip mobility has improved significantly since using their app. I can now get deeper with squats without knee pain and my form with shoulder overhead movements are much better and pain-free. This app has helped me in so many ways.',
            author: 'Marshall H.',
          },
          {
            text: 'This app saved my neck. My neck pain started in college and got worse over the past decade. I ignored the pain until it got so bad that I had to do something. I saw two PTs, and three chiropractors. They helped some, but never fully cured my pain. I found myself taking advil everyday just to get through the day. I completed Movement Vault’s neck pain program, and wow, I forgot what it’s like to not have neck pain. I can now work without pain and have stopped taking pain medication. This program helped so much.',
            author: 'Stephanie R.',
          },
          {
            text: 'My knee pain is gone thanks to Movement Vault! I sit at work and so I have very tight hips, especially my hip flexors. I was told that this puts pressure on my knee cap. After about 3 months of doing the stretches I found on youtube, my knee pain kept getting worse. Sitting for more than 30 minutes was very uncomfortable. I listened to a podcast that mentioned this app and started the program. The active stretches in the program are so effective. After about week 6, my knee was completely pain free.  ',
            author: 'Travis H.',
          },
        ]}
      />
      <ProgramsUsersPercentage
        ringComponents={[
          {
            percent: 93,
            description:
              'of Movement Vault users significantly improved their flexibility + mobility within 60 days',
          },
          {
            percent: 86,
            description:
              'of Movement Vault users that had pain, were able to become pain-free after using Movement Vault for 60-days',
          },
          {
            percent: 98,
            description:
              'of users recommend Movement Vault to their friends and family',
          },
        ]}
        totalUsersSurvey='*based on a survey of 10,536 Movement Vault users'
      />
      <Box>
        <Typography
          className={classes.titleForVideo}
          //   variant='h4'
        >
          <div>Start Improving Your Flexibility and Living Pain-Free</div>
          <div>Just Like These Members</div>
        </Typography>
      </Box>
      <div className={classes.reactPlayerRoot}>
        <NoSsr>
          <ReactPlayer
            config={{
              file: {
                attributes: { controlsList: 'nodownload' },
              },
            }}
            onContextMenu={(e) => e.preventDefault()}
            width='100%'
            height='100%'
            controls
            // url={'https://d1glqvkiwyy8rv.cloudfront.net/HIPS_AND_ANKLES.mp4'}
            url={'https://d1glqvkiwyy8rv.cloudfront.net/mov.mp4'}
            playing={false}
            // controls={true}
            playsinline={true}
          />
        </NoSsr>
      </div>
      <ProgramsDoctor
        titleOne='CREATED BY TOP DOCTORS OF PHYSICAL THERAPY'
        descriptionOne='Our active stretching methodology is backed by research and uses the most effective'
        descriptionTwo='active stretches, and muscle activation exercises, not static stretching. Focusing on static stretching'
        descriptionThree='is one of the main reasons why other programs fall short and don’t actually improve your flexibility.'
        descriptionOneMobile='Our active stretching methodology is backed by research and uses the most effective active stretches, and muscle activation exercises, not static stretching.'
        descriptionTwoMobile='Focusing on static stretching is one of the main reasons why other programs fall short and don’t actually improve your flexibility.'
      />

      <ProgramsHaveYouTried
        title='STRETCHING THAT WORKS'
        descriptionOne='Frustrated with not achieving flexibility progress with your current stretching routine?'
        descriptionTwo='The #1 reason most people fail to achieve flexibility results is due to stretching the wrong way. Traditional static stretching is the wrong way to stretch.'
        descriptionThree='Movement Vault’s revolutionary active stretching methodology is backed by research and is proven to help you achieve lasting flexibility and mobility results.'
        // img={catherineHypermobility}
        img={freeclassmaurostrentching}
        isButton={true}
        buttonText={"CLAIM DISCOUNT"}
        onClick={handleClickLink}

      />

      <ProgramsHelped
        title='FIX YOUR PAIN FOR GOOD'
        descriptionOne='Tired of suffering with joint pain and injuries even though you’ve tried everything including physical therapy, chiropractors, and even using other injury programs? You aren’t alone.'
        descriptionTwo='We have helped thousands of people just like you fix the root cause of their pain and injuries.'
        descriptionThree='We can help you too. Imagine doing everything you love to do in life without pain. You can.'
        // img={meAndCatherine}
        img={freeclassmeandcatherine}
        buttonText={"CLAIM DISCOUNT"}
        onClick={handleClickLink}

      />

      <ProgramsWillHelpYou
        title='The Movement Vault app includes:'
        // img={phone}
        image={freeClassAllDevices}
        descriptionOne='New 10-20 minute stretching video class everyday'
        descriptionTwo='1500+ video classes to choose from'
        descriptionThree='Pain & Injury programs such as our 16-week low back pain program'
        descriptionFour='Classes for all levels, all ages, everyone'
        descriptionFive='Our Virtual Mobility Assessment'
        descriptionSix='Little to no equipment needed'
        descriptionSeven='Use on any device, at anytime'
        isButton={true}
        buttonText={"CLAIM DISCOUNT"}
        onClick={handleClickLink}


      />

      <MobilityScore
        title='WHAT’S YOUR MOBILITY SCORE?'
        descriptionOne='Wondering which of your muscles and joints are tight? Tight joints lead to pain and injury.'
        descriptionTwo=' Take Movement Vault’s Virtual Mobility Assessment and generate your customized Total Mobility Score and generate a mobility score for each major joint in your body.'
        descriptionThree='This will help you focus on the areas you need to improve most and help you track your progress.'
        // img={meAndCatherine}
        img={doublePhone}
        buttonText={"CLAIM DISCOUNT"}
        onClick={handleClickLink}


      />
      <Consistent
        title='HELPING YOU STAY CONSISTENT'
        descriptionOne='Never miss your stretching session again.'
        descriptionTwo='Being consistent with stretching can be challenging, but not with Movement Vault.'
        descriptionThree='Movement Vault’s in-app stats and reminders will help you stay motivated and consistent.'
        // img={catherineHypermobility}
        isButton={true}
        buttonText={"CLAIM DISCOUNT"}
        onClick={handleClickLink}
        img={howmovmentvaultwokrsphone}



      />

   

      <div className={classes.mainContainer5}>
        <div className={classes.title5}>
          Ready to Unlock Your flexibility and mobility?
        </div>
        <div className={classes.title5}>Ready to start living pain-free?</div>
        <div className={classes.description5}>
          We’ve helped 100,000+ people and counting since 2017. We can help you
          too.
        </div>

        <div className={classes.alo}>
          <div className={classes.plansContainer}>
            
            {plans?.map((plan, index) => {
              return (
                <TabPanel key={plan.id} value={value} index={index}>
                  <Plans1
                    plans={plan.plans.sort((a, b) => a.order - b.order)}
                  />
                </TabPanel>
              );
            })}
          </div>
        </div>

        <div
          style={{
            fontWeight: 400,
            fontSize: 18,
            paddingTop: 15,
            color: 'white',
          }}
        >
          *FLASH sale ends in 24-hours
        </div>

        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '25px',
          }}
        >
            <Button
              variant='contained'
              color='secondary'
              className={classes.button5}
              onClick={handleClickLink}
            >
              <Typography className={classes.buttonText5} variant='body1'>
                CLAIM DISCOUNT
              </Typography>
            </Button>
        </Box>
      </div>
      <NewFooter />

    </div>
  );
};



// const mapStateToProps = ({  coupons,auth: { user, planId,couponId }, payment: { plans } }) => ({
//   data: coupons.coupons,
//   plans,
//   planId,
//   couponId,
//   user,

// });

const mapStateToProps = ({ coupons,plan, auth: { user, planId, couponId }, payment: { plans } }) => {
  // Log the entire state to see its structure
  console.log('State in mapStateToProps:', { coupons, plan,user, planId, couponId, plans });

  return {
    data: coupons.coupons,
    filter: coupons.filter,
    plans,
    planId,
    couponId,
    user,
  };
};


const mapDispatchToProps = {
  getCoupons: CouponCreators.getCoupons,
  getPaymentPlans: Creators.getPaymentPlans,
  setFilter: CouponCreators.setFilter,
  pushTo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ClaimDiscount));