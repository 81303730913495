import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Typography } from '@material-ui/core';
import cs from 'classnames';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundSize: 'auto',
    backgroundRepeat: 'no-repeat',
  },
  focusBanner: {
    backgroundColor: '#F7F8FA',
    backgroundImage: `url('../static/images/circle-banner.png')`,
    backgroundPosition: '0px 100%',
    backgroundSize: '380px 300px',
    paddingTop: '107px',
    paddingBottom: '72px',
    color: 'black',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '50px',
    },
  },
  banner: {
    [theme.breakpoints.down('xs')]: {
      paddingRight: 12,
      paddingLeft: 12,
    },
  },
  title: {
    // paddingTop: 20,
    // paddingBottom: 20,
    fontSize: 38,
    fontWeight: 900,
    lineHeight: '52px',
    letterSpacing: 0.56,
    color: 'white',
    whiteSpace: 'nowrap',
    color: '#020401',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontSize: 24,
      whiteSpace: 'normal',
      lineHeight: '38px',
    },
  },
  text: {
    fontSize: 22,
    lineHeight: '32px',
    color: '#020401',
    // paddingBottom: 30,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontSize: 22,
      width: '357px',
    },
  },
  desktop: {
    paddingTop: '30px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  mobile: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '30px',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
}));

const ProgramsDoctor = ({
  titleOne,
  descriptionOne,
  descriptionTwo,
  descriptionThree,
  descriptionOneMobile,
  descriptionTwoMobile,
}) => {
  const classes = useStyles();
  return (
    <>
      <Box className={cs(classes.root)}>
        {/* <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          flexGrow={1}
          className={classes.banner}
        > */}
        <div className={cs(classes.root, classes.focusBanner)}>
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            //   flexGrow={1}
            className={classes.banner}
          >
            <Typography className={classes.title} variant='h4'>
              {titleOne}
            </Typography>
            <Box className={classes.desktop}>
              <div>
                <Typography className={classes.text} variant='body1'>
                  {descriptionOne}
                </Typography>
              </div>
              <div>
                <Typography className={classes.text} variant='body1'>
                  {descriptionTwo}
                </Typography>
              </div>
              <div>
                <Typography className={classes.text} variant='body1'>
                  {descriptionThree}
                </Typography>
              </div>
            </Box>
            <Box className={classes.mobile}>
              <div>
                <Typography className={classes.text} variant='body1'>
                  {descriptionOneMobile}
                </Typography>
              </div>
              <div style={{paddingTop : "30px"}}>
                <Typography className={classes.text} variant='body1'>
                  {descriptionTwoMobile}
                </Typography>
              </div>
            </Box>
          </Box>
        </div>
        {/* </Box> */}
      </Box>
    </>
  );
};

export default ProgramsDoctor;
