import { Box, Button, NoSsr, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';

import backgroundImage from '../../../assets/images/img-background@2x.jpg';


const useStyles = makeStyles((theme) => ({
  header: {
    height: 488,
    // marginTop: -88,
    // backgroundImage: `url('../../../assets/images/img-background@2x.jpg')`,
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: '0px 100%',
    backgroundRepeat: 'no-repeat',
    padding: 151,
    justifyContent: 'center',
    gap: "109px",
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      height: "100%",
      paddingTop : 60,
      paddingBottom : 74,
      paddingLeft : 20,
      paddingRight : 20,
      gap : "61px",
    },
  },
  headerTitle: {
    color: 'white',
    fontFamily: 'Lato',
    fontSize: '38px',
    fontStyle: 'normal',
    fontWeight: 900,
    lineHeight: '52px',
    letterSpacing: '0.8px',
    textTransform: 'uppercase',
    width : "700px",
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width : "390px"
    },
  },
  headerDescription: {
    color: 'white',
    fontFamily: 'Lato',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '28px',
    marginTop: '16px',
  },
  buttonText: {
    fontWeight: 'bold',
    fontSize: 18,
    color: 'white',
    lineHeight: 3.56,
    letterSpacing: 1.38,
  },
  buttonContainer: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  button: {
    marginTop: '32px',
    height: 64,
    width: 348,
    borderRadius: 2,
    [theme.breakpoints.down('xs')]: {
      width : 305
      },
  },
  bottomLine: {
    background: '#28AAE2',
    height: '8px',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  image: {
    width: 386,
    height: 255,
    backgroundRepeat: 'no-repeat',
    objectFit: 'fit',
    backgroundSize: 'contain',
    // backgroundImage :`url('../static/images/ashley-trap-ball.png')`,
    [theme.breakpoints.down('xs')]: {
      height: 217,
      width: 327,
      // marginTop: 50,
    },
  },
  centeredText: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
}));

const ProgramsHeader = ({
  text,
  textOne,
  description,
  descriptionOne,
  buttonText,
  image,
}) => {
  const classes = useStyles();
  const [videoModalOpen, setVideoModalOpen] = useState(false);

  return (
    <div>
      <Box
        display='flex'
        alignItems='center'
        // justifyContent='space-between'
        // flexDirection='row'
        className={classes.header}
      >
        <Box
          display='flex'
          // alignItems='center'
          justifyContent='center'
          flexDirection='column'
        >
          <Box className={classes.headerTitle}>
            <div className={classes.centeredText}> {text}</div>
            <div className={classes.centeredText}>{textOne}</div>
          </Box>
          <Box className={classes.headerDescription}>
            <div className={classes.centeredText}>{description}</div>
            <div className={classes.centeredText}>{descriptionOne}</div>
          </Box>
          <Box className={classes.buttonContainer}>
            <a href='https://app.movementvault.com/auth/signup'>
              <Button
                variant='contained'
                color='primary'
                className={classes.button}
              >
                <Typography className={classes.buttonText} variant='body1'>
                  {buttonText}
                </Typography>
              </Button>
            </a>
          </Box>
        </Box>
        <Box className={classes.buttonContainer}>
          <div style={{  backgroundImage: `url(${image})` }} className={classes.image} />
        </Box>
      </Box>
      <div className={classes.bottomLine} />
    </div>
  );
};

export default ProgramsHeader;
