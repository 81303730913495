import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Formik, useFormikContext } from 'formik';
import { object, string } from 'yup';
import { goBack } from 'connected-react-router';

import { Button, Form, FormInput, FormRow, FormSelect } from 'components';
import { Creators } from '../reducer';
import { Dialog, DialogActions, DialogTitle } from '@material-ui/core';

const styles = (theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 40,
    width: '50%',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'center',
    position: 'relative',
  },
  form: {
    padding: `40px 32px`,
  },
  header: {
    alignItems: 'center',
    borderBottom: '1px solid #DFE2E6',
    display: 'flex',
    height: 80,
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  input: {
    width: 'calc(50% - 8px)',
  },
  inputRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 500,
    left: 16,
    position: 'absolute',
  },
  labelXl: {
    color: theme.palette.primary.dark,
    fontWeight: 900,
    fontSize: 21,
    lineHeight: 1.5,
    textTransform: 'uppercase',
  },
  stores:{
    display: "flex",
    justifyContent: "center",
    gap: "5px",
  }
});

const SignUpForm = ({
  classes,
  className,
  error,
  id,
  member,
  processing,
  navigateBack,
  signUp,
  updateUser,
}) => {
  const FormBody = () => {
    const { isValid, validateForm } = useFormikContext();

    useEffect(() => {
      validateForm();
    }, [validateForm]);


    useEffect(() => {
  console.log(member,"UPDATE");
}, [member]);


    console.log(member,"MEMBER")



    const [openDialog, setOpenDialog] = useState(false);
  const [fieldToClear, setFieldToClear] = useState(null);

    //  const handleSetFieldToNull = (field) => {
    //   updateUser({ id, user: { ...member, [field]: null } });
    // };

    const handleSetFieldToNull = (field, member) => {
      if (field) {
        updateUser({
          id,
          user: {
            ...member,
            [field]: null, 
          },
        });
        member[field] = null;
        setOpenDialog(false);
      }
    };
  
    const handleOpenDialog = (field) => {
      setFieldToClear(field); 
      setOpenDialog(true); 
    };
  
    const handleCloseDialog = () => {
      setOpenDialog(false); 
      setFieldToClear(null);
    };
  

    return (
      <Form containerClass={classes.form} showDivider={false}>
        <FormRow>
          
          <div className={classes.inputRow}>
            <div className={classes.input}>
              <FormSelect
                height={50}
                width='100%'
                label='Role'
                name='role'
                options={[
                  {
                    label: 'Admin',
                    value: 'admin',
                  },
                  {
                    label: 'User',
                    value: 'user',
                  },
                ]}
              />
            </div>
            <div className={classes.input}>
              <FormInput
                error={error}
                label='Email address'
                name='email'
                placeholder='Email address'
              />
            </div>
          </div>
        </FormRow>
        <FormRow>
          <div className={classes.inputRow}>
            <div className={classes.input}>
              <FormInput
                error={error}
                label='First Name'
                name='firstName'
                placeholder='First Name'
              />
            </div>
            <div className={classes.input}>
              <FormInput
                error={error}
                label='Last Name'
                name='lastName'
                placeholder='Last Name'
              />
            </div>
          </div>
        </FormRow>
        <FormRow>
          <div className={classes.inputRow}>
            <div className={classes.input}>
              <FormSelect
                height={50}
                width='100%'
                label='Status'
                name='status'
                options={[
                  {
                    label: 'Freemium',
                    value: 'freemium',
                  },
                  {
                    label: 'Premium',
                    value: 'premium',
                  },
                ]}
              />
            </div>
         
          </div>


         
            
        </FormRow>

        <FormRow>
          <div className={classes.stores}>
            {/* {member?.stripeId ? <div style={{width : 200}} className={classes.input}>
            <Button onClick={() => handleSetFieldToNull("stripeId")}
                title="Clear Stripe ID" />
            </div> : null }
            {member?.playStoreId ? <div style={{width : 200}} className={classes.input}>
            <Button onClick={() => handleSetFieldToNull("playStoreId")}
                title="Clear Play Store ID" />
            </div> : null }
            {member?.appStoreId ? <div style={{width : 200}} className={classes.input}>
            <Button onClick={() => handleSetFieldToNull("appStoreId")}
                title="Clear App Store ID" />
            </div> : null } */}

        <div style={{ width: 200 }} className={classes.input}>
          <Button disabled={!member?.stripeId} onClick={() => handleOpenDialog('stripeId')} title="Clear Stripe ID" />
        </div>
        <div style={{ width: 200 }} className={classes.input}>
          <Button disabled={!member?.playStoreId} onClick={() => handleOpenDialog('playStoreId')} title="Clear Play Store ID" />
        </div>
        <div style={{ width: 200 }} className={classes.input}>
          <Button disabled={!member?.appStoreId} onClick={() => handleOpenDialog('appStoreId')} title="Clear App Store ID" />
        </div>

      {/* Dialog for confirmation */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Clear</DialogTitle>
          {/* <p >Are you sure you want to clear this ID?</p> */}
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" title="No">
            No
          </Button>
          <Button
            onClick={() => {
              handleSetFieldToNull(fieldToClear, member)
            }}
            color="primary"
            autoFocus
            title="Yes"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
         
          </div>
        </FormRow>


        <FormRow>
          <div className={classes.buttons}>
            <div className={classes.input}>
              <Button onClick={() => navigateBack()} title='Cancel' outlined />
            </div>
            <div className={classes.input}>
              <Button
                disabled={!isValid}
                processing={processing}
                processingLabel='saving...'
                title='save'
                type='submit'
              />
            </div>
          </div>
        </FormRow>
      </Form>
    );
  };

  const validationSchema = object().shape({
    firstName: string().required('First name is required'),
    lastName: string().required('Last name is required'),
    email: string().email().required('Email is required'),
  });

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div onClick={navigateBack} className={classes.link}>{`<< Back`}</div>
        <div className={classes.labelXl}>edit member</div>
      </div>
      <Formik
        initialValues={{
          firstName: member?.firstName,
          lastName: member?.lastName,
          email: member?.email,
          role: member?.role,
          status: member?.status,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => updateUser({ id, user: values })}
      >
        <FormBody />
      </Formik>
    </div>
  );
};

const mapStateToProps = (
  { membership: { error, users, processing } },
  {
    match: {
      params: { id },
    },
  }
) => ({
  error,
  processing,
  member: users.find((user) => user.id === id),
  id,
});

const mapDispatchToProps = {
  navigateBack: goBack,
  updateUser: Creators.updateUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SignUpForm));
