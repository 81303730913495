import Axios from 'axios';
import https from 'https';

const API_URL = 'https://api.movementvault.com/api/v1/'

const axios = () => {
  return Axios.create({
    baseURL: API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    httpsAgent: new https.Agent({
      rejectUnauthorized: false,
    }),
  });
};

export default axios();
